import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import useReport from './hooks/useReport';
import Viewer from './Viewer';
import LegacyViewer from './LegacyViewer';
import FiltersGroup from './FiltersGroup';
import RunningIndicator from './RunningIndicator';
import ActionsDropdown from './ActionsDropdown';
import EquipmentHeader from './EquipmentHeader';
import { showInLegacyViewer } from './data/LegacyReports';

function Runner({
  reportId,
  refetchKey,
  showReport,
  showCharts,
  onBackClick,
  onHeaderClear,
  railsFormToken,
  equipmentId,
  inventoryId,
  config,
  onCustomize,
  infiniteScroll,
  mobileApp,
}) {
  const [filterParams, setFilterParams] = useState({});
  const [search, setSearch] = useState('');
  const [prevReportId, setPrevReportId] = useState(null);
  const [prevRefetchKey, setPrevRefetchKey] = useState(null);
  const [inLegacyViewer, setInLegacyViewer] = useState(false);
  const recordIdsRef = useRef([]);
  const orderRef = useRef('');
  const colFiltersRef = useRef({});

  // Effect to clear the custom_filter_id from state when then report id changes
  // This avoids passing the filter_id from the previous report into the preflight call
  useEffect(() => {
    if (reportId !== prevReportId || refetchKey !== prevRefetchKey) {
      // eslint-disable-next-line camelcase
      const { custom_filter_id, ...newFilters } = filterParams;
      setFilterParams(newFilters);
      setPrevReportId(reportId);
      setPrevRefetchKey(refetchKey);
    }
  }, [reportId, refetchKey]);

  // Effect to keep the legacy viewer state in sync with the report id
  useEffect(() => {
    setInLegacyViewer(showInLegacyViewer(reportId));
  }, [reportId]);

  // Query the API for report params; updates automatically when
  // arguments change.
  const {
    status, data: report, error, isFetching,
  } = useReport({
    reportId,
    filterParams,
    search,
    equipmentId,
    inventoryId,
    refetchKey,
  });

  // Callback that lets Viewer notify Runner of record id changes
  const handleRecordIdsChanged = useCallback((newIds) => {
    // Use ref to persist the record ids across renders and avoid re-renders
    recordIdsRef.current = newIds;
  }, []);

  // Callback that lets Viewer notify Runner of changes to record retrieval params
  const handleRecordsRetrieved = useCallback((params) => {
    // Use ref to persist the values across renders and avoid re-renders
    if (params.order) orderRef.current = params.order.map((obj) => `${obj.column},${obj.dir}`).join(',');
    if (params.filters) colFiltersRef.current = params.filters;
  }, []);

  const handleFiltersChange = useCallback(({ item, parentItem }) => {
    if (!item) return;

    // Build the new filters object from arguments
    const newFilters = {};
    newFilters[item.filter_id] = item.filter_value;

    if (item.include_parent_filter && parentItem) {
      newFilters[parentItem.filter_id] = parentItem.filter_value;
    }

    // Update state; this will auto-trigger the API query to fire since it
    // depends on this object
    setFilterParams(newFilters);
  }, [setFilterParams]);

  const handleCustomInterval = useCallback(({ fromDate, toDate }) => {
    const newFilters = {
      interval: 'custom',
      custom_interval: { iso: 'y' },
    };

    if (fromDate) newFilters.custom_interval.from_date = fromDate.toISOString();
    newFilters.custom_interval.to_date = toDate.toISOString();

    setFilterParams(newFilters);
  }, [setFilterParams]);

  const handleSearch = useCallback((searchString) => {
    // Update search state; this will auto-trigger API query
    setSearch(searchString);
  }, [setSearch]);

  // Don't return component at all if a report id isn't provided
  if (reportId === null || reportId === '') return null;

  if (status === 'error') {
    // Throw any error so the ErrorBoundary can pick it up
    throw error;
  }

  const hiddenInput = (name, value) => {
    const inputElement = document.createElement('input');
    inputElement.type = 'hidden';
    inputElement.name = name;
    inputElement.value = value;

    return inputElement;
  };

  // Handle PDF and CSV download requests, accommodating both the Datatables-powered
  // LegacyViewer and the AG Grid React-powered Viewer.
  const handleFileDownload = (fileType) => {
    const form = document.createElement('form');
    form.acceptCharset = 'UTF-8';
    if (fileType === 'pdf' && !mobileApp) form.target = '_blank';
    form.method = 'POST';
    form.action = `/reports/download/${reportId}.${fileType}`;

    form.appendChild(hiddenInput('utf8', '✓'));
    form.appendChild(hiddenInput('authenticity_token', railsFormToken));
    form.appendChild(hiddenInput('report', reportId));
    form.appendChild(hiddenInput('search', search));

    if (report.record_count <= 500) {
      const idsList = inLegacyViewer
        ? $('table.dataTable').DataTable().column(0, { search: 'applied' }).data()
        : recordIdsRef.current;

      form.appendChild(hiddenInput('draw', 0));
      form.appendChild(hiddenInput('ids', idsList.join()));
    } else {
      const order = inLegacyViewer
        ? $('table.dataTable').DataTable().order()
        : orderRef.current;

      form.appendChild(hiddenInput('draw', 1));
      form.appendChild(hiddenInput('order', order));
      if (!inLegacyViewer) form.appendChild(hiddenInput('filters', JSON.stringify(colFiltersRef.current)));
    }

    document.body.appendChild(form);
    form.submit();

    // Don't clutter up the DOM with on-demand forms
    // After the browser has a chance to initiate download or open the new tab,
    // check for the form and remove it.
    setTimeout(() => {
      if (document.contains(form)) form.remove();
    }, 1000);
  };

  const equipmentHeader = (report !== undefined && report.equipment_id) ? (
    <EquipmentHeader
      name={report.equipment_name}
      path={report.equipment_path}
      info={report.equipment_info}
      imageUrl={report.equipment_image_url}
      onClose={onHeaderClear}
    />
  ) : null;

  return (
    <>
      <button
        type="button"
        id="back-to-reports-button"
        className="im-clear tw-du-btn tw-du-btn-ghost tw-text-blue-400 tw-mb-2 hover:tw-bg-transparent hover:tw-text-gray-800"
        onClick={onBackClick}
      >
        {'< Back to Reports'}
      </button>
      <FiltersGroup
        report={report}
        isLoading={status === 'loading'}
        onChange={handleFiltersChange}
        onCustomInterval={handleCustomInterval}
        onSearch={handleSearch}
        config={config}
        railsFormToken={railsFormToken}
        onCustomize={onCustomize}
      />

      {status === 'loading' && (<RunningIndicator />)}

      {status !== 'loading' && (
        <div
          className={`tw-rounded-2xl tw-max-h-full tw-overflow-y-auto tw-shadow-lg tw-bg-white ${showReport ? 'tw-transition-all tw-delay-100 tw-duration-900 tw-opacity-100' : 'tw-opacity-0'}`}
        >
          <div className="tw-w-full tw-relative">
            <ActionsDropdown
              report={report}
              onDownloadRequest={handleFileDownload}
            />
          </div>
          <div className="tw-px-2 sm:tw-px-6 tw-py-4 tw-text-left">
            {!inLegacyViewer && (
              <Viewer
                reportId={reportId}
                title={report.title}
                columnArray={report.column_array}
                chartArray={report.chart_array}
                orderArray={JSON.parse(report.order_by_array)}
                filters={report.filters}
                ajax={report.ajax}
                showCharts={showCharts}
                serverSide={report.record_count > 500}
                noRecords={report.record_count === 0}
                customHeader={equipmentHeader}
                displayLength={report.display_length}
                currency={report.org_currency}
                onRecordIdsChanged={handleRecordIdsChanged}
                onRecordsRetrieved={handleRecordsRetrieved}
                infiniteScroll={infiniteScroll}
                railsFormToken={railsFormToken}
              />
            )}
            {inLegacyViewer && (
              <LegacyViewer
                title={report.title}
                columnArray={report.column_array}
                chartArray={report.chart_array}
                orderArray={JSON.parse(report.order_array)}
                ajax={report.ajax}
                showCharts={showCharts}
                serverSide={report.record_count > 500}
                customHeader={equipmentHeader}
                displayLength={report.display_length}
              />
            )}
            {isFetching && (
              <div>
                Updating...
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

Runner.propTypes = {
  reportId: PropTypes.string.isRequired,
  refetchKey: PropTypes.number,
  showCharts: PropTypes.bool,
  showReport: PropTypes.bool,
  onBackClick: PropTypes.func.isRequired,
  onHeaderClear: PropTypes.func.isRequired,
  railsFormToken: PropTypes.string.isRequired,
  equipmentId: PropTypes.number,
  inventoryId: PropTypes.number,
  config: PropTypes.string.isRequired,
  onCustomize: PropTypes.func,
  infiniteScroll: PropTypes.bool,
  mobileApp: PropTypes.bool,
};

Runner.defaultProps = {
  refetchKey: 0,
  showCharts: true,
  showReport: false,
  equipmentId: null,
  inventoryId: null,
  onCustomize: () => null,
  infiniteScroll: true,
  mobileApp: false,
};

export default React.memo(Runner);
