import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

export default function useCustomizationMutations({ railsFormToken, onSuccess }) {
  const updateCustomizationMutation = useMutation({
    mutationFn: ({
      customizationEndpoint,
      customizationId,
      suppressedFields,
      customFields,
      orderedFields,
      displayLength,
      suppressCharts,
      showOrgLogo,
      hideThumbnails,
    }) => (
      axios.patch(
        `/${customizationEndpoint}/${customizationId}.json`,
        {
          authenticity_token: railsFormToken,
          customization: {
            suppressed_field_array: suppressedFields,
            custom_field_array: customFields,
            ordered_field_array: orderedFields,
            display_length: displayLength,
            suppress_charts: suppressCharts,
            show_org_logo: showOrgLogo,
            hide_thumbnails: hideThumbnails,
          },
        },
      )
    ),
    onSuccess,
  });

  const deleteCustomizationMutation = useMutation({
    mutationFn: ({
      customizationEndpoint,
      customizationId,
    }) => (
      axios.delete(
        `/${customizationEndpoint}/${customizationId}.json`,
        {
          headers: {
            'X-CSRF-Token': railsFormToken,
          },
        },
      )
    ),
    onSuccess,
  });

  return { updateCustomizationMutation, deleteCustomizationMutation };
}
