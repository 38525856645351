import React from 'react';
import PropTypes from 'prop-types';

export default function SearchClear({
  handleClearClick,
  hideSearchClear,
}) {
  return (
    <>
      <a className= "tw-text-blue-500 hover:tw-text-blue-700 tw-absolute tw-right-6" href="#" onClick={handleClearClick} style={{ display: hideSearchClear ? "none" : "inline" }}>
        <i className="fa fa-large fa-times tw-align-[-0.6em]"/>
      </a>
    </>
  );
}

SearchClear.propTypes = {
  handleClearClick: PropTypes.func,
  hideSearchClear: PropTypes.bool,
};

SearchClear.defaultProps = {
  handleClearClick: () => null,
  hideSearchClear: true,
};
