import React, {
  useCallback,
  useEffect,
  useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import Chart from '../Chart';
import ErrorMessage from '../ErrorMessage';
import ClientSideGrid from '../AgGrid/ClientSideGrid';
import ServerSideGrid from '../AgGrid/ServerSideGrid';
import { GridContext } from '../AgGrid/data/GridContextManager';

function ViewerBody({
  chartArray,
  showCharts,
  noRecords,
  customHeader,
}) {
  const {
    ajax,
    columnArray,
    columnDefs,
    currency,
    defaultColDef,
    displayLength,
    errorMessage,
    gridStyle,
    handleColumnMoved,
    handlePaginationCustomized,
    handleRowClicked,
    haveError,
    infiniteScroll,
    onRecordsRetrieved,
    onRecordIdsChanged,
    serverSide,
    setErrorMessage,
    setHaveError,
    title,
  } = useContext(GridContext);

  const chartContainerRef = useRef();
  const tableContainerRef = useRef();

  // Effect to show the UI if there are no records
  useEffect(() => {
    if (noRecords) {
      // Allow the "no records" display to show
      if (tableContainerRef.current) {
        tableContainerRef.current.classList.add('tw-transition-opacity', 'tw-duration-500', 'tw-opacity-100');
      }
      if (chartContainerRef.current) {
        chartContainerRef.current.classList.add('tw-transition-opacity', 'tw-duration-700', 'tw-opacity-100');
      }
    }
  }, [noRecords]);

  // Effect to force reflow of charts library upon window resize
  useEffect(() => {
    const chartContainer = chartContainerRef.current;

    const resizeObserver = new ResizeObserver(() => {
      // eslint-disable-next-line no-undef
      Highcharts.charts.forEach((chart) => {
        if (chart !== undefined) {
          chart.reflow();
        }
      });
    });

    if (chartContainer) {
      resizeObserver.observe(chartContainer);
    }

    return () => {
      if (chartContainer) {
        resizeObserver.disconnect();
      }
    };
  }, [ajax]);

  // For a smoother UX, fade in the table & charts once AG Grid reports rendering data
  const handleFirstDataRendered = useCallback(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.classList.add('tw-transition-opacity', 'tw-duration-500', 'tw-opacity-100');
    }

    // Fade in the charts container after a short delay to avoid flicker
    setTimeout(() => {
      if (chartContainerRef.current) {
        chartContainerRef.current.classList.add('tw-transition-opacity', 'tw-duration-700', 'tw-opacity-100');
      }
    }, 500);
  }, []);

  // Persist sort order to local storage when changed
  const handleSortChanged = useCallback((e) => {
    const selectedOrder = e.api.getColumnState().filter((s) => s.sort !== null);
    const newOrderArray = selectedOrder.sort((a, b) => a.sortIndex - b.sortIndex).map((c) => [c.colId, c.sort]);

    localStorage.setItem(`ReportOrder_${title}`, JSON.stringify(newOrderArray));

    // Track record ID order
    const orderedIds = [];
    e.api.forEachNodeAfterFilterAndSort((node) => {
      if (!node.group && node.data?.id) orderedIds.push(node.data.id);
    });
    onRecordIdsChanged(orderedIds);
  }, [onRecordIdsChanged, title]);

  return (
    <>
      {!haveError && (
        <div
          ref={tableContainerRef}
          className="table-float lg:tw-grid tw-opacity-0"
        >
          <h1 className="tw-mb-4">{title}</h1>
          {customHeader}
          <div
            id="charts"
            ref={chartContainerRef}
            className="tw-bg-transparent tw-opacity-0"
          >
            {chartArray.map((chartOptions, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Chart key={index} chartOptions={chartOptions} show={showCharts} />
            ))}
          </div>
          <div className="tw-mt-4 tw-w-100">
            {!serverSide && (
              <ClientSideGrid
                ajax={ajax}
                currency={currency}
                defaultColDef={defaultColDef}
                displayLength={displayLength}
                columnDefs={columnDefs}
                gridStyle={gridStyle}
                allowGrouping={false}
                setHaveError={setHaveError}
                setErrorMessage={setErrorMessage}
                onColumnMoved={handleColumnMoved}
                onFirstDataRendered={handleFirstDataRendered}
                onRowClicked={handleRowClicked}
                onSortChanged={handleSortChanged}
                onRecordIdsChanged={onRecordIdsChanged}
                infiniteScroll={infiniteScroll}
              />
            )}
            {serverSide && (
              <ServerSideGrid
                ajax={ajax}
                columnArray={columnArray}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                gridStyle={gridStyle}
                displayLength={displayLength}
                setHaveError={setHaveError}
                setErrorMessage={setErrorMessage}
                onColumnMoved={handleColumnMoved}
                onFirstDataRendered={handleFirstDataRendered}
                onPaginationCustomized={handlePaginationCustomized}
                onRowClicked={handleRowClicked}
                onSortChanged={handleSortChanged}
                onRecordsRetrieved={onRecordsRetrieved}
                infiniteScroll={infiniteScroll}
              />
            )}
          </div>
        </div>
      )}
      {haveError && (
        <ErrorMessage details={errorMessage} />
      )}
    </>
  );
}

export default React.memo(ViewerBody);

ViewerBody.propTypes = {
  chartArray: PropTypes.arrayOf(PropTypes.object),
  customHeader: PropTypes.node,
  noRecords: PropTypes.bool,
  showCharts: PropTypes.bool,
};

ViewerBody.defaultProps = {
  chartArray: [],
  customHeader: null,
  showCharts: true,
  noRecords: false,
};
