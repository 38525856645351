import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Organizer from './Organizer';
import QuickLaunchSidebar from './QuickLaunchSidebar';
import SlidingSidebar from '../SlidingSidebar';
import SearchClear from '../SearchClear';
import Runner from './Runner';
import useLocalStorageState from '../hooks/useLocalStorageState';
import useReportCategories from './hooks/useReportCategories';
import useFavoriteMutations from './hooks/useFavoriteMutations';

function ReportsApp({
  railsFormToken,
  equipmentId,
  inventoryId,
  config,
  infiniteScroll,
  mobileApp,
}) {
  const { reportId = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [reportIsDisplayed, setReportIsDisplayed] = useState(reportId !== '');
  const [availableCategories, setAvailableCategories] = useState([]);
  const [activeReport, setActiveReport] = useState(reportId);
  const [reportQuery, setReportQuery] = useState('');
  const [categoryOpenStatus, setCategoryOpenStatus] = useLocalStorageState({ 1: true, 3: true }, 'report-category-open-list');
  const [currentEquipmentId, setCurrentEquipmentId] = useState(equipmentId);
  const [currentInventoryId, setCurrentInventoryId] = useState(inventoryId);
  const [refetchKey, setRefetchKey] = useState(0);
  const [previousLocation, setPreviousLocation] = useState(location.pathname);
  const [reportHistoryStack, setReportHistoryStack] = useState([]);
  const [hideSearchClear, setHideSearchClear] = useState(true);

  const {
    status, data: authorizedCategoryList, isFetching,
  } = useReportCategories();

  const { createFavoriteMutation, deleteFavoriteMutation } = useFavoriteMutations(railsFormToken);

  useEffect(() => {
    if (!isFetching && status === 'success') {
      setAvailableCategories(authorizedCategoryList);
    }
  }, [isFetching, status, authorizedCategoryList, setAvailableCategories]);

  const runReport = (reportName) => {
    setActiveReport('');
    setActiveReport(reportName);
    setReportIsDisplayed(true);
    setReportQuery('');
    setAvailableCategories(authorizedCategoryList);

    // Keep the equipment & inventory id around unless we switch without full reload
    // (in which case the prevReportId would be populated)
    setCurrentEquipmentId(null);
    setCurrentInventoryId(null);
    navigate(`/reports/${reportName}`);
    setReportHistoryStack((prev) => [...prev, reportName]);
  };

  const handleReportClick = (reportName) => {
    runReport(reportName);
  };

  const handleEquipmentHeaderClose = useCallback(() => {
    runReport(activeReport);
  }, [runReport, activeReport]);

  const handleSearchChange = (e) => {
    const categoriesWithFilteredReports = availableCategories.map((category) => {
      const filteredReports = category.reports.filter((report) => (
        report.display_name.toLowerCase().includes(e.target.value.toLowerCase())
      ));

      return { ...category, reports: filteredReports };
    });

    let newAvailableCategories;
    if (e.target.value === '') {
      newAvailableCategories = authorizedCategoryList;
      setHideSearchClear(true)
    } else {
      newAvailableCategories = categoriesWithFilteredReports.filter((category) => (
        category.reports.length > 0
      ));
      setHideSearchClear(false)
    }

    setReportQuery(e.target.value);
    setAvailableCategories(newAvailableCategories);
  };

  const handleClearClick = () => {
    setHideSearchClear(true);
    setReportQuery('');
    setAvailableCategories(authorizedCategoryList);
  };

  const showReport = () => (activeReport !== '');

  const handleBackClick = useCallback(() => {
    setReportIsDisplayed(false);
    setActiveReport('');
    navigate('/reports');
  }, [setReportIsDisplayed, setActiveReport, navigate]);

  const handleCategoryOpenChange = (categoryId, isOpen) => {
    const newStatus = {
      ...categoryOpenStatus,
      [categoryId]: isOpen,
    };

    setCategoryOpenStatus(newStatus);
  };

  const handleToggleFavorite = (favoriteReportId, isFavorited) => {
    if (isFavorited) {
      deleteFavoriteMutation.mutate(favoriteReportId);
    } else {
      createFavoriteMutation.mutate(favoriteReportId);
    }
  };

  const handleCustomize = useCallback((performReload = false) => {
    if (performReload) setRefetchKey((prev) => prev + 1);
  }, [setRefetchKey]);

  // Effect for dealing with browser back button click
  useEffect(() => {
    if (location.pathname !== previousLocation && !location.pathname.includes(activeReport)) {
      let lastReport = null;
      if (reportHistoryStack.length > 1) {
        const newStack = [...reportHistoryStack];
        newStack.pop();
        lastReport = newStack[newStack.length - 1];
        setReportHistoryStack(newStack);
      } else {
        setReportHistoryStack([]);
      }
      setActiveReport(lastReport || '');
      setReportIsDisplayed(!!lastReport);
    }
    setPreviousLocation(location.pathname);
  }, [location.pathname]);

  return (
    <div className="im-gradient-container im-fill-vertical-space">
      {reportIsDisplayed && (
        <SlidingSidebar title="Quick Launch Reports" className="im-clear hidden-sm" navClassName="im-clear hidden-sm">
          <QuickLaunchSidebar
            onClick={handleReportClick}
            availableCategories={availableCategories}
            categoryOpenStatus={categoryOpenStatus}
            onCategoryOpenChange={handleCategoryOpenChange}
          />
        </SlidingSidebar>
      )}
      <div className="tw-flex-1 tw-shrink tw-w-full tw-overflow-x-hidden">
        <main className="tw-p-1 lg:tw-p-4">
          <div className="tw-px-1 lg:tw-px-4">
            <div className="tw-relative tw-w-full tw-align-middle">
              {!reportIsDisplayed && (
                <div className="im-clear">
                  <div className="tw-w-full tw-text-right">
                    <input
                      type="search"
                      value={reportQuery}
                      placeholder="Search for a report"
                      className="im-clear im-input-md tw-w-full tw-max-w-xs tw-h-10"
                      onChange={handleSearchChange}
                    />
                    <SearchClear
                      handleClearClick={handleClearClick}
                      hideSearchClear={hideSearchClear}
                    />
                  </div>
                  <Organizer
                    onClick={handleReportClick}
                    availableCategories={availableCategories}
                    searchActive={reportQuery !== ''}
                    categoryOpenStatus={categoryOpenStatus}
                    onCategoryOpenChange={handleCategoryOpenChange}
                    onToggleFavorite={handleToggleFavorite}
                  />
                </div>
              )}
              {showReport() && (
                <Runner
                  showReport
                  reportId={activeReport}
                  refetchKey={refetchKey}
                  onBackClick={handleBackClick}
                  onHeaderClear={handleEquipmentHeaderClose}
                  railsFormToken={railsFormToken}
                  equipmentId={currentEquipmentId}
                  inventoryId={currentInventoryId}
                  config={config}
                  onCustomize={handleCustomize}
                  infiniteScroll={infiniteScroll}
                  mobileApp={mobileApp}
                />
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

ReportsApp.propTypes = {
  railsFormToken: PropTypes.string.isRequired,
  equipmentId: PropTypes.number,
  inventoryId: PropTypes.number,
  config: PropTypes.string.isRequired,
  infiniteScroll: PropTypes.bool,
  mobileApp: PropTypes.bool,
};

ReportsApp.defaultProps = {
  equipmentId: null,
  inventoryId: null,
  infiniteScroll: true,
  mobileApp: false,
};

export default React.memo(ReportsApp);
