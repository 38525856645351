/* eslint-disable jsx-a11y/click-events-have-key-events,no-plusplus */
import React, {useEffect, useState, useCallback, useContext} from 'react';
import PropTypes from 'prop-types';
import { GridContext } from './data/GridContextManager';

function CustomPagination({ api }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(25);
  const [rowCount, setRowCount] = useState(0);
  const [firstRow, setFirstRow] = useState(1);
  const [lastRow, setLastRow] = useState(25);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [currentPageRowCount, setCurrentPageRowCount] = useState(0);

  const {
    currentHeaderRows,
    negativeOffset,
    serverSide,
  } = useContext(GridContext);

  useEffect(() => {
    if (!api) return;
    if (serverSide) return;

    let newTotalRowCount = 0;
    let newCurrentPageRowCount = 0;
    api.forEachNodeAfterFilterAndSort((node, index) => {
      if (!node.group) {
        newTotalRowCount++;
      } else if (index >= firstRow - 1 && index < lastRow) {
        newCurrentPageRowCount++;
      }
    });

    if (newTotalRowCount > 0) {
      setTotalRowCount(newTotalRowCount);
      setCurrentPageRowCount(newCurrentPageRowCount);
    }
  }, [api, firstRow, lastRow, serverSide]);

  const updatePagination = useCallback(() => {
    if (!api) return;
    const newCurrentPage = api.paginationGetCurrentPage() + 1;
    const newTotalPages = api.paginationGetTotalPages();
    const newPageSize = api.paginationGetPageSize();
    const newRowCount = api.paginationGetRowCount();
    const newFirstRow = newCurrentPage > 0 ? (newCurrentPage - 1) * newPageSize + 1 : 0;
    const newLastRow = Math.min(newCurrentPage * newPageSize, newRowCount);

    setCurrentPage(newCurrentPage);
    setTotalPages(newTotalPages);
    setPageSize(newPageSize);
    setRowCount(newRowCount);
    setFirstRow(newFirstRow);
    setLastRow(newLastRow);
  }, [api]);

  useEffect(() => {
    if (!api) return () => {};

    updatePagination();
    api.addEventListener('paginationChanged', updatePagination);

    return () => {
      if (!api.isDestroyed) {
        api.removeEventListener('paginationChanged', updatePagination);
      }
    };
  }, [api, updatePagination]);

  const getLastRow = useCallback(() => {
    if (serverSide) {
      if (currentPage === totalPages) {
        return rowCount - negativeOffset;
      }
      return lastRow - currentHeaderRows;
    }

    if (currentPage === totalPages) {
      return totalRowCount;
    }
    return lastRow - currentPageRowCount;
  }, [currentHeaderRows, currentPage, currentPageRowCount, lastRow, negativeOffset, rowCount, serverSide, totalPages, totalRowCount]);

  const onBtFirst = () => api.paginationGoToFirstPage();
  const onBtPrevious = () => api.paginationGoToPreviousPage();
  const onBtNext = () => api.paginationGoToNextPage();
  const onBtLast = () => api.paginationGoToLastPage();

  return (
    <div className="ag-paging-panel ag-unselectable tw-py-2" style={{ borderTop: 'none' }}>
      <span className="ag-paging-page-size" />
      <span className="ag-paging-row-summary-panel" role="status">
        <span className="ag-paging-row-summary-panel-number">
          {firstRow}
        </span>
        <span className="tw-px-1">to</span>
        <span className="ag-paging-row-summary-panel-number">
          {getLastRow()}
        </span>
        <span className="tw-px-1">of</span>
        <span className="ag-paging-row-summary-panel-number">
          {serverSide ? rowCount - negativeOffset : totalRowCount}
        </span>
      </span>
      <span className="ag-paging-page-summary-panel" role="presentation">
        <div
          className={`ag-button ag-paging-button ${currentPage === 1 ? 'ag-disabled' : ''}`}
          role="button"
          aria-label="First Page"
          tabIndex="0"
          onClick={onBtFirst}
          aria-disabled={currentPage === 1}
        >
          <span className="ag-icon ag-icon-first" role="presentation" />
        </div>
        <div
          className={`ag-button ag-paging-button ${currentPage === 1 ? 'ag-disabled' : ''}`}
          role="button"
          aria-label="Previous Page"
          tabIndex="0"
          onClick={onBtPrevious}
          aria-disabled={currentPage === 1}
        >
          <span className="ag-icon ag-icon-previous" role="presentation" />
        </div>
        <span className="ag-paging-description" role="status">
          <span className="tw-pr-1">Page</span>
          <span className="ag-paging-number">
            {currentPage}
          </span>
          <span className="tw-px-1">of</span>
          <span className="ag-paging-number">
            {totalPages}
          </span>
        </span>
        <div
          className={`ag-button ag-paging-button ${currentPage === totalPages ? 'ag-disabled' : ''}`}
          role="button"
          aria-label="Next Page"
          tabIndex="0"
          onClick={onBtNext}
          aria-disabled={currentPage === totalPages}
        >
          <span className="ag-icon ag-icon-next" role="presentation" />
        </div>
        <div
          className={`ag-button ag-paging-button ${currentPage === totalPages ? 'ag-disabled' : ''}`}
          role="button"
          aria-label="Last Page"
          tabIndex="0"
          onClick={onBtLast}
          aria-disabled={currentPage === totalPages}
        >
          <span className="ag-icon ag-icon-last" role="presentation" />
        </div>
      </span>
    </div>
  );
}

CustomPagination.propTypes = {
  api: PropTypes.object.isRequired,
};

export default React.memo(CustomPagination);
