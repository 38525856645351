import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CollapsablePanelItem from './CollapsablePanelItem';
import SlideDownTransition from './SlideDownTransition';

export default function CollapsablePanel({
  title, startOpen, forceOpen, className, children, onOpenChange, itemId,
}) {
  const [showContent, setShowContent] = useState(startOpen || forceOpen);

  const handleCheckedChange = () => {
    const newValue = !showContent;
    setShowContent(newValue);
    onOpenChange(itemId, newValue);
  };

  return (
    <div
      className={classNames({
        [className]: true,
        'im-cp-panel': true,
        'tw-border-primary tw-border-2': itemId === null,
      })}
    >
      <div className="tw-px-6 tw-py-4 tw-text-left">
        <button
          className={classNames({
            'im-clear tw-text-left tw-w-full im-cp-title im-cp-clickable': true,
            'tw-mb-3': forceOpen || showContent,
          })}
          type="button"
          onClick={handleCheckedChange}
        >
          {title}
          <i
            className={classNames({
              'fas fa-chevron-down im-cp-chevron': true,
              'tw-rotate-180': forceOpen || showContent,
            })}
          />
        </button>
        <SlideDownTransition
          show={forceOpen || showContent}
        >
          <ul className="tw-columns-1 xl:tw-columns-2 tw-gap-10 sm:tw-px-9">
            {children}
          </ul>
        </SlideDownTransition>
      </div>
    </div>
  );
}

CollapsablePanel.propTypes = {
  title: PropTypes.string.isRequired,
  startOpen: PropTypes.bool,
  forceOpen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf([CollapsablePanelItem]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf([CollapsablePanelItem]),
      }),
    ),
  ]),
  className: PropTypes.string,
  onOpenChange: PropTypes.func,
  itemId: PropTypes.number,
};

CollapsablePanel.defaultProps = {
  startOpen: false,
  forceOpen: false,
  children: [],
  className: '',
  onOpenChange: null,
  itemId: null,
};
