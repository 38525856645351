import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FavoriteButton from './FavoriteButton';

export default function CollapsablePanelItem({
  className,
  children,
  onClick,
  isFavorited,
  onToggleFavorite,
  suppressBorder,
}) {
  const [currentFavorite, setCurrentFavorite] = useState(isFavorited);

  // We use optimistic updates to change star fill upon clicking
  // This effect updates the fill based on eventual refetch from server
  useEffect(() => {
    setCurrentFavorite(isFavorited);
  }, [isFavorited, setCurrentFavorite]);

  const handleStarClick = () => {
    setCurrentFavorite(!currentFavorite);
    onToggleFavorite();
  };

  return (
    <li
      className={classNames({
        [`im-cp-item im-column-fix hover:tw-bg-gray-100 ${className}`]: true,
        'tw-border-b-gray-200 last:tw-border-b-white': !suppressBorder,
        'tw-border-b-gray-200 xl:tw-border-b-white': suppressBorder,
      })}
    >
      <div className="tw-flex tw-justify-between tw-w-full">
        <button
          type="button"
          onClick={onClick}
          className="im-clear im-cp-clickable hover:tw-font-bold tw-w-full tw-text-left tw-cursor-pointer"
        >
          {children}
        </button>
        <FavoriteButton
          onClick={handleStarClick}
          isFavorite={currentFavorite}
        />
      </div>
    </li>
  );
}

CollapsablePanelItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
  isFavorited: PropTypes.bool,
  onToggleFavorite: PropTypes.func,
  suppressBorder: PropTypes.bool,
};

CollapsablePanelItem.defaultProps = {
  className: '',
  onClick: null,
  isFavorited: false,
  onToggleFavorite: null,
  suppressBorder: false,
};
