import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useReport({
  reportId, filterParams = {}, search = '', equipmentId, inventoryId, refetchKey,
}) {
  const baseParams = {
    preflight: 'y',
    ...(search && { search }),
    ...(equipmentId && { equipment_id: equipmentId }),
    ...(inventoryId && { inventory_id: inventoryId }),
  };

  const params = { ...baseParams, ...filterParams };

  return useQuery(
    reportId && ['reports', reportId, filterParams, search, equipmentId, inventoryId, refetchKey],
    () => axios.get(`/reports/${reportId}.json`, { params }).then((response) => response.data),
    {
      cacheTime: 0,
      retry: 2,
    },
  );
}
