import React from 'react';
import PropTypes from 'prop-types';

function HiddenStep({ step, index, createRepairRequest, repairPriority }) {
  return (
    <>
      <input
        type="hidden"
        value={step.name}
        name={`inspection[inspection_steps_attributes][${index}][name]`}
      />
      <input
        type="hidden"
        value={step.id}
        name={`inspection[inspection_steps_attributes][${index}][id]`}
      />
      {step.pass !== null && (
        <input
          type="hidden"
          value={step.pass}
          name={`inspection[inspection_steps_attributes][${index}][pass]`}
          className="inspection-step-result"
        />
      )}
      <input
        type="hidden"
        value={createRepairRequest}
        name={`inspection[inspection_steps_attributes][${index}][create_repair_request]`}
      />
      <input
        type="hidden"
        value={repairPriority}
        name={`inspection[inspection_steps_attributes][${index}][repair_priority]`}
      />
    </>
  );
}

export default React.memo(HiddenStep);

HiddenStep.propTypes = {
  step: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  createRepairRequest: PropTypes.bool.isRequired,
  repairPriority: PropTypes.string.isRequired,
};
