import React, { useContext, useRef } from 'react';
import { InspectionsContext } from './data/InspectionsContextManager';
import Step from './Step';
import SpinnerMessage from '../SpinnerMessage';
import StepFilters from './StepFilters';
import ErrorMessage from '../ErrorMessage';

export default function InspectionStepsApp() {
  const {
    steps,
    inspectionCompleted,
    fetchProcessing,
    fetchError,
    stepsIncompleteCount,
    autoScrollOnPass,
    allowAllOk,
    allOk,
    readOnly,
    allItemsPassed,
    filterPassFail,
    visibleStepIds,
    scored,
    totalScore,
  } = useContext(InspectionsContext);
  const stepRefs = useRef([]);

  const handleScrollToNext = () => {
    if (!autoScrollOnPass || stepsIncompleteCount < 1) return;

    const firstIncompleteIndex = steps.findIndex((step) => step.pass === null && !step.section_header);
    const element = stepRefs.current[firstIncompleteIndex];

    if (element) {
      const offset = 120;
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleAllOkClick = () => {
    allOk();
  };

  let noRecordsMessage = (
    <p className="tw-mt-4 tw-text-center tw-text-primary">
      No steps match filters. Try adjusting filter values.
    </p>
  );

  if (visibleStepIds.length === 0 && allItemsPassed && readOnly && filterPassFail === 0) {
    noRecordsMessage = (
      <>
        <h1>
          All items passed
        </h1>
        <p className="tw-mt-4 tw-text-primary">
          Adjust filter values above to see passed items.
        </p>
      </>
    );
  }
  return (
    <>
      {fetchProcessing && (
        <div className="tw-w-full tw-text-center tw-py-6">
          <SpinnerMessage message="Loading..." />
        </div>
      )}
      {fetchError && (
        <ErrorMessage details={fetchError} />
      )}
      <div className={fetchProcessing ? 'tw-hidden' : ''}>
        {!fetchError && (
          <>
            {inspectionCompleted && (
              <StepFilters />
            )}
            {steps.map((step, index) => (
              <Step
                key={step.id}
                step={step}
                index={index}
                stepRef={(el) => { stepRefs.current[index] = el; }}
                onScrollToNext={handleScrollToNext}
              />
            ))}
            {visibleStepIds.length === 0 && (
              noRecordsMessage
            )}
            <div className='tw-flex tw-justify-between'>
              {allowAllOk && !readOnly && visibleStepIds.length !== 0 && (
                <div className='tw-content-end sm:tw-content-normal'>
                  <button
                    type="button"
                    className="im-clear tw-mt-3 active:tw-shadow-inner tw-border-0 tw-cursor-pointer tw-justify-center tw-rounded-md tw-px-4 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-legacy-blue-focus tw-w-auto tw-bg-legacy-blue"
                    onClick={handleAllOkClick}
                  >
                    <i className="fa fa-check-double tw-mr-1" />
                    All OK
                  </button>
                </div>
              )}
              {scored && (
                <div className='sm:tw-flex sm:tw-pt-3 sm:tw-w-72'>
                  <label
                    className="tw-block tw-text-gray-700 tw-text-sm tw-my-2 sm:tw-w-9/12"
                    htmlFor="total_score"
                  >
                    Condition Score
                  </label>
                  <input
                    className='tw-shadow-none tw-rounded tw-font-bold'
                    name='inspection_total_score'
                    id='inspection_total_score'
                    value={totalScore}
                    disabled={true}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
