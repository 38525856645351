import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CustomizationWindow from './CustomizationWindow';

export default function CustomizationModalDataWrapper({
  railsFormToken,
  isOpen,
  onClose,
  title,
  customizationClass,
  customizationName,
  orgLogoPresent,
  report_title,
}) {
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  }));

  return (
    <QueryClientProvider client={queryClient}>
      <CustomizationWindow
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        customizationClass={customizationClass}
        customizationName={customizationName}
        orgLogoPresent={orgLogoPresent}
        railsFormToken={railsFormToken}
        report_title = {report_title}
      />
    </QueryClientProvider>
  );
}

CustomizationModalDataWrapper.propTypes = {
  railsFormToken: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  customizationClass: PropTypes.string.isRequired,
  customizationName: PropTypes.string.isRequired,
  orgLogoPresent: PropTypes.bool,
};

CustomizationModalDataWrapper.defaultProps = {
  isOpen: false,
  onClose: () => null,
  orgLogoPresent: false,
};
