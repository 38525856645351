import { useCallback } from 'react';

// Hook to handle AG Grid column visibility based on the screen size while
// honoring the legacy visible-* and hidden-* classes used elsewhere in the app.
const useAgColumnVisibility = (columnDefs, gridApiRef) => {
  return useCallback(() => {
    if (!gridApiRef.current) return;

    const width = window.innerWidth;

    // Breakpoints for column visibility based on those defined in _grid-settings.scss
    const breakpoints = {
      screenThree: 350,
      screenSix: 640,
      screenEight: 860,
    };

    const columnsToHideOrShow = columnDefs.map((colDef) => {
      if (!colDef.cellClass) return null;

      // Visibility rules based on those defined in _visible.scss
      const rules = [
        { match: 'visible-lg-md', isVisible: width >= breakpoints.screenSix },
        { match: 'visible-lg', isVisible: width >= breakpoints.screenEight },
        { match: 'visible-md', isVisible: width >= breakpoints.screenSix },
        { match: 'visible-sm', isVisible: width < breakpoints.screenSix },
        { match: 'visible-xs', isVisible: width <= breakpoints.screenThree },
        { match: 'hidden-sm-md', isVisible: width >= breakpoints.screenEight },
        { match: 'hidden-sm', isVisible: width >= breakpoints.screenSix },
        { match: 'hidden-xs', isVisible: width > breakpoints.screenThree },
        { match: 'hidden-md', isVisible: width < breakpoints.screenSix },
        { match: 'hidden-lg', isVisible: width < breakpoints.screenEight },
      ];

      const matchedRule = rules.find((rule) => colDef.cellClass.includes(rule.match));
      if (matchedRule) {
        return { colId: colDef.field, visible: matchedRule.isVisible };
      }

      return null;
    }).filter(Boolean);

    // Use the AG Grid's API to show or hide columns based on the visibility rules
    columnsToHideOrShow.forEach(({ colId, visible }) => {
      gridApiRef.current.setColumnsVisible([colId], visible);
    });
  }, [columnDefs, gridApiRef]);
};

export default useAgColumnVisibility;
