import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReportsApp from './ReportsApp';

export default function ReportsDataWrapper({
  railsFormToken,
  equipmentId,
  inventoryId,
  config,
  infiniteScroll,
  mobileApp,
}) {
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  }));

  return (
    <QueryClientProvider client={queryClient}>
      <ReportsApp
        railsFormToken={railsFormToken}
        equipmentId={equipmentId}
        inventoryId={inventoryId}
        config={config}
        infiniteScroll={infiniteScroll}
        mobileApp={mobileApp}
      />
    </QueryClientProvider>
  );
}

ReportsDataWrapper.propTypes = {
  railsFormToken: PropTypes.string.isRequired,
  equipmentId: PropTypes.number,
  inventoryId: PropTypes.number,
  config: PropTypes.string.isRequired,
  infiniteScroll: PropTypes.bool,
  mobileApp: PropTypes.bool,
};

ReportsDataWrapper.defaultProps = {
  equipmentId: null,
  inventoryId: null,
  infiniteScroll: true,
  mobileApp: false,
};
