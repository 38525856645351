import UsersBulkActions from '../bulkActions/UsersBulkActions';
import RepairTypesBulkActions from '../bulkActions/RepairTypesBulkActions';
import EquipmentBulkActions from '../bulkActions/EquipmentBulkActions';

// Mapping of table IDs to components
const tableIdToComponentMap = {
  'users-table': UsersBulkActions,
  'repair-types-table': RepairTypesBulkActions,
  'equipment-index-table': EquipmentBulkActions,
};

// Hook to return the appropriate bulk actions component for the given table ID
const useBulkActionsComponent = (tableId) => {
  // Get the component for the given table ID
  const BulkActionsComponent = tableIdToComponentMap[tableId];

  // If there's no component for this table ID, return null
  if (!BulkActionsComponent) {
    return null;
  }

  return BulkActionsComponent;
};

export default useBulkActionsComponent;
