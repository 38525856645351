import React from 'react';
import PropTypes from 'prop-types';
import { GridContextProvider } from '../AgGrid/data/GridContextManager';
import ViewerBody from './ViewerBody';

export default function Viewer({
  reportId,
  title,
  ajax,
  columnArray,
  chartArray,
  orderArray,
  filters,
  showCharts,
  serverSide,
  noRecords,
  customHeader,
  displayLength,
  currency,
  onRecordIdsChanged,
  onRecordsRetrieved,
  infiniteScroll,
  railsFormToken,
}) {
  return (
    <GridContextProvider
      ajax={ajax}
      columnArray={columnArray}
      contextType="viewer"
      currency={currency}
      displayLength={displayLength}
      filters={filters}
      infiniteScroll={infiniteScroll}
      onRecordIdsChanged={onRecordIdsChanged}
      onRecordsRetrieved={onRecordsRetrieved}
      orderArray={orderArray}
      railsFormToken={railsFormToken}
      reportId={reportId}
      serverSide={serverSide}
      title={title}
    >
      <ViewerBody
        chartArray={chartArray}
        showCharts={showCharts}
        noRecords={noRecords}
        customHeader={customHeader}
      />
    </GridContextProvider>
  );
}

Viewer.propTypes = {
  reportId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  ajax: PropTypes.string.isRequired,
  columnArray: PropTypes.arrayOf(PropTypes.object),
  chartArray: PropTypes.arrayOf(PropTypes.object),
  orderArray: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    ),
  ),
  filters: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  showCharts: PropTypes.bool,
  serverSide: PropTypes.bool,
  noRecords: PropTypes.bool,
  customHeader: PropTypes.node,
  displayLength: PropTypes.number,
  currency: PropTypes.object.isRequired,
  onRecordIdsChanged: PropTypes.func.isRequired,
  onRecordsRetrieved: PropTypes.func.isRequired,
  infiniteScroll: PropTypes.bool,
  railsFormToken: PropTypes.string.isRequired,
};

Viewer.defaultProps = {
  columnArray: [],
  chartArray: [],
  orderArray: [],
  filters: '',
  showCharts: true,
  serverSide: false,
  noRecords: false,
  customHeader: null,
  displayLength: 25,
  infiniteScroll: true,
};
