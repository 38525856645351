import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

function UsersBulkActions({
  recordIds,
  gridApi,
}) {
  const handleClick = () => {
    if (recordIds.length === 0) return;

    $.get('/bulk_edit_employees/new', { bulk_edit_employee: { employee_ids: recordIds } }, null, 'script');
  };

  return (
    <>
      <button
        type="button"
        className="btn-white-multiselect tw-mr-3"
        id="bulk-edit-employees"
        onClick={handleClick}
      >
        Edit
      </button>
      <span>
        {`${recordIds.length} item${recordIds.length === 1 ? '' : 's'}`}
      </span>
    </>
  );
}

UsersBulkActions.propTypes = {
  recordIds: PropTypes.array.isRequired,
  gridApi: PropTypes.object,
};

UsersBulkActions.defaultProps = {
  gridApi: null,
};

export default React.memo(UsersBulkActions);
