import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import $ from 'jquery';

export default function TakePictureModal({ isOpen, onClose, step }) {
  const handleTakePicture = () => {
    const form = $('form#file_upload.inspection-step-picture-uploader');
    form.data('objectId', step.id);
    $('#take-picture-input').click();
    onClose();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="im-clear tw-relative tw-z-10"
        onClose={() => null}
      >
        <Transition.Child
          as={Fragment}
          enter="tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
        </Transition.Child>

        <div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
          <div className="tw-flex tw-min-h-min tw-relative tw-top-10 tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
              enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            >
              <Dialog.Panel className="tw-relative tw-transform tw-overflow-show tw-rounded-xl tw-bg-white tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 tw-w-fit sm:tw-max-w-lg">
                <div className="tw-flex tw-flex-col tw-text-center tw-bg-white tw-rounded-t-xl tw-px-4 tw-pb-4 tw-pt-5 sm:tw-p-6 sm:tw-pb-4">
                  <h2 className="tw-font-extrabold">This inspection step requires a picture</h2>
                  <hr />
                  <h4 className="tw-m-4">
                    Snap a picture now using the camera button:
                  </h4>
                  <p className="tw-m-4">
                    <button
                      type="button"
                      className="take-picture-link lg-btn-round"
                      onClick={handleTakePicture}
                    >
                      <i className="far fa-camera" />
                    </button>
                  </p>
                </div>
                <div className="tw-bg-gray-100 tw-rounded-b-xl tw-px-4 tw-py-3 tw-mt-3">
                  <button
                    type="button"
                    className="im-clear tw-mt-3 active:tw-shadow-inner tw-inline-flex tw-cursor-pointer tw-w-full tw-justify-center tw-rounded-md tw-bg-white tw-px-4 tw-py-1 tw-text-sm tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 sm:tw-mt-0 sm:tw-w-auto"
                    onClick={onClose}
                  >
                    Close to add one later
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

TakePictureModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  step: PropTypes.object.isRequired,
};

TakePictureModal.defaultProps = {
  isOpen: false,
  onClose: () => null,
};
