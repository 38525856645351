/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';

function RepairWorkOrderStatus({ step }) {
  return (
    <>
      {(step.open_repairs.length > 0) && (
        <div className="tw-flex tw-justify-start tw-flex-col tw-mb-3 tw-text-sm">
          <div>Open Repairs:</div>
          <ul>
            {step.open_repairs.map((openRepair) => (
              <li key={openRepair.path}>
                <a href={openRepair.path} className="inspection-link tw-text-primary">
                  {openRepair.comment}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
      {step.has_repair_work_order && (
        <div className="tw-pl-4 tw-mb-2 tw-text-sm">
          <div>{step.repair_work_order_completed ? 'Repair completed on:' : 'Repair assigned to:'}</div>
          <a href={step.repair_work_order_path} className="tw-text-primary">
            Work Order {step.repair_work_order_number}
          </a>
        </div>
      )}
    </>
  );
}

RepairWorkOrderStatus.propTypes = {
  step: PropTypes.object.isRequired,
};

export default React.memo(RepairWorkOrderStatus);
