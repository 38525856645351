// Reports listed in this array will render within LegacyViewer powered by DataTables
const legacyReports = [
  'employee_equipment',
  'employee_renewal_report',
  'employee_sign_in',
  'employee_sign_out',
  'equipment_accident',
  'equipment_depreciation_summary',
  'equipment_expense_detail',
  'equipment_fluid_by_category',
  'equipment_fluid_detail',
  'equipment_fuel_by_state_or_province',
  'equipment_fuel_cost',
  'equipment_fuel_detail',
  'equipment_inspection_summary',
  'equipment_maintenance_cost',
  'equipment_maintenance_detail',
  'equipment_need_daily_inspection',
  'equipment_part_assignment',
  'equipment_usage_detail',
  'equipment_usage_summary',
  'fuel_tanks_receipt',
  'fuel_tanks_reorder',
  'fuel_tanks_stock_value',
  'fuel_tanks_usage_detail',
  'fuel_tanks_usage_summary',
  'inventory_pull_ticket',
  'inventory_receipt',
  'inventory_reorder',
  'inventory_return_detail',
  'inventory_stock_value',
  'inventory_stock_value_summary',
  'inventory_transfer',
  'inventory_usage_detail',
  'inventory_usage_summary',
  'invoice_summary',
  'job_site_equipment_summary',
  'purchase_order_summary',
  'repairs_downtime',
  'repairs_due_by_date',
  'repairs_due_list',
  'repairs_mttr',
  'task_productivity',
  'tasks_all_due',
  'tasks_by_date',
  'tasks_by_employee',
  'tasks_by_vendor',
  'technician_employee_cost_summary',
  'technician_vendor_cost_summary',
  'work_orders_closed_summary',
  'work_orders_outstanding_summary',
];

const showInLegacyViewer = (reportName) => {
  const betaFeaturesEnabled = document.querySelector('meta[name="enable-beta-features"]').getAttribute('content') === 'yes';

  return !betaFeaturesEnabled || legacyReports.includes(reportName);
};

export { showInLegacyViewer, legacyReports };
