import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-charts-enterprise';
import Toggle from '../Toggle';
import HtmlRenderer from './HtmlRenderer';
import ActionsMenuRenderer from './ActionsMenuRenderer';
import TooltipCellRenderer from './TooltipCellRenderer';
import ScrollToTopButton from './ScrollToTopButton';
import ExpandDetailRenderer from './ExpandDetailRenderer';
import DetailCellRenderer from './DetailCellRenderer';
import useServerSideDatasource from './data/useServerSideDatasource';
import CustomPagination from './CustomPagination';

function ServerSideGrid({
  ajax,
  columnArray,
  defaultColDef,
  columnDefs,
  gridStyle,
  displayLength,
  quickFilterText,
  tallRows,
  rowBuffer,
  setHaveError,
  setErrorMessage,
  expectedRecords,
  onBodyScroll,
  onColumnMoved,
  onFirstDataRendered,
  onGridReady,
  onPaginationCustomized,
  onRowClicked,
  onSortChanged,
  onRecordsRetrieved,
  onSelectionChanged,
  isRowSelectable,
  allowGetRowId,
  infiniteScroll,
  squareBorder,
  masterDetail,
}) {
  const [groupByColumn, setGroupByCol] = useState();
  const [enableServerGroupBy, setEnableServerGroupBy] = useState(true);
  const [totalsRowData, setTotalsRowData] = useState([]);
  const detailCellRenderer = useCallback(DetailCellRenderer, []);

  // Effect to keep the groupByCol state value updated when column defs change
  useEffect(() => {
    setGroupByCol(columnDefs.find((e) => e.cellClass && e.cellClass.includes('group-by')));
  }, [columnDefs]);

  // Get the datasource from the custom hook
  const { datasource } = useServerSideDatasource(
    ajax,
    columnArray,
    columnDefs,
    displayLength,
    groupByColumn,
    enableServerGroupBy,
    quickFilterText,
    setTotalsRowData,
    setHaveError,
    setErrorMessage,
    onRecordsRetrieved,
    onPaginationCustomized,
  );

  // Function to determine row class
  const getRowClass = useCallback((params) => {
    if (!params.data) return '';
    if (params.data.isHeader) return 'ag-row-group';

    let classes = 'tw-cursor-pointer';
    if (masterDetail) {
      classes += ' im-master-detail';
    }

    if (params.data?.DT_RowClass && params.data.DT_RowClass.includes('priority-high-or-overdue')) {
      classes += ' priority-high-or-overdue';
    }
    return classes;
  }, [masterDetail]);

  // Turn on/off server-side grouping
  const handleEnableGroupByChange = () => {
    setEnableServerGroupBy(!enableServerGroupBy);
  };

  // Needed for server-side row selection
  const getRowId = (params) => params.data.id;

  const statusBar = useMemo(() => ({
    statusPanels: [
      {
        statusPanel: CustomPagination,
        align: 'center',
      },
    ],
  }), []);

  return (
    <>
      {groupByColumn && (
        <div className="im-clear tw-flex tw-justify-start tw-items-center tw-w-full tw-mb-2 tw-ml-2">
          <Toggle
            id="enable-grouping"
            value={enableServerGroupBy}
            onChange={handleEnableGroupByChange}
            labelText={`Group by ${groupByColumn.headerName}`}
            formControlClass="tw-w-40"
            labelClass="tw-du-label-text tw-mr-1 tw-w-full tw-text-left"
            inputClass="tw-du-toggle-sm tw-du-toggle-primary"
            toggleBeforeLabel
          />
        </div>
      )}
      <div
        style={gridStyle}
        className={`ag-theme-quartz tw-relative ${squareBorder ? 'ag-zero-border-radius' : ''}`}
      >
        <AgGridReact
          cacheBlockSize={displayLength}
          columnDefs={columnDefs}
          components={{
            htmlRenderer: HtmlRenderer,
            actionsMenuRenderer: ActionsMenuRenderer,
            tooltipCellRenderer: TooltipCellRenderer,
            expandDetailRenderer: ExpandDetailRenderer,
          }}
          defaultColDef={defaultColDef}
          getRowClass={getRowClass}
          getRowId={allowGetRowId ? getRowId : undefined}
          isRowSelectable={isRowSelectable}
          maxConcurrentDatasourceRequests={1}
          onBodyScroll={onBodyScroll}
          onColumnMoved={onColumnMoved}
          onFirstDataRendered={onFirstDataRendered}
          onGridReady={onGridReady}
          onRowClicked={onRowClicked}
          onSelectionChanged={onSelectionChanged}
          onSortChanged={onSortChanged}
          pinnedBottomRowData={totalsRowData}
          reactiveCustomComponents
          rowBuffer={rowBuffer}
          rowHeight={tallRows ? 73 : 42}
          rowModelType="serverSide"
          rowSelection={allowGetRowId ? 'multiple' : undefined }
          serverSideDatasource={datasource}
          serverSideInitialRowCount={expectedRecords}
          suppressCellFocus
          suppressContextMenu
          suppressDragLeaveHidesColumns
          suppressRowClickSelection
          suppressScrollOnNewData
          suppressServerSideFullWidthLoadingRow
          domLayout={infiniteScroll ? undefined : 'autoHeight'}
          pagination={!infiniteScroll}
          paginationPageSize={displayLength}
          paginateChildRows
          paginationPageSizeSelector={false}
          masterDetail={masterDetail}
          detailCellRenderer={masterDetail ? detailCellRenderer : undefined}
          detailRowAutoHeight
          suppressPaginationPanel
          statusBar={!infiniteScroll ? statusBar : undefined}
        />
        <ScrollToTopButton />
      </div>
    </>
  );
}

ServerSideGrid.propTypes = {
  ajax: PropTypes.string.isRequired,
  columnArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultColDef: PropTypes.object.isRequired,
  columnDefs: PropTypes.arrayOf(PropTypes.object).isRequired,
  gridStyle: PropTypes.object.isRequired,
  displayLength: PropTypes.number,
  quickFilterText: PropTypes.string,
  tallRows: PropTypes.bool,
  rowBuffer: PropTypes.number,
  expectedRecords: PropTypes.number,
  setHaveError: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  onBodyScroll: PropTypes.func,
  onColumnMoved: PropTypes.func,
  onFirstDataRendered: PropTypes.func.isRequired,
  onGridReady: PropTypes.func,
  onPaginationCustomized: PropTypes.func,
  onRowClicked: PropTypes.func.isRequired,
  onSortChanged: PropTypes.func.isRequired,
  onRecordsRetrieved: PropTypes.func.isRequired,
  onSelectionChanged: PropTypes.func,
  isRowSelectable: PropTypes.func,
  allowGetRowId: PropTypes.bool,
  infiniteScroll: PropTypes.bool,
  squareBorder: PropTypes.bool,
  masterDetail: PropTypes.bool,
};

ServerSideGrid.defaultProps = {
  displayLength: 25,
  quickFilterText: '',
  tallRows: false,
  rowBuffer: 10,
  onBodyScroll: () => {},
  onColumnMoved: () => {},
  onGridReady: () => {},
  onPaginationCustomized: () => {},
  onSelectionChanged: () => {},
  isRowSelectable: () => true,
  allowGetRowId: false,
  infiniteScroll: true,
  squareBorder: false,
  masterDetail: false,
  expectedRecords: undefined,
};

export default React.memo(ServerSideGrid);
