import React from 'react';
import PropTypes from 'prop-types';
import { InspectionsContextProvider } from './data/InspectionsContextManager';
import ErrorBoundary from '../ErrorBoundary';
import InspectionStepsApp from './InspectionStepsApp';

export default function InspectionSteps({
  equipmentId,
  inspectionId,
  config,
  railsFormToken,
  readOnly,
  pendingAttributes,
}) {
  return (
    <InspectionsContextProvider
      equipmentId={equipmentId}
      inspectionId={inspectionId}
      railsFormToken={railsFormToken}
      readOnly={readOnly}
      pendingAttributes={pendingAttributes}
    >
      <ErrorBoundary config={config} errorContext="Inspection Steps">
        <InspectionStepsApp />
      </ErrorBoundary>
    </InspectionsContextProvider>
  );
}

InspectionSteps.propTypes = {
  equipmentId: PropTypes.number.isRequired,
  inspectionId: PropTypes.number.isRequired,
  config: PropTypes.string.isRequired,
  railsFormToken: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  pendingAttributes: PropTypes.arrayOf(PropTypes.object),
};

InspectionSteps.defaultProps = {
  readOnly: false,
  pendingAttributes: null,
};
