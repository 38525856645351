import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

export default function PictureButtons({ step, deviceHasCamera }) {
  const handleSelectPicture = () => {
    const form = $('form#file_upload.inspection-step-picture-uploader');
    form.data('objectId', step.id);
    $('#select-picture-input').click();
  };

  const handleTakePicture = () => {
    const form = $('form#file_upload.inspection-step-picture-uploader');
    form.data('objectId', step.id);
    $('#take-picture-input').click();
  };

  return (
    <>
      {deviceHasCamera && (
        <div className="tw-flex">
          <button
            className="im-clear tw-px-3 active:tw-shadow-inner tw-cursor-pointer tw-justify-center tw-rounded tw-bg-muted-light tw-text-lg tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-muted-dark hover:tw-bg-neutral-50"
            type="button"
            onClick={handleTakePicture}
          >
            <i className="far fa-camera" />
          </button>
          <button
            className="im-clear tw-ml-1 tw-px-1 active:tw-shadow-inner tw-cursor-pointer tw-justify-center tw-rounded tw-bg-muted-light tw-text-lg tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-muted-dark hover:tw-bg-neutral-50"
            type="button"
            onClick={handleSelectPicture}
          >
            <i className="far fa-ellipsis-h" />
          </button>
        </div>
      )}
      {!deviceHasCamera && (
        <button
          className="im-clear tw-px-3 active:tw-shadow-inner tw-cursor-pointer tw-justify-center tw-rounded tw-bg-muted-light tw-text-lg tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-muted-dark hover:tw-bg-neutral-50"
          type="button"
          onClick={handleSelectPicture}
        >
          <i className="far fa-camera" />
        </button>
      )}
    </>
  );
}

PictureButtons.propTypes = {
  step: PropTypes.object.isRequired,
  deviceHasCamera: PropTypes.bool,
};

PictureButtons.defaultProps = {
  deviceHasCamera: false,
};
