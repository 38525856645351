import { useEffect, useRef } from 'react';

/*
Hook for situations in which we need to consume events and data from Rails.
Used in conjunction with the ReactEvents utility, the event with optional
data can be sent from Rails to React.

The default event name is 'RailsEvent', but custom names may be used to handle multiple events.

Example Rails side:
dataToSend = { fieldOne: 'Field One', fieldTwo: 'Field Two' }
Utility.ReactEvents.trigger(dataToSend, 'CustomEventName')

Example React side:
const handleRailsEvent = (eventData) => {
  console.log('Received Rails data', eventData);
};

useRailsEvent(handleRailsEvent, 'CustomEventName');
*/

function useRailsEvent(callback, eventName = 'RailsEvent') {
  const callbackRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const eventListener = (event) => {
      if (event.detail) {
        callbackRef.current(event.detail);
      } else {
        callbackRef.current();
      }
    };

    document.addEventListener(eventName, eventListener);

    return () => {
      document.removeEventListener(eventName, eventListener);
    };
  }, [eventName]);
}

export default useRailsEvent;
