import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import DraggableFieldName from './DraggableFieldName';

export default function ReorderableFields({ fields, onClick, droppableId, inListReorder, dragBackgroundClass }) {
  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={`tw-border-solid tw-bg- tw-border ${snapshot.isDraggingOver ? `${dragBackgroundClass} tw-outline-2 tw-outline-primary tw-outline-dashed` : ''} tw-border-gray-200 tw-transition-colors tw-duration-200 tw-ease-in tw-rounded tw-p-4 tw-mb-3`}
          style={{ minHeight: '70px' }}
        >
          {fields.map((field, index) => (
            <DraggableFieldName
              key={field.name}
              field={field}
              index={index}
              onClick={onClick}
              inListReorder={inListReorder}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}
