import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useLocalStorageState from './hooks/useLocalStorageState';

export default function SlidingSidebar({
  className, title, isOpen, children, navClassName,
}) {
  const [sidebarOpen, setSidebarOpen] = useLocalStorageState(isOpen, 'report-quick-launch-open');

  return (
    <>
      <aside
        className={classNames({
          [`im-ssb-container ${className}`]: true,
          '-tw-ml-48': !sidebarOpen,
        })}
      >
        <nav className="im-ssb-nav">
          {children}
        </nav>
      </aside>

      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <nav
        className={`im-ssb-border-nav ${navClassName}`}
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <button
          type="button"
          className="im-clear tw-rotate-90 tw-whitespace-nowrap tw-text-white hover:tw-text-white focus:tw-text-white tw-w-full"
        >
          <i
            className={classNames({
              'fas fa-chevron-down im-ssb-border-chevron': true,
              'tw-rotate-180': !sidebarOpen,
            })}
          />
          {title}
        </button>
      </nav>
    </>
  );
}

SlidingSidebar.propTypes = {
  className: PropTypes.string,
  navClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

SlidingSidebar.defaultProps = {
  className: '',
  navClassName: '',
  isOpen: false,
  title: '',
  children: [],
};
