import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReportsDataWrapper from './ReportsDataWrapper';
import ErrorBoundary from '../ErrorBoundary';

export default function Reports({
  railsFormToken,
  equipmentId,
  inventoryId,
  config,
  infiniteScroll,
  mobileApp,
}) {
  const appRoot = (
    <ReportsDataWrapper
      railsFormToken={railsFormToken}
      equipmentId={equipmentId}
      inventoryId={inventoryId}
      config={config}
      infiniteScroll={infiniteScroll}
      mobileApp={mobileApp}
    />
  );

  return (
    <ErrorBoundary config={config} errorContext="Reports">
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={appRoot} />
          <Route path="/reports/:reportId" element={appRoot} />
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

Reports.propTypes = {
  railsFormToken: PropTypes.string.isRequired,
  equipmentId: PropTypes.number,
  inventoryId: PropTypes.number,
  config: PropTypes.string.isRequired,
  infiniteScroll: PropTypes.bool,
  mobileApp: PropTypes.bool,
};

Reports.defaultProps = {
  equipmentId: null,
  inventoryId: null,
  infiniteScroll: true,
  mobileApp: false,
};
