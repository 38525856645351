import { useCallback } from 'react';
import axios from 'axios';

function getForbiddenPositions(columnDefs) {
  // Return the positions of columns that are not allowed to be moved
  // This will help us ensure no moveable columns can be moved to these positions
  const visibleColumnDefs = columnDefs.filter((columnDef) => !columnDef.hide);
  const forbiddenPositions = visibleColumnDefs
    .filter((columnDef) => columnDef.suppressMovable)
    .map((columnDef) => visibleColumnDefs.indexOf(columnDef) + 1);

  // Zero position is always forbidden
  forbiddenPositions.unshift(0);
  return forbiddenPositions;
}

function getRevisedPosition(toIndex, forbiddenPositions) {
  // When user attempts to move a column into a forbidden position,
  // determine a revised position: the closest allowed position to the left
  // or right of the forbidden position (depending on position of the column being moved)
  let lastContiguousForbidden = forbiddenPositions[0];

  for (let i = 1; i < forbiddenPositions.length; i++) {
    if (forbiddenPositions[i] === lastContiguousForbidden + 1) {
      lastContiguousForbidden = forbiddenPositions[i];
    } else {
      break;
    }
  }

  // Determine the direction based on the position of the column being moved
  let direction = toIndex <= lastContiguousForbidden ? 1 : -1;

  // If the first forbidden position is not 0, then the direction should be
  // negative when attempting to move to the first forbidden position
  if (forbiddenPositions[0] !== 0 && toIndex === forbiddenPositions[0]) {
    direction = -1;
  }

  // Find the closest allowed position
  let newPosition = toIndex;
  while (forbiddenPositions.includes(newPosition)) {
    newPosition += direction;
    if (newPosition < 1) {
      newPosition = lastContiguousForbidden + 1;
      break;
    }
  }

  // Bale out if the new position is still forbidden
  if (forbiddenPositions.includes(newPosition)) return -1;

  return newPosition;
}

async function saveColumnOrder(params, customizationClass, customizationName, railsFormToken) {
  if (!customizationClass || !customizationName || !railsFormToken) return;

  // Save the column order to the server's customization record
  const columnOrder = params.api.getAllDisplayedColumns().map((column) => column.colId);
  const response = await axios.get(`/${customizationClass}/${customizationName}.json`);
  if (response.status !== 200) return;

  const displayedFields = response.data.displayed_fields;
  const hiddenFields = response.data.hidden_fields;

  // Filter columnOrder to keep only the columns that are present in displayed_fields
  const filteredColumnOrder = columnOrder.filter((c) => displayedFields.some((f) => f.name === c));

  // Combine this filtered list with hidden_fields
  const columnOrderToSave = [...filteredColumnOrder, ...hiddenFields.map((f) => f.name)];

  await axios.patch(`/${customizationClass}/${response.data.id}.json`, {
    customization: {
      ordered_field_array: columnOrderToSave,
    },
    authenticity_token: railsFormToken,
  });
}

export default function useSavedColumnPositions(columnDefs, customizationClass, customizationName, railsFormToken) {
  return useCallback(async (params) => {
    if (!params.finished) return;

    const forbiddenPositions = getForbiddenPositions(columnDefs);

    // Check if the column has been moved to a forbidden position
    if (forbiddenPositions.includes(params.toIndex)) {
      const newPosition = getRevisedPosition(params.toIndex, forbiddenPositions);
      // If so, move it to the first allowed position
      params.api.moveColumns([params.column], newPosition);
    } else {
      await saveColumnOrder(params, customizationClass, customizationName, railsFormToken);
    }
  }, [columnDefs, customizationClass, customizationName, railsFormToken]);
}
