import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import Chart from '../Chart';
import SpinnerMessage from '../SpinnerMessage';

// Display report within legacy Datatables
export default function LegacyViewer({
  title,
  ajax,
  columnArray,
  chartArray,
  orderArray,
  showCharts,
  serverSide,
  customHeader,
  displayLength,
}) {
  // eslint-disable-next-line global-require,import/no-extraneous-dependencies
  $.DataTable = require('datatables.net');
  const tableRef = useRef();
  const containerRef = useRef();
  const { current: columns } = useRef(columnArray);

  const drawCallback = () => {
    const api = $(tableRef.current).dataTable().api();
    const rows = api.rows({ page: 'current' }).nodes();

    // Remove existing click event handlers from the rows
    $(rows).off('click');

    // Add click event handler to each row
    $(rows).on('click', function (e) {
      if (!$(e.target).hasClass('inner-link') && ($(e.target).attr('data-method') != 'delete')) {
        const rowData = api.row(this).data();
        const rowUrl = rowData.DT_RowData.url;
        const rowRemote = rowData.DT_RowData.remote;

        // Navigate to the URL based on the remote boolean
        if (rowRemote) {
          window.location.href = rowUrl;
        } else {
          window.open(rowUrl, '_self');
        }
      }
    });

    let last = null;

    const nodes = api.column('.group-by', { page: 'current' }).nodes();
    if (nodes !== undefined) {
      nodes.each((groupNode, i) => {
        const group = $(groupNode).text();
        if (last !== group) {
          let row = '<tr class="group">';
          let group_set = false
          api.columns().iterator('column', (content, index) => {
            const column = api.column(index);

            if (column.visible()) {
              if ($(column.header()).hasClass('subtotal')) {
                row += `<td class="text-right">${api.rows({ page: 'current' }).data()[i][`${api.settings()[0].aoColumns[index].data}_subtotal`]}</td>`;
              }
              else if (!group_set) {
                group_set = true
                row += `<td>${group}</td>`;
              }
              else {
                row += '<td></td>';
              }
            }
          });

          row += '</tr>';
          $(rows).eq(i).before(row);
          last = group;
          $('.dataTables_scrollFoot').show();
          $('#report-table').closest('.dataTables_scrollBody').height('inherit');
        }
      });
    }

    // Make the UX a bit smoother by keeping the table and charts hidden until all records
    // have been processed.
    if (api.data().any() || api.rows().count() === 0) {
      // Because of contention between React and JQuery DOM manipulation, we have to go old-school
      // here with JQuery-based classname changes. A modern React state change would force a
      // rerender and thereby make Jquery and Datatables flicker.
      $('#loading-indicator').addClass('tw-hidden');
      $('#data-container-table').addClass('tw-transition-opacity tw-duration-500 tw-opacity-100');

      // Give the charts' appearance a slight delay to make experience feel less jumpy
      setTimeout(() => {
        $('#charts').addClass('tw-transition-opacity tw-duration-700 tw-opacity-100');
      }, 500);
    }
  };

  const footerCallback = () => {
    const api = $(tableRef.current).dataTable().api();
    api.columns('.total').iterator('column', (context, index) => {
      const column = api.column(index);

      if (api.row(0).data() !== undefined) {
        $(column.footer())
          .html(api.row(0).data()[`${api.table().settings()[0].aoColumns[index].data}_total`]);
      }
    });
  };

  useEffect(() => {
    let savedOrder = JSON.parse(localStorage.getItem(`ReportOrder_${title}`));

    // Reset order if column count no longer matches
    if (savedOrder && (savedOrder.length > 0)) {
      for (let i = 0; i < savedOrder.length; i++) {
        if (savedOrder[i][0] > (columns.length - 1)) {
          savedOrder = null;
          break;
        }
      }
    }

    // Reset order if saved order is in new AG Grid format
    if (savedOrder && (savedOrder.length > 0)) {
      if (savedOrder.some((c) => typeof c[0] !== 'number')) savedOrder = null;
    }

    const table = $(tableRef.current).DataTable({
      responsive: true,
      ajax,
      columns,
      order: savedOrder || orderArray,
      serverSide,
      destroy: true,
      searching: false,
      drawCallback,
      footerCallback,
      displayLength,
      scrollX: true,
    });

    // Datatables has a resizing quirk causing column headers to become unaligned
    // with the data. This resize observer fixes it.
    const resizeObserver = new ResizeObserver(() => {
      $.fn.dataTable.tables({ visible: true, api: true }).columns.adjust();

      Highcharts.charts.forEach((chart) => {
        if (chart !== undefined){
          chart.reflow();
        }
      });
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Save table order to localStorage whenever it changes
    table.on('order.dt', () => {
      // Remove existing sorting icons
      $('.DataTables_sort_icon').removeClass('fas fa-chevron-down fas fa-chevron-up');

      // Get current sorting column and direction
      const order = table.order();

      if (order.length) {
        const sortingColumn = order[0][0];
        const sortingDirection = order[0][1];

        // Add new sorting icons
        $('.sorting').each(function () {
          if ($(this).index() === sortingColumn) {
            if (sortingDirection === 'asc') {
              $('.DataTables_sort_icon', this).addClass('fas fa-chevron-up');
            } else {
              $('.DataTables_sort_icon', this).addClass('fas fa-chevron-down');
            }
          }
        });

        localStorage.setItem(`ReportOrder_${title}`, JSON.stringify(order));
      }
    });

    return () => {
      if (containerRef.current) resizeObserver.disconnect();
      table.off('order.dt');
      table.destroy();
    };
  }, [columns, ajax]);

  return (
    <>
      <div
        id="loading-indicator"
        className="tw-text-neutral-600 tw-h-96 tw-align-middle tw-flex tw-flex-col tw-justify-center tw-items-center"
      >
        <SpinnerMessage message="" />
      </div>
      <div
        id="data-container-table"
        className="table-float tw-opacity-0"
        ref={containerRef}
      >
        <h1 className="tw-mb-2 tw-max-w-[70%]">{title}</h1>
        {customHeader}
        <div
          id="charts"
          className="tw-opacity-0"
        >
          {chartArray.map((chartOptions, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Chart key={index} chartOptions={chartOptions} show={showCharts} />
          ))}
        </div>
        <table
          id="report-table"
          ref={tableRef}
          className="report-table index-table row-links table-select"
        >
          <thead />
          <tfoot>
            <tr>
              {columnArray.map((col, index) => (
                // eslint-disable-next-line max-len
                // eslint-disable-next-line react/no-array-index-key,jsx-a11y/control-has-associated-label
                <th key={index} />
              ))}
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
}

LegacyViewer.propTypes = {
  title: PropTypes.string.isRequired,
  ajax: PropTypes.string.isRequired,
  columnArray: PropTypes.arrayOf(PropTypes.object),
  chartArray: PropTypes.arrayOf(PropTypes.object),
  orderArray: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    ),
  ),
  showCharts: PropTypes.bool,
  serverSide: PropTypes.bool,
  customHeader: PropTypes.node,
  displayLength: PropTypes.number,
};

LegacyViewer.defaultProps = {
  columnArray: [],
  chartArray: [],
  orderArray: [],
  showCharts: true,
  serverSide: false,
  customHeader: null,
  displayLength: 25,
};
