import React from 'react';
import PropTypes from 'prop-types';

export default function ErrorMessage({ details }) {
  return (
    <div className="tw-flex tw-h-[calc(100vh-80px)] tw-items-center tw-justify-center tw-p-5 tw-w-full tw-bg-transparent">
      <div className="tw-text-center tw-shadow-md tw-border-solid tw-border-2 tw-border-red-300 tw-rounded-2xl tw-bg-red-50 tw-p-5">
        <div className="tw-text-red-700 tw-font-extrabold tw-text-6xl">
          <i className="fas fa-exclamation-circle tw-m-0" />
        </div>
        <h1 className="tw-mt-5 tw-font-bold tw-text-slate-800 tw-text-3xl">
          Application Error
        </h1>
        <p className="tw-text-slate-600 tw-mt-5 lg:tw-text-lg">
          We&apos;re sorry, but something went wrong. Please try again.
          <br />
          Feel free to contact us if the problem persists.
        </p>
        {details && (
          <div>
            <h6 className="tw-mt-6 tw-font-bold mt-mb-2">Details:</h6>
            <p className="tw-font-mono tw-my-3 tw-text-xs lg:tw-text-sm tw-text-slate-500">
              {details}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

ErrorMessage.propTypes = {
  details: PropTypes.string,
};

ErrorMessage.defaultProps = {
  details: null,
};
