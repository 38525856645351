import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';

function SlideDownTransition({ className, children, show }) {
  return (
    <Transition
      className={className}
      show={show}
      enter="tw-transition-all tw-ease-in-out tw-duration-500 tw-delay-150"
      enterFrom="tw-opacity-0 tw-max-h-0"
      enterTo="tw-opacity-100 tw-max-h-[100rem]"
      leave="tw-transition-all tw-ease-in-out tw-duration-300"
      leaveFrom="tw-opacity-100 tw-max-h-[100rem]"
      leaveTo="tw-opacity-0 tw-max-h-0"
    >
      {children}
    </Transition>
  );
}

SlideDownTransition.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  show: PropTypes.bool,
};

SlideDownTransition.defaultProps = {
  className: '',
  children: [],
  show: false,
};

export default SlideDownTransition;
