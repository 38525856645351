import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { FixedSizeList as List } from 'react-window';

export default function FilterDropdownButton({
  options, currentText, currentSubtext, onClick, onLaunchCustom, highlight, id,
}) {
  const handleOptionClick = (option) => {
    if (option.launch_custom) {
      onLaunchCustom({ item: option });
    } else if (option.parentItem) {
      onClick({ item: option, parentItem: option.parentItem });
    } else {
      onClick({ item: option });
    }
  };

  const classesForOption = (active, isCategory) => classNames({
    'im-clear tw-w-full tw-cursor-pointer tw-text-left tw-block tw-px-4 tw-py-2 tw-text-sm': true,
    'tw-bg-primary-content tw-text-gray-900': active,
    'tw-text-gray-700': !active,
    'tw-font-bold': !isCategory,
  });

  const flattenOptions = (optionsToFlatten) => {
    const result = [];
    optionsToFlatten.forEach((option) => {
      result.push({ ...option, depth: 0 });
      if (option.categories) {
        option.categories.forEach((category) => {
          result.push({ ...category, depth: 1, parentItem: option });
        });
      }
    });
    return result;
  };

  const flatOptions = flattenOptions(options);

  const renderRow = ({ index, style }) => {
    const option = flatOptions[index];
    return (
      <div
        key={`item-${option.filter_value}`}
        style={{ ...style, paddingLeft: `${option.depth * 20}px` }}
        className={classNames({
          'im-divider-bottom': option.bottom_divide,
          'im-divider-top': option.top_divide,
        })}
      >
        {!option.as_link && !option.as_remote_link && (
          <Menu.Item>
            {({ active }) => (
              // option.depth === 1 for categories
              <button
                type="button"
                onClick={() => handleOptionClick(option)}
                className={classesForOption(active, option.depth === 1)}
              >
                {option.name}
              </button>
            )}
          </Menu.Item>
        )}
        {(option.as_link || option.as_remote_link) && (
          <Menu.Item>
            {({ active }) => (
              <a
                type="button"
                href={option.url || option.remote_url}
                data-remote={option.as_remote_link ? 'true' : 'false'}
                data-no-react-unmount="true"
                className={classesForOption(active, option.depth === 1)}
              >
                {option.name}
              </a>
            )}
          </Menu.Item>
        )}
      </div>
    );
  };

  // Do some over-simplified dynamic sizing based on text width
  // This avoids having to drop down into React rendering, which could impact performance
  const maxLengthOption = Math.max(...flatOptions.map((option) => option.name.length));
  const estimatedWidth = maxLengthOption * (60 / Math.sqrt(maxLengthOption));

  return (
    <Menu as="div" id={id} className="im-clear tw-flex-none tw-mr-2 tw-mb-2 md:tw-relative tw-inline-block tw-text-left">
      <div>
        <Menu.Button className={`im-clear tw-inline-flex tw-w-full tw-justify-center tw-gap-x-1.5 tw-rounded-md tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset ${highlight ? 'tw-ring-primary tw-bg-blue-100 hover:tw-bg-blue-50' : 'tw-ring-gray-300 tw-bg-white hover:tw-bg-gray-50'} `}>
          {currentText}
          {currentSubtext && (
            <>
              <span>{'>'}</span>
              <span className="tw-font-normal">
                {currentSubtext}
              </span>
            </>
          )}
          <ChevronDownIcon className="-tw-mr-1 tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="tw-transition tw-ease-out tw-duration-100"
        enterFrom="tw-transform tw-opacity-0 tw-scale-95"
        enterTo="tw-transform tw-opacity-100 tw-scale-100"
        leave="tw-transition tw-ease-in tw-duration-75"
        leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
        leaveTo="tw-transform tw-opacity-0 tw-scale-95"
      >
        <Menu.Items className="tw-absolute tw-left-10 tw-z-40 tw-mt-2 tw-min-w-56 tw-w-max tw-overflow-auto tw-border-solid tw-shadow-md tw-shadow-gray-500 tw-border-gray-200 tw-origin-top-right tw-rounded-md tw-bg-neutral-100 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
          <List
            height={Math.min(500, (flatOptions.length * 35) + 5)}
            itemCount={flatOptions.length}
            itemSize={35}
            width={estimatedWidth}
          >
            {renderRow}
          </List>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

FilterDropdownButton.propTypes = {
  options: PropTypes.array,
  currentText: PropTypes.string,
  currentSubtext: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onLaunchCustom: PropTypes.func,
  highlight: PropTypes.bool,
  id: PropTypes.string,
};

FilterDropdownButton.defaultProps = {
  options: [],
  currentText: 'Select',
  currentSubtext: null,
  onLaunchCustom: () => null,
  highlight: false,
  id: null,
};
