import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';

function FailIcon({ isActive }) {
  return (
    <BaseIcon
      isActive={isActive}
      activeColorClass="tw-stroke-bad-dark"
      pathData="M5.62384898,4.37040441 L29.3301102,27.9457721 M5.62384898,27.9457721 L29.3301102,4.37040441"
      name="fail"
    />
  );
}

FailIcon.propTypes = {
  isActive: PropTypes.bool,
};

FailIcon.defaultProps = {
  isActive: false,
};

export default React.memo(FailIcon);
