import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';

function NaIcon({ isActive }) {
  return (
    <BaseIcon
      isActive={isActive}
      activeColorClass="tw-stroke-neutral-dark"
      pathData="M17.5,28.75 C24.4035594,28.75 30,23.1535594 30,16.25 C30,9.34644063 24.4035594,3.75 17.5,3.75 C10.5964406,3.75 5,9.34644063 5,16.25 C5,23.1535594 10.5964406,28.75 17.5,28.75 Z M10.3125,9.0625 L25.15625,23.671875"
      strokeWidthActive={5.0625}
      name="na"
    />
  );
}

NaIcon.propTypes = {
  isActive: PropTypes.bool,
};

NaIcon.defaultProps = {
  isActive: false,
};

export default React.memo(NaIcon);
