import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export default function useFavoriteMutations(railsFormToken) {
  const queryClient = useQueryClient();

  const invalidateReportCategories = () => {
    // Force reload of categories and their favorites
    queryClient.invalidateQueries({ queryKey: ['report_categories'] });
  };

  const createFavoriteMutation = useMutation({
    mutationFn: (favoritedReportId) => (
      axios.post(
        '/user_favorite_reports.json',
        {
          report_id: favoritedReportId,
          authenticity_token: railsFormToken,
        },
      )
    ),
    onSuccess: invalidateReportCategories,
  });

  const deleteFavoriteMutation = useMutation({
    mutationFn: (favoritedReportId) => (
      axios.delete(
        `/user_favorite_reports/${favoritedReportId}.json`,
        {
          headers: {
            'X-CSRF-Token': railsFormToken,
          },
        },
      )
    ),
    onSuccess: invalidateReportCategories,
  });

  return { createFavoriteMutation, deleteFavoriteMutation };
}
