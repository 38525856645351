import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { InspectionsContext } from './data/InspectionsContextManager';

export default function ValueField({ step, index, valueInputRef }) {
  const [value, setValue] = useState(step.value || '');
  const { updateStep, readOnly } = useContext(InspectionsContext);

  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  const handleValueBlur = () => {
    if (readOnly) return;

    updateStep(step.id, { value });
  };

  return (
    <div className="im-clear tw-flex-col">
      <label
        className="tw-block tw-text-gray-700 tw-text-sm tw-my-2"
        htmlFor="value"
      >
        {step.value_name}
      </label>
      <input
        className="tw-shadow-none tw-rounded tw-mb-1"
        name={`inspection[inspection_steps_attributes][${index}][value]`}
        id={`inspection_inspection_steps_attributes_${index}_value`}
        type="text"
        ref={valueInputRef}
        placeholder={`(${step.value_type_string})`}
        required={step.value_required}
        value={value}
        onChange={handleValueChange}
        onBlur={handleValueBlur}
        disabled={readOnly}
      />
    </div>
  );
}

ValueField.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.object.isRequired,
  valueInputRef: PropTypes.object.isRequired,
};
