import React, {
  createContext,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

const SharedContext = createContext();

function SharedContextProvider({
  ajax,
  children,
  columnArray,
  currency,
  displayLength,
  filters,
  infiniteScroll,
  orderArray,
  railsFormToken,
  serverSide,
}) {
  const [haveError, setHaveError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const parsedFilters = useMemo(() => {
    if (typeof filters === 'string' && filters !== '') {
      try {
        return JSON.parse(filters);
      } catch (error) {
        return {};
      }
    } else if (typeof filters === 'object' && filters !== null) {
      return filters;
    }
    return {};
  }, [filters]);

  const providerValue = useMemo(
    () => ({
      ajax,
      columnArray,
      currency,
      displayLength,
      errorMessage,
      filters: parsedFilters,
      haveError,
      infiniteScroll,
      orderArray,
      railsFormToken,
      serverSide,
      setErrorMessage,
      setHaveError,
    }),
    [
      ajax,
      columnArray,
      currency,
      displayLength,
      errorMessage,
      parsedFilters,
      haveError,
      infiniteScroll,
      orderArray,
      railsFormToken,
      serverSide,
    ],
  );

  return (
    <SharedContext.Provider value={providerValue}>
      {children}
    </SharedContext.Provider>
  );
}

export { SharedContext, SharedContextProvider };

SharedContextProvider.propTypes = {
  ajax: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  columnArray: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  currency: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  displayLength: PropTypes.number,
  filters: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  infiniteScroll: PropTypes.bool,
  orderArray: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    ),
  ),
  railsFormToken: PropTypes.string.isRequired,
  serverSide: PropTypes.bool,
};

SharedContextProvider.defaultProps = {
  columnArray: '',
  displayLength: 25,
  filters: '',
  infiniteScroll: true,
  orderArray: [],
  serverSide: false,
};
