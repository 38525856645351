import React from 'react';
import PropTypes from 'prop-types';

export default function RowsToDisplaySelect({
  displayLength,
  onChange,
}) {
  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
      <div className="tw-flex tw-items-center">
        <span className="tw-du-label-text tw-mr-2">Rows per page:</span>
        <select
          className="tw-du-select tw-du-select-bordered tw-border-solid tw-du-select-xs hover:tw-border-primary"
          name="customization[display_length]"
          id="customization_display_length"
          value={displayLength}
          onChange={onChange}
        >
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    </div>

  );
}

RowsToDisplaySelect.propTypes = {
  displayLength: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
};
