import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';

export default function ConfirmationDialog({
  children,
  isOpen,
  onClose,
  onCancelClick,
  onConfirmClick,
  cancelLabel,
  confirmLabel,
  containerClass,
  dialogClass,
  cancelButtonClass,
  confirmButtonClass,
}) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter="tw-ease-out tw-duration-300"
        enterFrom="tw-opacity-0"
        enterTo="tw-opacity-100"
        leave="tw-ease-in tw-duration-200"
        leaveFrom="tw-opacity-100"
        leaveTo="tw-opacity-0"
      >
        <Dialog
          as="div"
          className={`im-clear tw-fixed tw-z-20 tw-inset-0 tw-flex confirmation-dialog ${containerClass}`}
          onClose={onClose}
        >
          <div className={`tw-p-5 tw-rounded-md tw-w-1/2 tw-max-w-sm ${dialogClass}`}>
            {children}
            <div className="tw-flex tw-justify-center tw-gap-2">
              <button
                type="button"
                className={`im-clear tw-mt-3 active:tw-shadow-inner tw-inline-flex tw-cursor-pointer tw-w-full tw-justify-center tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset sm:tw-mt-0 sm:tw-w-auto sm:tw-mr-3 sm:tw-mb-0 ${cancelButtonClass}`}
                onClick={onCancelClick}
              >
                {cancelLabel}
              </button>
              <button
                type="button"
                className={`im-clear tw-mt-3 active:tw-shadow-inner tw-inline-flex tw-cursor-pointer tw-w-full tw-justify-center tw-rounded-md tw-shadow-sm  sm:tw-w-auto sm:tw-mt-0 ${confirmButtonClass}`}
                onClick={onConfirmClick}
              >
                {confirmLabel}
              </button>
            </div>
          </div>
        </Dialog>
      </Transition.Child>
    </Transition.Root>
  );
}

ConfirmationDialog.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onCancelClick: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  containerClass: PropTypes.string,
  dialogClass: PropTypes.string,
  cancelButtonClass: PropTypes.string,
  confirmButtonClass: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  children: <h2>Are you sure?</h2>,
  onClose: () => null,
  cancelLabel: 'Cancel',
  confirmLabel: 'OK',
  containerClass: 'tw-items-center tw-justify-center',
  dialogClass: 'tw-bg-red-50 tw-border-solid tw-border-2 tw-border-red-400',
  cancelButtonClass: 'tw-bg-white tw-px-4 tw-py-3 tw-text-sm tw-font-semibold tw-text-gray-900 tw-ring-gray-300 hover:tw-bg-gray-50',
  confirmButtonClass: 'tw-bg-red-500 tw-px-4 tw-py-3 tw-text-sm tw-font-semibold tw-text-white hover:tw-bg-red-600',
};
