// Translates the server data request from the format native to AG Grid
// to the format used by Datatables, which is the format the server expects.

export default function agServerRequestAdapter({
  request,
  length,
  columnArray,
  draw,
  enableServerGroupBy,
  groupByColumn,
  search,
  filterModel,
  negativeOffset,
}) {
  const sortField = (data) => {
    if (typeof data === 'object') return data._;

    return data;
  };

  const order = request.sortModel.map((col) => ({
    column: columnArray.findIndex((c) => sortField(c.data) === col.colId),
    dir: col.sort,
  }));

  // When enabled, add the group-by column as the first sort
  if (enableServerGroupBy && groupByColumn) {
    order.unshift({
      column: columnArray.findIndex((c) => sortField(c.data) === groupByColumn.field),
      dir: 'asc',
    });
  }

  const returnVal = {
    draw,
    order,
    start: request.startRow - negativeOffset,
    length,
    search: { value: search, regex: false },
    _: Date.now(),
  };

  if (filterModel) returnVal.filters = filterModel;

  return returnVal;
}
