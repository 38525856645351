import React from 'react';
import PropTypes from 'prop-types';
import CollapsableMenu from '../CollapsableMenu';
import CollapsableMenuItem from '../CollapsableMenuItem';

export default function QuickLaunchSidebar({
  onClick, availableCategories, categoryOpenStatus, onCategoryOpenChange,
}) {
  return (
    <ul>
      {availableCategories.map((category) => (
        <CollapsableMenu
          key={category.id}
          itemId={category.id}
          isOpen={category.id === null || categoryOpenStatus[category.id]}
          onOpenChange={onCategoryOpenChange}
          title={category.display_name}
          className={category.id === null ? 'tw-bg-blue-200' : 'tw-bg-blue-100'}
        >
          {category.reports.map((report) => (
            <CollapsableMenuItem
              key={report.id}
              onClick={() => onClick(report.slug)}
              isOpen={category.id === null}
            >
              {report.display_name}
            </CollapsableMenuItem>
          ))}
        </CollapsableMenu>
      ))}
    </ul>
  );
}

QuickLaunchSidebar.propTypes = {
  onClick: PropTypes.func,
  availableCategories: PropTypes.arrayOf(PropTypes.object),
  categoryOpenStatus: PropTypes.object.isRequired,
  onCategoryOpenChange: PropTypes.func,
};

QuickLaunchSidebar.defaultProps = {
  onClick: () => null,
  availableCategories: [],
  onCategoryOpenChange: () => null,
};
