import React, {
  createContext,
  useContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { SharedContextProvider, SharedContext } from './SharedContextManager';
import { ViewerContextProvider, ViewerContext } from '../../Reports/data/ViewerContextManager';
import { IndexViewerContextProvider, IndexViewerContext } from './IndexViewerContextManager';

const GridContext = createContext();

function GridContextProvider({
  ajax,
  children,
  columnArray,
  columnsFuncName,
  contextType,
  currency,
  customizationName,
  displayLength,
  expectedRecords,
  filters,
  infiniteScroll,
  masterDetail,
  onRecordIdsChanged,
  onRecordsRetrieved,
  orderArray,
  railsFormToken,
  reportId,
  serverSide,
  tableId,
  title,
}) {
  return (
    <SharedContextProvider
      ajax={ajax}
      columnArray={columnArray}
      currency={currency}
      displayLength={displayLength}
      filters={filters}
      infiniteScroll={infiniteScroll}
      orderArray={orderArray}
      railsFormToken={railsFormToken}
      serverSide={serverSide}
    >
      {contextType === 'viewer' && (
        <ViewerContextProvider
          reportId={reportId}
          title={title}
          onRecordsRetrieved={onRecordsRetrieved}
          onRecordIdsChanged={onRecordIdsChanged}
        >
          <GridContextWrapper>
            {children}
          </GridContextWrapper>
        </ViewerContextProvider>
      )}
      {contextType === 'indexViewer' && (
        <IndexViewerContextProvider
          tableId={tableId}
          columnsFuncName={columnsFuncName}
          customizationName={customizationName}
          expectedRecords={expectedRecords}
          masterDetail={masterDetail}
        >
          <GridContextWrapper>
            {children}
          </GridContextWrapper>
        </IndexViewerContextProvider>
      )}
    </SharedContextProvider>
  );
}

function GridContextWrapper({ children }) {
  const sharedContext = useContext(SharedContext);
  const viewerContext = useContext(ViewerContext);
  const indexViewerContext = useContext(IndexViewerContext);

  const providerValue = useMemo(() => ({
    ...sharedContext,
    ...(viewerContext || {}),
    ...(indexViewerContext || {}),
  }), [
    sharedContext,
    viewerContext,
    indexViewerContext,
  ]);

  return (
    <GridContext.Provider value={providerValue}>
      {children}
    </GridContext.Provider>
  );
}

GridContextWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

GridContextProvider.propTypes = {
  ajax: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  columnArray: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  columnsFuncName: PropTypes.string,
  contextType: PropTypes.oneOf(['viewer', 'indexViewer']).isRequired,
  currency: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  customizationName: PropTypes.string,
  displayLength: PropTypes.number,
  expectedRecords: PropTypes.number,
  filters: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  infiniteScroll: PropTypes.bool,
  masterDetail: PropTypes.bool,
  onRecordIdsChanged: PropTypes.func,
  onRecordsRetrieved: PropTypes.func,
  orderArray: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    ),
  ),
  railsFormToken: PropTypes.string.isRequired,
  reportId: PropTypes.string,
  serverSide: PropTypes.bool,
  tableId: PropTypes.string,
  title: PropTypes.string,
};

GridContextProvider.defaultProps = {
  columnArray: '',
  columnsFuncName: '',
  customizationName: '',
  displayLength: 25,
  expectedRecords: 0,
  filters: '',
  infiniteScroll: true,
  masterDetail: false,
  onRecordsRetrieved: () => {},
  onRecordIdsChanged: () => {},
  orderArray: [],
  reportId: '',
  serverSide: false,
  tableId: '',
  title: '',
};

export { GridContext, GridContextProvider };
