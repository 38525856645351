import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { SharedContext } from './SharedContextManager';
import useAgColumnVisibility from '../hooks/useAgColumnVisibility';
import agColumnDefAdapter from './agColumnDefAdapter';
import useRailsEvent from '../../hooks/useRailsEvent';
import useSavedColumnPositions from '../hooks/useSavedColumnPositions';

const IndexViewerContext = createContext();

function IndexViewerContextProvider({
  children,
  columnsFuncName,
  customizationName,
  expectedRecords,
  masterDetail,
  tableId,
}) {
  const rowBuffer = 10;
  const sharedContext = useContext(SharedContext);

  const {
    columnArray,
    filters,
    infiniteScroll,
    orderArray,
    railsFormToken,
    serverSide,
  } = sharedContext;

  const gridApiRef = useRef();
  const orderRef = useRef();
  const recordIdsRef = useRef([]);
  const colFiltersRef = useRef({});
  const [selectedRecordIds, setSelectedRecordIds] = useState([]);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [quickFilterText, setQuickFilterText] = useState('');

  // Effect that sets quick filter text to remembered search if present
  useEffect(() => {
    const search = localStorage.getItem(`${tableId}-search`);

    if (search && localStorage[`${tableId}-remember_search`] === 'true') {
      setQuickFilterText(search);
    }
  }, [tableId]);

  // Convert the legacy column array to the columnDefs format AG Grid expects
  const memoizedAgColumnDefAdapter = useCallback(() => {
    let columns;
    if (columnArray) {
      columns = JSON.parse(columnArray);
    } else if (columnsFuncName) {
      // eslint-disable-next-line no-undef
      columns = Utility.Datatables[columnsFuncName]();
    } else {
      columns = [];
    }
    const savedOrder = JSON.parse(localStorage.getItem(`TableOrder_${tableId}`));
    return agColumnDefAdapter(columns, savedOrder || orderArray, filters, serverSide);
  }, [columnArray, columnsFuncName, filters, orderArray, serverSide, tableId]);

  // eslint-disable-next-line no-unused-vars
  const [columnDefs, _setColumnDefs] = useState(memoizedAgColumnDefAdapter);
  const setColumnVisibility = useAgColumnVisibility(columnDefs, gridApiRef);

  // Define basic config for all columns - columnDefs overrides these
  const defaultColDef = useMemo(() => (
    {
      autoHeaderHeight: true,
      autoHeight: true,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
      },
      filter: false,
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      },
      flex: 1,
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
      minWidth: 120,
      wrapHeaderText: true,
      wrapText: true,
    }
  ), []);

  const gridStyle = useMemo(() => ({
    height: infiniteScroll ? '100%' : undefined,
    width: '100%',
    marginBottom: '20px',
  }), [infiniteScroll]);

  const handleColumnMoved = useSavedColumnPositions(columnDefs, 'datatable_customizations', customizationName, railsFormToken);

  // Send search to the quickFilterText feature of AG Grid
  const handleRailsSearchEvent = (eventData) => {
    setQuickFilterText(eventData.searchText);
  };

  // Consume search events from the Rails side
  useRailsEvent(handleRailsSearchEvent, 'AgGridSearch');

  // Compile the arguments the Rails-side download form needs from the grid
  const handleRequestDownloadInfo = (eventData) => {
    const detail = {
      form: eventData.form,
    };

    if (serverSide) {
      detail.order = orderRef.current;
      detail.search = quickFilterText;
      detail.filters = JSON.stringify(colFiltersRef.current);
      detail.draw = 1;
    } else {
      detail.selectedIds = recordIdsRef.current;
      detail.draw = 0;
    }

    // Send them to the Rails side via a custom event
    const event = new CustomEvent('AgGridDownloadInfoReady', { detail });
    document.dispatchEvent(event);
  };

  // Consume request download info events from the Rails side
  useRailsEvent(handleRequestDownloadInfo, 'AgGridRequestDownloadInfo');

  // After scrolling, store the current index position so we can return to it later
  const handleBodyScroll = useCallback((params) => {
    const debouncedScrollHandler = debounce(() => {
      const topRowIndex = params.api.getFirstDisplayedRowIndex();
      const adjustedIndex = topRowIndex > 0 ? topRowIndex + rowBuffer : 0;
      localStorage.setItem(`TableIndex_${tableId}`, JSON.stringify(adjustedIndex));
      setShowScrollButton(topRowIndex > 0);
    }, 500);

    debouncedScrollHandler();
  }, [tableId]);

  // Set the gridApiRef when the grid is ready
  const handleGridReady = useCallback((params) => {
    gridApiRef.current = params.api;
    setColumnVisibility();
  }, [setColumnVisibility]);

  // Callback to track record id changes for use in printing/exporting
  const handleRecordIdsChanged = useCallback((newIds) => {
    // Use ref to persist the record ids across renders and avoid re-renders
    recordIdsRef.current = newIds;
  }, []);

  // Callback to track record retrieval params for use in printing/exporting
  const handleRecordsRetrieved = useCallback((params) => {
    // Use ref to persist the values across renders and avoid re-renders
    if (params.order) orderRef.current = params.order.map((obj) => `${obj.column},${obj.dir}`).join(',');
    if (params.filters) colFiltersRef.current = params.filters;
  }, []);

  // Perform action when table row clicked
  const handleRowClicked = useCallback((e) => {
    // Get the clicked element
    const clickedElement = e.event.target;

    // Ignore clicks from within the selection checkbox column
    if (clickedElement.querySelector('.ag-selection-checkbox')
      || clickedElement.classList.contains('ag-selection-checkbox')
      || clickedElement.classList.contains('im-expand-master-detail')
      || clickedElement.classList.contains('ag-checkbox-input-wrapper')
    ) {
      return;
    }

    // Ignore clicks on dropdowns
    if (e.event.target.closest('.ag-actions-trigger')) {
      return;
    }


    if ($(e.event.target).hasClass('inner-link') || ($(e.event.target).attr('data-method') === 'delete')) {
      return;
    }

    const rowUrl = e.data?.DT_RowData?.url;
    const rowRemote = e.data?.DT_RowData?.remote || false;
    const rowFlyIn = e.data?.DT_RowData['show-fly-in'] || false;

    // Navigate to the URL based on the remote boolean
    if (rowRemote) {
      // eslint-disable-next-line no-undef
      if (rowFlyIn) Utility.FlyInPanel.show(rowUrl);
      $.get(rowUrl, null, null, 'script');
    } else {
      window.location = rowUrl;
    }
  }, []);

  // Scroll to top and clear the stored index position
  const handleScrollToTop = useCallback(() => {
    gridApiRef.current.ensureIndexVisible(0, 'top');
    localStorage.removeItem(`TableIndex_${tableId}`);
    setShowScrollButton(false);
  }, [tableId]);

  // Show the multi-select header when the selection changes
  const handleSelectionChanged = useCallback(() => {
    let selectedIds;

    // Choose the proper approach based on server- or client-side mode
    if (serverSide) {
      const selectionState = gridApiRef.current.getServerSideSelectionState();
      selectedIds = [];

      // We can only track individual ids in server-side mode without select all
      if (!selectionState.selectAll) {
        selectedIds = selectionState.toggledNodes;
      }
    } else {
      // Client-side mode
      const selectedNodes = gridApiRef.current.getSelectedNodes();
      selectedIds = selectedNodes.map((node) => node?.data?.id);
    }

    // Style the header so we can seamlessly overlay the MultiselectHeader component
    const headerElement = document.querySelector('.ag-header-viewport');
    if (selectedIds.length > 0) {
      headerElement.classList.add('rows-selected');
    } else {
      headerElement.classList.remove('rows-selected');
    }

    setSelectedRecordIds(selectedIds);
    handleRecordIdsChanged(selectedIds);
  }, [handleRecordIdsChanged, serverSide]);

  // Persist sort order to local storage when changed and track record ID order
  const handleSortChanged = useCallback((e) => {
    // Persist sort
    const selectedOrder = e.api.getColumnState().filter((s) => s.sort !== null);
    const newOrderArray = selectedOrder.sort((a, b) => a.sortIndex - b.sortIndex).map((c) => [c.colId, c.sort]);

    localStorage.setItem(`TableOrder_${tableId}`, JSON.stringify(newOrderArray));

    // Track record ID order
    const orderedIds = [];
    e.api.forEachNodeAfterFilterAndSort((node) => {
      if (!node.group && node.data?.id) orderedIds.push(node.data.id);
    });
    handleRecordIdsChanged(orderedIds);
  }, [handleRecordIdsChanged, tableId]);

  // Row is selectable if the checkbox_control value is populated
  const isRowSelectable = useCallback((rowNode) => rowNode?.data?.checkbox_control !== null, []);

  const providerValue = useMemo(
    () => ({
      ...sharedContext,
      columnDefs,
      defaultColDef,
      expectedRecords,
      gridApiRef,
      gridStyle,
      handleBodyScroll,
      handleColumnMoved,
      handleGridReady,
      handleRecordIdsChanged,
      handleRecordsRetrieved,
      handleRowClicked,
      handleSelectionChanged,
      handleScrollToTop,
      handleSortChanged,
      isRowSelectable,
      masterDetail,
      quickFilterText,
      rowBuffer,
      selectedRecordIds,
      setShowScrollButton,
      setQuickFilterText,
      showScrollButton,
      tableId,
    }),
    [
      sharedContext,
      columnDefs,
      defaultColDef,
      expectedRecords,
      gridStyle,
      handleBodyScroll,
      handleColumnMoved,
      handleGridReady,
      handleRecordIdsChanged,
      handleRecordsRetrieved,
      handleRowClicked,
      handleScrollToTop,
      handleSelectionChanged,
      handleSortChanged,
      isRowSelectable,
      masterDetail,
      quickFilterText,
      selectedRecordIds,
      showScrollButton,
      tableId,
    ],
  );

  return (
    <IndexViewerContext.Provider value={providerValue}>
      {children}
    </IndexViewerContext.Provider>
  );
}

export { IndexViewerContext, IndexViewerContextProvider };

IndexViewerContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  columnsFuncName: PropTypes.string,
  customizationName: PropTypes.string,
  expectedRecords: PropTypes.number.isRequired,
  masterDetail: PropTypes.bool,
  tableId: PropTypes.string.isRequired,
};

IndexViewerContextProvider.defaultProps = {
  columnsFuncName: '',
  customizationName: '',
  masterDetail: false,
};
