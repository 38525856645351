import React from 'react';
import PropTypes from 'prop-types';
import CollapsablePanel from '../CollapsablePanel';
import CollapsablePanelItem from '../CollapsablePanelItem';

export default function Organizer({
  onClick,
  availableCategories,
  searchActive,
  categoryOpenStatus,
  onCategoryOpenChange,
  onToggleFavorite,
}) {
  const suppressCategoryBorder = (index, category) => {
    // Don't display the report dividing border when the report is the last one in a column
    const lastColItemIndex = Math.round(category.reports.length / 2);
    return index === (lastColItemIndex - 1);
  };

  const handleToggleFavorite = (report) => {
    onToggleFavorite(report.id, report.favorited);
  };

  return (
    <div className="tw-mt-4">
      {availableCategories.map((category) => (
        (category.reports.length) > 0 && (
          <CollapsablePanel
            key={category.id}
            title={category.display_name}
            startOpen={category.id === null || categoryOpenStatus[category.id]}
            forceOpen={searchActive}
            onOpenChange={onCategoryOpenChange}
            itemId={category.id}
          >
            {category.reports.map((report, index) => (
              <CollapsablePanelItem
                key={report.id}
                onClick={() => onClick(report.slug)}
                isFavorited={report.favorited}
                onToggleFavorite={() => handleToggleFavorite(report)}
                suppressBorder={suppressCategoryBorder(index, category)}
              >
                <span className="tw-mr-2">{report.display_name}</span>
              </CollapsablePanelItem>
            ))}
          </CollapsablePanel>
        )
      ))}
    </div>
  );
}

Organizer.propTypes = {
  onClick: PropTypes.func.isRequired,
  availableCategories: PropTypes.arrayOf(PropTypes.object),
  searchActive: PropTypes.bool,
  categoryOpenStatus: PropTypes.object.isRequired,
  onCategoryOpenChange: PropTypes.func,
  onToggleFavorite: PropTypes.func.isRequired,
};

Organizer.defaultProps = {
  availableCategories: [],
  searchActive: false,
  onCategoryOpenChange: () => null,
};
