import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import Tippy from '@tippyjs/react';

function ActionsMenuRenderer({ data }) {
  const tippyRef = useRef();
  const dropdownContentRef = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const [dropdownContent, setDropdownContent] = useState(null);
  const [renderDropdown, setRenderDropdown] = useState(false);

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data.actions, 'text/html');

    // Extract the data-dropdown span
    const dropdownSpan = doc.querySelector('span[data-dropdown]');

    if (dropdownSpan) {
      // If it exists, we will render a dropdown menu using Tippy:
      // Need to remove the span from the document body
      dropdownSpan.remove();
    } else {
      // If it doesn't exist, we will render the actions as is
      setRenderDropdown(false);
      return;
    }

    // Replace the 'dropdown' class with 'ag-dropdown' in the div
    const dropdownDiv = doc.querySelector('div.dropdown');
    if (dropdownDiv) {
      dropdownDiv.classList.add('im-clear');
      dropdownDiv.classList.replace('dropdown', 'ag-dropdown');
      dropdownDiv.classList.remove('dropdown-relative');
      dropdownDiv.classList.remove('dropdown-tip');
    }

    // Replace the 'dropdown-menu' class with 'ag-dropdown-menu' in the UL
    const dropdownMenuUl = doc.querySelector('ul.dropdown-menu');
    if (dropdownMenuUl) {
      dropdownMenuUl.classList.replace('dropdown-menu', 'ag-dropdown-menu');
    }

    // Select all a elements with data-remote="true" and add data-no-react-unmount="true"
    const remoteLinks = doc.querySelectorAll('a[data-remote="true"]');
    remoteLinks.forEach((link) => {
      link.setAttribute('data-no-react-unmount', 'true');
    });

    // Indicate that we will render a dropdown menu
    setRenderDropdown(true);
    setDropdownContent(doc.body.innerHTML);
  }, [data.actions]);

  // Effect to hide the drop-down menu upon clicking an item within it
  useEffect(() => {
    const handleClick = (event) => {
      if (dropdownContentRef.current && dropdownContentRef.current.contains(event.target)) {
        hide();
      }
    };

    document.addEventListener('click', handleClick);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [hide]);

  return (
    <>
      {renderDropdown && (
        <Tippy
          ref={tippyRef}
          content={
            (
              <div
                ref={dropdownContentRef}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dropdownContent) }}
              />
            )
          }
          visible={visible}
          onClickOutside={hide}
          allowHTML
          arrow
          appendTo={document.body}
          interactive
          placement="bottom"
          theme="general"
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['top', 'right', 'left'],
                },
              },
              {
                name: 'preventOverflow',
                options: {
                  boundary: 'viewport',
                },
              },
            ],
          }}
        >
          <span
            className="ag-actions-trigger"
            onClick={visible ? hide : show}
          >
            <i className="far fa-cog" />
            <i className="fas fa-caret-down" />
          </span>
        </Tippy>
      )}
      {!renderDropdown && (
        <span
          className="ag-actions-trigger"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.actions) }}
        />
      )}
    </>
  );
}

ActionsMenuRenderer.propTypes = {
  data: PropTypes.shape({
    actions: PropTypes.string.isRequired,
  }).isRequired,
};

export default React.memo(ActionsMenuRenderer);
