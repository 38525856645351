import React from 'react';
import PropTypes from 'prop-types';

export default function Toggle({
  id,
  labelText,
  value,
  onChange,
  disabled,
  containerClass,
  formControlClass,
  labelClass,
  inputClass,
  toggleBeforeLabel,
}) {
  const labelElement = (
    <div className={`tw-du-form-control ${formControlClass}`}>
      <label htmlFor={id} className="tw-cursor-pointer">
        <div className={labelClass}>{labelText}</div>
      </label>
    </div>
  );

  const toggleElement = (
    <input
      id={id}
      type="checkbox"
      className={`tw-du-toggle ${inputClass}`}
      onChange={onChange}
      checked={value}
      disabled={disabled}
    />
  );

  return (
    <div className={`tw-flex ${toggleBeforeLabel ? 'tw-space-x-1.5' : 'tw-space-x-4'} tw-items-center ${containerClass}`}>
      {toggleBeforeLabel ? (
        <>
          {toggleElement}
          {labelElement}
        </>
      ) : (
        <>
          {labelElement}
          {toggleElement}
        </>
      )}
    </div>
  );
}

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  containerClass: PropTypes.string,
  formControlClass: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  toggleBeforeLabel: PropTypes.bool,
};

Toggle.defaultProps = {
  labelText: '',
  value: false,
  onChange: () => null,
  disabled: false,
  containerClass: '',
  formControlClass: '',
  labelClass: '',
  inputClass: '',
  toggleBeforeLabel: false,
};
