import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { InspectionsContext } from './data/InspectionsContextManager';
import SlideDownTransition from '../SlideDownTransition';
import PictureDisplay from './PictureDisplay';
import PictureButtons from './PictureButtons';
import NotesDialog from '../NotesDialog';

export default function AdditionalInfo({
  show,
  deviceHasCamera,
  index,
  step,
}) {
  const [comments, setComments] = useState(step.comments || '');
  const [score, setScore] = useState(() => {
    if (step.score) {
      return step.score
    } else {
      return ''
    }
  });
  const [total_score, setTotalScore] = useState(() => {
    if (step.total_score) {
      return step.total_score
    } else {
      return ''
    }
  });
  const { updateStep, statusName, readOnly } = useContext(InspectionsContext);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const weight_factor = step.weight_factor || ''

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  const handleCommentsBlur = () => {
    if (readOnly) return;

    updateStep(step.id, { comments });
  };

  const handleScoreChange = (event) => {
    var rounded_score = 0
    if (parseFloat(event.target.value) >= 0) {
      rounded_score = Math.round(parseFloat(event.target.value) * 100) / 100
      setScore(rounded_score);
    } else {
      setScore('');
    }

    if ((rounded_score > 0) && (weight_factor > 0)) {
      setTotalScore(Math.round((rounded_score * weight_factor) * 100) / 100);
    } else {
      setTotalScore(0)
    }
  };

  const handleScoreBlur = () => {
    if (readOnly) return;

    updateStep(step.id, { score, total_score });
  };

  const pictureIsRequired = () => (
    statusName(step.pass) === 'fail' || statusName(step.pass) === 'pass' || step.scored_step
  );

  return (
    <SlideDownTransition show={show}>
      <>
        {step.has_picture && (
          <PictureDisplay step={step} />
        )}

        <div className="im-clear tw-flex tw-pt-4 tw-mb-1">
          {step.scored_step && (
            <div className='tw-flex tw-items-end'>
              <p>
                { step.value_name || 'Score' }
                <input
                  className={`tw-shadow-none tw-rounded`}
                  name={`inspection[inspection_steps_attributes][${index}][score]`}
                  id={`inspection_inspection_steps_attributes_${index}_score`}
                  placeholder="Enter numeric score"
                  value={score}
                  onChange={handleScoreChange}
                  onBlur={handleScoreBlur}
                  disabled={readOnly}
                />
              </p>
              <p className='tw-ml-5'>
                Weight Factor
                <input
                  className={`tw-shadow-none tw-rounded`}
                  name={`inspection[inspection_steps_attributes][${index}][weight_factor]`}
                  id={`inspection_inspection_steps_attributes_${index}_weight_factor`}
                  value={weight_factor}
                  disabled={true}
                />
              </p>
              <p className='tw-ml-5'>
                Total Score
                <input
                  className={`tw-shadow-none tw-rounded`}
                  name={`inspection[inspection_steps_attributes][${index}][total_score]`}
                  id={`inspection_inspection_steps_attributes_${index}_total_score`}
                  value={total_score}
                  disabled={true}
                />
              </p>
            </div>
          )}
        </div>

        <div className="im-clear tw-flex tw-pt-4 tw-mb-1">
          {!step.has_picture && !readOnly && (
            <PictureButtons step={step} deviceHasCamera={deviceHasCamera} />
          )}
          {readOnly && (
            <p className="tw-bg-transparent tw-w-full tw-break-words">
              {comments}
            </p>
          )}

          {!readOnly && (
            <input
              className={`tw-shadow-none tw-rounded ${(step.has_picture || readOnly) ? '' : 'tw-ml-2'}`}
              name={`inspection[inspection_steps_attributes][${index}][comments]`}
              id={`inspection_inspection_steps_attributes_${index}_comments`}
              placeholder="Remarks"
              value={comments}
              onChange={handleCommentsChange}
              onBlur={handleCommentsBlur}
              disabled={readOnly}
            />
          )}
        </div>

        {step.notes !== null && step.notes !== '' && (
          <div>
            <button
              className="im-clear tw-bg-transparent tw-pt-2 im-cp-clickable tw-border-none tw-text-primary hover:tw-text-blue-400"
              type="button"
              onClick={() => setShowNotesModal(true)}
            >
              View Notes
            </button>
          </div>
        )}
        {step.notes !== null && step.notes !== '' && (
          <NotesDialog
            isOpen={showNotesModal}
            onClose={() => setShowNotesModal(false)}
            notes={step.notes}
          >
          </NotesDialog>
        )}
        {step.require_photo && !step.has_picture && !readOnly && (
          <input
            type="text"
            id={`required-photo-${step.id}`}
            className="required-photo"
            style={{ display: 'none' }}
            required={pictureIsRequired()}
            data-parsley-required-message="A picture is required."
          />
        )}
      </>
    </SlideDownTransition>
  );
}

AdditionalInfo.propTypes = {
  show: PropTypes.bool,
  deviceHasCamera: PropTypes.bool,
  index: PropTypes.number.isRequired,
  step: PropTypes.object.isRequired,
};

AdditionalInfo.defaultProps = {
  show: false,
  deviceHasCamera: false,
};
