import React from 'react';
import PropTypes from 'prop-types';
import { Notifier } from '@airbrake/browser';
import ErrorMessage from './ErrorMessage';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    const { config } = this.props;
    this.state = {
      hasError: false,
      errorDetails: '',
    };
    const [projectId, projectKey, environment] = atob(config).split(':');

    if (environment !== 'test' && environment !== 'development') {
      this.airbrake = new Notifier({
        projectId,
        projectKey,
        environment,
        instrumentation: {
          onerror: false,
        },
      });
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true, errorDetails: error.message });
    const { errorContext } = this.props;

    // Send error to Airbrake
    if (this.airbrake) {
      this.airbrake.notify({
        error: `Intercepted by ${errorContext} React Error Boundary: ${error}`,
        params: { info },
      });
    }
  }

  render() {
    const { hasError, errorDetails } = this.state;
    const { children } = this.props;

    if (hasError) return <ErrorMessage details={errorDetails} />;

    return children;
  }
}

ErrorBoundary.propTypes = {
  config: PropTypes.string.isRequired,
  errorContext: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
