import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { InspectionsContext } from './data/InspectionsContextManager';
import ActionButton from './ActionButton';

function ActionButtonGroup({
  stepId,
  stepStatus,
  stepValue,
  index,
  onClick,
}) {
  const { updateStep, allowNA, readOnly } = useContext(InspectionsContext);

  const handleActionClick = (value) => {
    if (readOnly) return;

    updateStep(stepId, { pass: value });
    onClick(value);
  };

  return (
    <div
      className="tw-flex tw-flex-wrap tw-grow -tw-ml-0.5 -tw-mr-1 sm:-tw-ml-1 sm:-tw-mr-2"
      style={{ maxWidth: '540px' }}
    >
      <ActionButton
        onClick={() => handleActionClick(1)}
        isActive={stepStatus === 'pass'}
        label="Pass"
        baseClass="pass-step"
        activeBackClass="tw-bg-good-medium"
        hoverBackClass="hover:tw-bg-good-light"
        activeBorderClass="tw-ring-good-dark"
        hoverBorderClass="hover:tw-ring-good-dark"
        activeTextClass="tw-text-good-dark"
        disabled={readOnly}
      />
      <ActionButton
        onClick={() => handleActionClick(0)}
        isActive={stepStatus === 'fail'}
        label="Fail"
        baseClass="fail-step"
        activeBackClass="tw-bg-bad-medium"
        hoverBackClass="hover:tw-bg-bad-light"
        activeBorderClass="tw-ring-bad-dark"
        hoverBorderClass="hover:tw-ring-bad-dark"
        activeTextClass="tw-text-bad-dark"
        disabled={readOnly}
      />
      {allowNA && (
        <ActionButton
          onClick={() => handleActionClick(-1)}
          isActive={stepStatus === 'na'}
          label="N/A"
          baseClass="na-step"
          activeBackClass="tw-bg-neutral-medium"
          hoverBackClass="hover:tw-bg-neutral-light"
          activeBorderClass="tw-ring-neutral-dark"
          hoverBorderClass="hover:tw-ring-neutral-dark"
          activeTextClass="tw-text-neutral-dark"
          disabled={readOnly}
        />
      )}
      {stepValue !== null && (
        <input
          type="hidden"
          value={stepValue}
          name={`inspection[inspection_steps_attributes][${index}][pass]`}
          className="inspection-step-result"
        />
      )}
    </div>
  );
}

ActionButtonGroup.propTypes = {
  stepId: PropTypes.number.isRequired,
  stepStatus: PropTypes.string.isRequired,
  stepValue: PropTypes.number,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

ActionButtonGroup.defaultProps = {
  stepValue: null,
};

export default ActionButtonGroup;
