import React from 'react';
import PropTypes from 'prop-types';
import Toggle from '../Toggle';
import RepairWorkOrderStatus from './RepairWorkOrderStatus';

function RepairRequest({
  value,
  priority,
  onValueChange,
  onPriorityChange,
  step,
  index,
  disabled,
}) {
  return (
    <div
      className="tw-flex tw-flex-col tw-grow tw-justify-center tw-min-w-full sm:tw-min-w-0 tw-my-3 tw-ml-0 sm:tw-ml-4"
      style={{ maxWidth: '210px' }}
    >
      <RepairWorkOrderStatus step={step} />
      {!step.has_repair_work_order && (
        <>
          <div className="tw-flex tw-justify-start tw-items-center tw-w-full">
            <Toggle
              id={`create_repair_request_toggle_${index}`}
              value={value}
              onChange={onValueChange}
              labelText="Create repair request"
              formControlClass="tw-w-40"
              labelClass="tw-du-label-text tw-mr-2 tw-w-full tw-text-left"
              inputClass="tw-du-toggle-md tw-du-toggle-primary"
              toggleBeforeLabel
              disabled={disabled}
            />
            <input type="hidden" value={value} name={`inspection[inspection_steps_attributes][${index}][create_repair_request]`} />
          </div>
          <div className={`tw-flex tw-justify-start tw-w-full tw-transition-all tw-duration-500 tw-ease-in-out ${value ? 'tw-opacity-100 tw-max-h-56' : 'tw-opacity-0 tw-max-h-0'}`}>
            {value && (
              <select
                className="tw-du-select tw-du-select-bordered tw-border-solid tw-rounded tw-du-select-sm tw-mt-2 tw-w-full hover:tw-border-primary"
                name={`inspection[inspection_steps_attributes][${index}][repair_priority]`}
                id={`inspection_inspection_steps_attributes_${index}_repair_priority`}
                value={priority}
                onChange={onPriorityChange}
                disabled={disabled}
              >
                <option value="LOW">LOW</option>
                <option value="NORMAL">NORMAL</option>
                <option value="HIGH">HIGH</option>
                <option value="URGENT">URGENT</option>
              </select>
            )}
          </div>
        </>
      )}
    </div>
  );
}

RepairRequest.propTypes = {
  value: PropTypes.bool.isRequired,
  priority: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onPriorityChange: PropTypes.func.isRequired,
  step: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

RepairRequest.defaultProps = {
  disabled: false,
};

export default React.memo(RepairRequest);
