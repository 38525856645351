// Translates the column definition array expected by Datatables to
// a column definition array compatible with AG Grid.
import DOMPurify from 'dompurify';

export default function agColumnDefAdapter(columnsArray, orderArray = null, filters = {}, serverSide = false) {
  const filterOnInt = (params) => {
    // Parse integer-containing strings as integers for filtering
    try {
      const filterVal = params.data[params.colDef.field];
      if (filterVal) {
        // Remove any HTML
        const sanitized = DOMPurify.sanitize(filterVal, { ALLOWED_TAGS: [] });
        return parseInt(sanitized.replace(/[^\d.-]/g, ''), 10);
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const sortByInt = (valueA, valueB, _nodeA, _nodeB, _isDescending) => {
    // Custom comparator for integer-containing string sorting
    const sanitizedValueA = DOMPurify.sanitize(valueA, { ALLOWED_TAGS: [] });
    const sanitizedValueB = DOMPurify.sanitize(valueB, { ALLOWED_TAGS: [] });
    const intValueA = parseInt(sanitizedValueA?.replace(/[^\d.-]/g, ''), 10);
    const intValueB = parseInt(sanitizedValueB?.replace(/[^\d.-]/g, ''), 10);

    if (intValueA === null || Number.isNaN(intValueA)) {
      if (intValueB === null || Number.isNaN(intValueB)) return 0;
      return 1;
    }

    if (intValueB === null || Number.isNaN(intValueB)) return -1;

    if (intValueA === intValueB) return 0;
    return (intValueA > intValueB) ? 1 : -1;
  };

  const filterOnFloat = (params) => {
    // Parse float-containing strings as floats for filtering
    try {
      const filterVal = params.data[params.colDef.field];
      if (filterVal) {
        // Remove any HTML
        const sanitized = DOMPurify.sanitize(filterVal, { ALLOWED_TAGS: [] });
        return parseFloat(sanitized.replace(/[^\d.-]/g, ''));
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const sortByFloat = (valueA, valueB, _nodeA, _nodeB, _isDescending) => {
    // Custom comparator for integer-containing string sorting
    const sanitizedValueA = DOMPurify.sanitize(valueA, { ALLOWED_TAGS: [] });
    const sanitizedValueB = DOMPurify.sanitize(valueB, { ALLOWED_TAGS: [] });
    const floatValueA = parseFloat(sanitizedValueA?.replace(/[^\d.-]/g, ''));
    const floatValueB = parseFloat(sanitizedValueB?.replace(/[^\d.-]/g, ''));

    if (floatValueA === null || Number.isNaN(floatValueA)) {
      if (floatValueB === null || Number.isNaN(floatValueB)) return 0;
      return 1;
    }

    if (floatValueB === null || Number.isNaN(floatValueB)) return -1;

    if (floatValueA === floatValueB) return 0;
    return (floatValueA > floatValueB) ? 1 : -1;
  };

  const sortByString = (valueA, valueB, _nodeA, _nodeB, _isDescending) => {
    if (valueA === null || valueA === '') {
      if (valueB === null || valueB === '') return 0;
      return 1;
    }

    if (valueB === null || valueB === '') return -1;

    if (valueA === valueB) return 0;
    return (valueA > valueB) ? 1 : -1;
  };

  // eslint-disable-next-line
  const sortByField = (sortField) => {
    // Custom comparator for sorting by a field other than display field
    // eslint-disable-next-line no-unused-vars
    return (valueA, valueB, nodeA, nodeB, _isDescending) => {
      const sortValueA = nodeA.data?.[sortField] || '';
      const sortValueB = nodeB.data?.[sortField] || '';
      if (sortValueA === sortValueB) return 0;
      return (sortValueA > sortValueB) ? 1 : -1;
    };
  };

  const dateComparator = (filterLocalDateAtMidnight, cellValue) => {
    const cellDate = new Date(cellValue);
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  };

  const includeIfDefined = (oldValue, newKey, newValue = null) => (
    typeof oldValue !== 'undefined' ? { [newKey]: newValue || oldValue } : {}
  );

  const translateDataIfDefined = (data) => {
    if (typeof data === 'undefined' || data === '') return {};

    // Direct mapping of data attribute to AG Grid's field attribute
    if (typeof data === 'string') {
      return {
        field: data,
        comparator: sortByString,
      };
    }

    // Special handling in the data of the data attribute being an object
    if (typeof data === 'object' && data !== null) {
      if (data.sort) {
        // Include a custom comparator if sort is specified in the object
        // Default attribute maps to field
        return {
          field: data._,
          comparator: sortByField(data.sort),
        };
      }

      // Default attribute maps to field
      return {
        field: data._,
      };
    }

    return undefined;
  };

  const translateSearchableIfDefined = (searchable) => {
    if (typeof searchable === 'undefined') return {};

    // To disable search in AG Grid, use a function that returns an empty string
    // (Otherwise, searching is enabled by default.)
    if (!searchable) {
      return { getQuickFilterText: () => '' };
    }

    return {};
  };

  const translateClassNameIfDefined = (className) => {
    if (typeof className === 'undefined' || className === '') return {};

    // Enable row grouping
    if (className === 'group-by' && !serverSide) {
      return { rowGroup: true, enableRowGroup: true };
    }

    // Pass through right alignment classes
    if (className.includes('text-right')) {
      return {
        headerClass: 'ag-right-aligned-header',
        cellClass: className,
      };
    }

    return { cellClass: className };
  };

  const addFilterIfNeeded = (data) => {
    // Translate the filter config into AG Grid's filter attributes
    if (!filters) return { filter: false };
    const field = translateDataIfDefined(data)?.field;
    if (!field) return { filter: false };

    const filterConfig = filters[field];
    if (!filterConfig) return { filter: false };

    const dataType = filterConfig.type;
    if (!dataType) return { filter: false };

    if (dataType === 'boolean') {
      return {
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Yes', 'No'],
          suppressMiniFilter: true,
          suppressSelectAll: true,
        },
      };
    }

    if (filterConfig.array) {
      return {
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
        },
      };
    }

    if (filterConfig.custom_field) {
      return {
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['equals', 'notEqual', 'contains', 'notContains'],
        },
      };
    }

    if (dataType === 'date' || dataType === 'datetime') {
      const filter = {
        filter: 'agDateColumnFilter',
        filterParams: {
          filterOptions: ['lessThan', 'greaterThan'],
          comparator: dateComparator,
        },
      };

      // If there's a sort attribute in data object, use it as the filterValueGetter
      if (typeof data === 'object' && data !== null && data.sort) {
        filter.filterValueGetter = (params) => {
          return params.data[data.sort];
        };
      }

      return filter;
    }

    if (dataType === 'integer') {
      return {
        filter: 'agNumberColumnFilter',
        filterValueGetter: filterOnInt,
        comparator: sortByInt,
        filterParams: {
          filterOptions: ['lessThan', 'lessThanOrEqual', 'equals', 'greaterThan', 'greaterThanOrEqual'],
        },
      };
    }

    if (dataType === 'float' || dataType === 'decimal') {
      return {
        filter: 'agNumberColumnFilter',
        filterValueGetter: filterOnFloat,
        comparator: sortByFloat,
        filterParams: {
          filterOptions: ['lessThan', 'lessThanOrEqual', 'equals', 'greaterThan', 'greaterThanOrEqual'],
        },
      };
    }

    // Default for text, text_array, and other types
    return {
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['equals', 'notEqual', 'startsWith', 'endsWith', 'contains', 'notContains'],
      },
    };
  };

  const includeAGIfDefined = (ag) => {
    // Anything in the ag object will be passed through directly - these
    // are AG Grid-specific attributes.
    if (typeof ag === 'undefined' || ag === '') return {};

    return { ...ag };
  };

  const addActionsRendererIfNeeded = (data) => {
    if (data !== 'actions') return {};

    return {
      cellRenderer: 'actionsMenuRenderer',
      minWidth: 80,
      cellStyle: {
        justifyContent: 'left',
      },
      headerClass: 'no-resize-line',
      suppressHeaderMenuButton: true,
    };
  };

  const appendOrderKeys = (columns, orders) => {
    // Accepts columns and orders arrays and, if order is present present, returns
    // grid column definitions with the sorting attributes 'sort' and 'sortIndex'

    // Return unmodified columns if there's no order array
    if (orders === null || orders.length === 0) return columns;

    try {
      // If necessary, convert from legacy [index, order] to new [field, order]
      // Ex: ['field_name', 'asc'] instead of [1, 'asc']
      const convertedOrders = orders.map((order) => {
        if (typeof order[0] === 'number') {
          // In wrong format, so look up the appropriate column object and use its field attribute
          const column = columns[order[0]];
          return [column.field, order[1]];
        }
        // Already in the proper format, so use it as-is
        return order;
      });

      const resultColumns = columns.map((column) => ({ ...column }));

      // Append the ordering attributes to the column definitions
      convertedOrders.forEach(([field, order], index) => {
        if (order === 'asc' || order === 'desc') {
          const columnIndex = resultColumns.findIndex((c) => c.field === field);

          if (columnIndex !== -1) {
            resultColumns[columnIndex].sort = order;
            resultColumns[columnIndex].sortIndex = index;
          }
        }
      });

      return resultColumns;
    } catch (error) {
      // Return the unmodified columns array if something went awry
      return columns;
    }
  };

  const translatedColumns = columnsArray.map((column) => {
    // Return a checkbox column definition
    if (column.data === 'checkbox_control' || column.data === 'select') {
      return {
        ...includeIfDefined(column.sortable, 'sortable'),
        ...includeIfDefined(column.orderable, 'sortable'),
        ...translateSearchableIfDefined(column.searchable),
        ...translateClassNameIfDefined(column.className),
        ...includeIfDefined(column.visible, 'hide', !column.visible),
        ...includeAGIfDefined(column.ag),
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressMovable: true,
        field: 'checkbox_control',
        headerName: '',
        headerClass: 'no-resize-line',
        maxWidth: 45,
        suppressHeaderMenuButton: true,
        valueGetter: () => null,
      };
    }

    // Return a standard column definition
    return {
      ...includeIfDefined(column.title, 'headerName'),
      ...translateDataIfDefined(column.data),
      ...includeIfDefined(column.sortable, 'sortable'),
      ...includeIfDefined(column.orderable, 'sortable'),
      ...translateSearchableIfDefined(column.searchable),
      ...translateClassNameIfDefined(column.className),
      ...includeIfDefined(column.visible, 'hide', !column.visible),
      ...addFilterIfNeeded(column.data),
      ...includeAGIfDefined(column.ag),
      ...addActionsRendererIfNeeded(column.data),
      ...includeIfDefined(column.required, 'suppressMovable', column.required),
    };
  });

  const firstVisibleColumn = translatedColumns.find((e) => e.hide !== true);
  firstVisibleColumn.showRowGroup = true;
  firstVisibleColumn.enableRowGroup = false;

  return appendOrderKeys(translatedColumns, orderArray);
}
