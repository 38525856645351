import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

function RepairTypesBulkActions({
  recordIds,
  gridApi,
}) {
  const handleDeleteClick = () => {
    if (recordIds.length === 0) return;

    $.ajax({
      url: '/repair_types/bulk_delete',
      type: 'DELETE',
      data: {
        // _method: 'DELETE',
        repair_type_ids: recordIds,
      },
      cache: false,
      dataType: 'script',
    });
  };

  const performDeleteAll = () => {
    $.ajax({
      url: '/repair_types/bulk_delete',
      type: 'POST',
      data: {
        _method: 'DELETE',
        delete_all: 1,
      },
      cache: false,
      dataType: 'script',
    });
  };

  const handleDeleteAllClick = (e) => {
    Utility.ConfirmationDialog.show(
      { mainText: 'All repair types will be deleted. Continue?' },
      performDeleteAll,
    );
  };

  return (
    <>
      <button
        type="button"
        className="btn-white-multiselect tw-mr-3"
        id="bulk-delete-repair-types"
        onClick={handleDeleteClick}
      >
        {`Delete ${recordIds.length} item${recordIds.length === 1 ? '' : 's'}`}
      </button>
      <button
        type="button"
        className="btn-white-multiselect tw-mr-3"
        id="bulk-delete-all-repair-types"
        onClick={handleDeleteAllClick}
      >
        {`Delete All${gridApi ? ` ${gridApi.getDisplayedRowCount().toLocaleString()}` : ''} Repair Types`}
      </button>
    </>
  );
}

RepairTypesBulkActions.propTypes = {
  recordIds: PropTypes.array.isRequired,
  gridApi: PropTypes.object,
};

RepairTypesBulkActions.defaultProps = {
  gridApi: null,
};

export default React.memo(RepairTypesBulkActions);
