import React from 'react';
import PropTypes from 'prop-types';

export default function EquipmentHeader({
  name,
  info,
  path,
  imageUrl,
  onClose,
}) {
  return (
    <div className="tw-du-card tw-my-3 tw-du-card-side tw-bg-indigo-50 tw-shadow-md tw-border-solid tw-border-1 tw-border-accent">
      {imageUrl && (
        <figure className="tw-p-2">
          <img
            src={imageUrl}
            className="tw-max-w-[150px] tw-rounded-lg"
            alt={name}
          />
        </figure>
      )}
      <div className="tw-du-card-body tw-p-4 tw-flex tw-flex-col tw-justify-center">
        <a
          href={path}
          data-remote="true"
          className="tw-text-neutral-500 hover:tw-text-primary"
        >
          <h2 className="tw-font-extrabold">{name}</h2>
          <p className="tw-font-bold">{info}</p>
        </a>
      </div>
      <button
        type="button"
        onClick={onClose}
        className="im-clear tw-text-gray-600 tw-bg-transparent tw-border-0 tw-mt-2 tw-mb-2 tw-mr-3 tw-cursor-pointer tw-text-4xl tw-font-extralight tw-flex tw-flex-col tw-justify-center hover:tw-text-primary"
      >
        &times;
      </button>
    </div>
  );
}

EquipmentHeader.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.string,
  path: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

EquipmentHeader.defaultProps = {
  info: '',
  imageUrl: '',
};
