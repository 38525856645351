import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import Tippy from '@tippyjs/react';
import DOMPurify from 'dompurify';
import ReactDOM from 'react-dom';

function DetailCellRenderer({ data }) {
  const [htmlContent, setHtmlContent] = useState('');
  const [tooltips, setTooltips] = useState([]);
  const [dynamicTooltips, setDynamicTooltips] = useState([]);
  const [fetchedContent, setFetchedContent] = useState({});
  const fetchedContentRef = useRef(fetchedContent);

  // Update the ref whenever fetchedContent state changes - needed for the onShow closure withing
  // the dynamic Tippy component
  useEffect(() => {
    fetchedContentRef.current = fetchedContent;
  }, [fetchedContent]);

  useEffect(() => {
    const detailsUrl = data.DT_RowData['details-url'];
    if (detailsUrl) {
      // Retrieve the details content from the provided URL
      $.get({
        url: detailsUrl,
        dataType: 'html',
      }).done((dataResult) => {
        // Parse the HTML content to extract the tooltips:
        // We need to replace the legacy tooltip implementation with Tippy tooltips
        // in order to be compatible with rendering within an AGGrid row.
        const parser = new DOMParser();
        const doc = parser.parseFromString(dataResult, 'text/html');
        const taskTooltips = doc.querySelectorAll('.task-tooltip');

        const tooltipsData = [];
        taskTooltips.forEach((taskTooltip, index) => {
          // Extract the trigger element and the tooltip content
          const triggerElement = taskTooltip.children[0];
          const tooltipDiv = taskTooltip.querySelector('.tooltip');
          if (tooltipDiv && triggerElement) {
            const triggerHtml = triggerElement.outerHTML;
            const tooltipHtml = tooltipDiv.innerHTML;

            // Maintain a list of the tooltips that will be replaced
            tooltipsData.push({
              id: `tooltip-${index}`,
              triggerHtml,
              tooltipHtml,
            });

            // Add a placeholder for eventual replacement with a Tippy component
            // eslint-disable-next-line no-param-reassign
            taskTooltip.outerHTML = `<span id="tooltip-${index}"></span>`;
          }
        });

        // Dynamic tooltips are tooltips that are loaded via AJAX, so keep track of them separately
        const dynamicTooltipElements = doc.querySelectorAll('.task-work-order-tooltip');
        const dynamicTooltipsData = [];
        dynamicTooltipElements.forEach((dynamicTooltip, index) => {
          // Extract the trigger element and the URL to fetch the tooltip content
          const triggerElement = dynamicTooltip.children[0];
          if (triggerElement) {
            const triggerHtml = triggerElement.outerHTML;
            const dataUrl = dynamicTooltip.getAttribute('data-url');

            // Maintain a list of the tooltips that will be replaced with dynamic Tippy components
            dynamicTooltipsData.push({
              id: `dynamic-tooltip-${index}`,
              triggerHtml,
              dataUrl,
            });

            // Add a placeholder for eventual replacement with a dynamic Tippy component
            // eslint-disable-next-line no-param-reassign
            dynamicTooltip.outerHTML = `<span id="dynamic-tooltip-${index}"></span>`;
          }
        });

        setTooltips(tooltipsData);
        setDynamicTooltips(dynamicTooltipsData);
        setHtmlContent(doc.body.innerHTML);
      }).fail(() => {
        setHtmlContent('');
        setTooltips([]);
        setDynamicTooltips([]);
      });
    }
  }, [data]);

  // Replace the standard tooltip placeholders with Tippy components
  useEffect(() => {
    if (htmlContent && tooltips.length > 0) {
      tooltips.forEach(({ id, triggerHtml, tooltipHtml }) => {
        const placeholder = document.getElementById(id);
        if (placeholder) {
          const wrapper = document.createElement('div');
          placeholder.parentNode.replaceChild(wrapper, placeholder);

          const tippyComponent = (
            <Tippy
              content={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tooltipHtml) }} />}
              allowHTML
              arrow
              interactive
              appendTo={document.body}
              placement="right"
              theme="light-border"
              maxWidth="24em"
              className="tw-p-2"
            >
              <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(triggerHtml) }} />
            </Tippy>
          );

          ReactDOM.render(tippyComponent, wrapper);
        }
      });
    }
  }, [htmlContent, tooltips]);

  // Replace the dynamic tooltip placeholders with dynamic Tippy components
  useEffect(() => {
    if (htmlContent && dynamicTooltips.length > 0) {
      dynamicTooltips.forEach(({ id, triggerHtml, dataUrl }) => {
        const placeholder = document.getElementById(id);
        if (placeholder) {
          const wrapper = document.createElement('div');
          placeholder.parentNode.replaceChild(wrapper, placeholder);

          const tippyComponent = (
            <Tippy
              content="..."
              allowHTML
              arrow
              interactive
              appendTo={document.body}
              placement="right"
              theme="light-border"
              maxWidth="24em"
              className="tw-p-2"
              onShow={(instance) => {
                if (fetchedContentRef.current[id]) {
                  // Use the saved content if we've already retrieved it
                  instance.setContent(fetchedContentRef.current[id]);
                } else {
                  $.get(dataUrl, (response) => {
                    // The response comes back as JS, so we need to extract and parse the HTML content
                    const match = response.match(/\.html\("([\s\S]*?)"\)\.show/);
                    if (match) {
                      const rawHtmlContent = match[1]
                        .replace(/\\"/g, '"')
                        .replace(/\\'/g, "'")
                        .replace(/\\n/g, '')
                        .replace(/\\\//g, '/');

                      const parser = new DOMParser();
                      const parsedDoc = parser.parseFromString(rawHtmlContent, 'text/html');
                      const parsedHtmlContent = parsedDoc.body.innerHTML;

                      const sanitizedResponse = DOMPurify.sanitize(parsedHtmlContent);

                      // Save the content so we don't have to retrieve it on each tooltip show
                      setFetchedContent((prevContent) => {
                        const newContent = { ...prevContent, [id]: sanitizedResponse };
                        // Update the tooltip instance with the sanitized content
                        instance.setContent(sanitizedResponse);
                        return newContent;
                      });
                      instance.setContent(sanitizedResponse);
                    }
                  });
                }
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(triggerHtml) }} />
            </Tippy>
          );

          ReactDOM.render(tippyComponent, wrapper);
        }
      });
    }
  }, [htmlContent, dynamicTooltips, fetchedContent]);

  return (
    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlContent) }} />
  );
}

DetailCellRenderer.propTypes = {
  data: PropTypes.object.isRequired,
};

export default React.memo(DetailCellRenderer);
