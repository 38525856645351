import React, { useContext } from 'react';
import useBulkActionsComponent from './hooks/useBulkActionsComponent';
import { GridContext } from './data/GridContextManager';

function MultiselectHeader() {
  const {
    tableId,
    gridApiRef,
    selectedRecordIds,
  } = useContext(GridContext);

  const BulkActionsComponent = useBulkActionsComponent(tableId);

  const handleDeselectAll = () => {
    if (!gridApiRef.current) return;

    gridApiRef.current.deselectAll();
  };

  return (
    <div
      className={`tw-absolute tw-top-[12px] tw-left-12 tw-right-3.5 tw-text-sm tw-text-white tw-font-semibold ${selectedRecordIds.length > 0 ? 'tw-opacity-100 tw-z-50' : 'tw-opacity-0 tw-z-0'} tw-transition-opacity tw-duration-500`}
    >
      {BulkActionsComponent && (
        <BulkActionsComponent
          recordIds={selectedRecordIds}
          gridApi={gridApiRef.current}
        />
      )}
      <button
        type="button"
        className="im-clear tw-absolute tw-top-[3px] tw-right-[10px] tw-p-0 tw-bg-transparent tw-border-0 tw-text-white tw-text-lg tw-cursor-pointer"
        aria-label="Deselect All"
        onClick={handleDeselectAll}
      >
        <i className="fas fa-times" />
      </button>
    </div>
  );
}

export default React.memo(MultiselectHeader);
