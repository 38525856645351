window.Utility ||= {}
class Utility.Messaging
  messaging = null

  @sendTokenToServer = (token) ->
    $.ajax
      url: "/messaging_tokens"
      type: 'POST'
      data: {
        messaging_token: {
          token: token
        }
      }
      cache: false
      dataType: 'script'

  @deleteTokenFromServerAndSignOut = (token) ->
    $.ajax
      url: "/session"
      type: 'DELETE'
      data: {
        messaging_token: token
      }
      cache: false
      dataType: 'script'

  @notificationStatus = (status) =>
    $.ajax
      url: "/messaging_notification_status"
      data: {
        status: status
      }
      type: 'POST'
      cache: false
      dataType: 'script'

  @signOut = ->
    if window.productFruitsIsReady
      window.productFruits.services.destroy()

    if messaging != null && localStorage.getItem('notificationPermission') == 'granted'
      messaging.getToken().then (currentToken) =>
        messaging.deleteToken(currentToken).then (result) =>
          Utility.Messaging.deleteTokenFromServerAndSignOut(currentToken)
          console.log('Token deleted on sign out')
        .catch (err) =>
          console.log(err)
          Utility.Messaging.deleteTokenFromServerAndSignOut(currentToken)
      .catch (err) =>
        console.log(err)
        Utility.Messaging.deleteTokenFromServerAndSignOut('no_token')
    else
      try
        Utility.Messaging.deleteTokenFromServerAndSignOut('no_token')
      catch e
        console.log(e)

  @requestMessagingPermission = ->
    Notification.requestPermission().then (permission) =>
      if (permission == 'granted')
        console.log('Notification permission granted.')
        localStorage.setItem('notificationPermission', 'granted')
        Utility.Messaging.getMessagingToken()
        $('.notification-permission-hint').hide()
      else
        console.log('Notification permission denied.')
        localStorage.setItem('notificationPermission', 'denied')
        $('.notification-permission-hint').hide()
    .catch (err) =>
      console.log('An error occurred while requesting messaging permission.', err)
      Utility.Messaging.popupMessage('An error occurred while requesting messaging permission.', err, '')

  @getMessagingToken = ->
    console.log('Requesting messaging token')
    messaging.getToken().then (currentToken) =>
      if (currentToken)
        Utility.Messaging.sendTokenToServer(currentToken)
        console.log('Token sent to server')

    .catch (err) =>
      console.log('An error occurred while retrieving token.', err)
      console.log('Falling back to polling')
      Utility.Messaging.preparePolling()

  @preparePolling = ->
    $("#messages-menu-link[data-autorefresh]").each ->
      console.log "POLLING"    
      clearInterval($(@).reloadInterval)
      url = $(@).data('url')
      $(@).reloadInterval = setInterval (->
        $.get url, null, null, 'script'
      ), 1000 * $(@).data('autorefresh')


  @prepareWeb = ->
    if !window.PushManager
      Utility.Messaging.preparePolling()
    else if (typeof window.firebase != "undefined")
      console.log "EVENT DRIVEN"
      firebaseConfig = {
        apiKey: $('#fcm').data('config').apiKey
        authDomain: $('#fcm').data('config').authDomain
        databaseURL: $('#fcm').data('config').databaseUrl
        projectId: $('#fcm').data('config').projectId
        storageBucket: ''
        messagingSenderId: $('#fcm').data('config').messageSenderId
        appId: $('#fcm').data('config').appId
      }

      firebase.initializeApp(firebaseConfig)
      messaging = firebase.messaging()
      messaging.usePublicVapidKey($('#fcm').data('config').vapidPublicKey)

      if localStorage.getItem('notificationPermission') == 'granted'
        console.log('Notification permission previously granted')
        $('.notification-permission-hint').hide()
        Utility.Messaging.getMessagingToken()
      else
        $('.notification-permission-hint').show()
        $('.notification-permission').on 'click', =>
          Utility.Messaging.requestMessagingPermission()

      # Callback fired if Instance ID token is updated.
      messaging.onTokenRefresh =>
        messaging.getToken().then (refreshedToken) =>
          console.log('Token refreshed')
          # Indicate that the new Instance ID token has not yet been sent to the
          # app server.
          # setTokenSentToServer(false)
          Utility.Messaging.sendTokenToServer(refreshedToken)
        .catch (err) =>
          console.log('Unable to retrieve refreshed token ', err)

      messaging.onMessage (payload) =>
        console.log('onMessage: ', payload)
        Utility.Messaging.displayMessage(payload.notification.title, payload.notification.body, payload.data.url, payload.data.fromUserId, payload.data.messageId)

    else
      console.log('Firebase not loaded')

  @displayMessage = (title, body, url, fromUserId, messageId) =>
    if $(document).scrollTop() > 500
      Utility.Messaging.popupMessage(title, body, url)
    else if $('#messages-table').length > 0
      if ($('#message_to_user_id').length == 0 && fromUserId.length == 0) || $('#message_to_user_id').val() == fromUserId
        Utility.Messaging.reloadMessageTable(fromUserId, messageId)
      else
        Utility.Messaging.popupMessage(title, body, url)
    else
      Utility.Messaging.popupMessage(title, body, url)

  @reloadMessageTable = (fromUserId, messageId) =>
    $('#messages-table').DataTable().ajax.reload (json) =>
      if $('#messages-table tbody tr#message-' + messageId).length > 0
        $('#messages-table tbody tr#message-' + messageId + ' .message').effect('highlight')
      else
        Utility.Messaging.popupMessage(title, body, url)

  @updateBell = () =>
    $.get $('#messages-menu-link').data('url'), null, null, 'script'

  @popupMessage = (title, body, url) =>
    Utility.Messaging.updateBell()

    classes = 'toast-info'
    if url.length > 0
      title = "<a href='#{url}' data-remote=true>#{title}</a>"
      body = "<a href='#{url}' data-remote=true>#{body}</a>"
      classes = 'toast-info with-url'

    toastr["info"](
      body
      title
      closeButton: true
      newestOnTop: true
      positionClass: "toast-top-center"
      timeOut: 5000
      extendedTimeOut: 5000
      iconClass: classes
    )

  @refreshAlerts = (html, count) =>
    $('.alerts-container').html(html)
    # iOS APP ONLY
    if (typeof window.JsInterface != "undefined" && typeof JsInterface.setLauncherBadgeCount == "function")
      JsInterface.setLauncherBadgeCount(count)

  exports.requestMessagingPermission = @requestMessagingPermission