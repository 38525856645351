import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PassIcon from './icons/PassIcon';
import FailIcon from './icons/FailIcon';
import NaIcon from './icons/NaIcon';

function ActionButton({
  onClick,
  isActive,
  label,
  baseClass,
  activeBorderClass,
  defaultBorderClass,
  hoverBorderClass,
  activeBackClass,
  defaultBackClass,
  hoverBackClass,
  activeTextClass,
  defaultTextClass,
  disabled,
}) {
  const baseClasses = classNames({
    'im-clear tw-ring-inset tw-rounded-lg tw-h-20 tw-mx-0.5 sm:tw-mx-1 tw-my-1 tw-py-2 tw-shadow-sm tw-flex tw-flex-1 tw-flex-wrap tw-items-center tw-justify-center tw-text-base tw-font-semibold tw-shadow-sm active:tw-shadow-inner': true,
    [baseClass]: true,
    'tw-cursor-pointer hover:tw-ring-[2.5px]': !disabled,
    [hoverBorderClass]: !disabled,
    [hoverBackClass]: !disabled,
    'tw-delay-60 tw-transition-all tw-duration-300 tw-ease-in-out': !disabled,
  });
  const borderColorClass = isActive ? activeBorderClass : defaultBorderClass;
  const borderWidthClass = isActive ? 'tw-ring-[2.5px]' : 'tw-ring-[0.5px]';
  const backClass = isActive ? activeBackClass : defaultBackClass;
  const textClass = isActive ? activeTextClass : defaultTextClass;

  let IconComponent;
  if (label.toLowerCase() === 'pass') {
    IconComponent = PassIcon;
  } else if (label.toLowerCase() === 'fail') {
    IconComponent = FailIcon;
  } else {
    IconComponent = NaIcon;
  }

  return (
    <button
      className={`${baseClasses} ${borderColorClass} ${borderWidthClass} ${backClass} ${textClass}`}
      type="button"
      style={{ maxWidth: '155px', minWidth: '60px', maxHeight: '65px' }}
      onClick={onClick}
      disabled={disabled}
    >
      <IconComponent isActive={isActive} />
      <span className={`tw-mx-1 tw-w-[35px] sm:tw-mx-2 ${isActive ? 'tw-font-extrabold' : ''}`}>
        {label}
      </span>
    </button>
  );
}

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  label: PropTypes.string.isRequired,
  baseClass: PropTypes.string,
  activeBorderClass: PropTypes.string.isRequired,
  activeBackClass: PropTypes.string.isRequired,
  hoverBorderClass: PropTypes.string,
  activeTextClass: PropTypes.string.isRequired,
  defaultBorderClass: PropTypes.string,
  defaultBackClass: PropTypes.string,
  hoverBackClass: PropTypes.string,
  defaultTextClass: PropTypes.string,
  disabled: PropTypes.bool,
};

ActionButton.defaultProps = {
  isActive: false,
  baseClass: '',
  defaultBorderClass: 'tw-ring-muted-dark tw-ring-[0.25px]',
  hoverBorderClass: '',
  defaultBackClass: 'tw-bg-muted-light',
  hoverBackClass: '',
  defaultTextClass: 'tw-text-muted-dark',
  disabled: false,
};

export default React.memo(ActionButton);
