/* eslint-disable no-param-reassign */
const ACTIONS = {
  REQUEST_FETCH: 'request_fetch',
  FETCH_START: 'fetch_start',
  FETCH_SUCCESS: 'fetch_success',
  FETCH_ERROR: 'fetch_error',
  REQUEST_TOGGLE_UPDATE: 'request_toggle_update',
  TOGGLES_UPDATED: 'toggles_updated',
  UPDATE_STEPS: 'update_steps',
  UPDATE_STEPS_ERROR: 'update_steps_error',
  UPLOAD_PICTURE: 'upload_picture',
  REMOVE_PICTURE_START: 'remove_picture_start',
  REMOVE_PICTURE_SUCCESS: 'remove_picture_success',
  REMOVE_PICTURE_ERROR: 'remove_picture_error',
  SET_FILTER_PASS_FAIL: 'set_filter_pass_fail',
  SET_FILTER_STATUS: 'set_filter_status',
  CLEAR_FILTERS: 'clear_filters',
  UPDATE_VISIBLE_STEPS: 'update_visible_steps',
  CLEAR_PENDING_ATTRIBUTES: 'clear_pending_attributes',
};

const InspectionsReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.REQUEST_FETCH: {
      return {
        ...state,
        fetchRequested: true,
      };
    }

    case ACTIONS.FETCH_START: {
      return {
        ...state,
        fetchProcessing: true,
        fetchSuccess: false,
        fetchError: null,
        deviceHasCamera: false,
        allowAllOk: false,
        allowNA: false,
        createRepairByDefault: false,
        autoScrollOnPass: false,
        stepsIncompleteCount: 0,
        inspectionCompleted: false,
        steps: [],
        visibleStepIds: [],
        filterPassFail: 'none',
        filterStatus: {
          hasComments: false,
          hasValue: false,
          hasPicture: false,
        },
        allItemsPassed: false,
        scored: false,
        totalScore: 0,
      };
    }

    case ACTIONS.FETCH_SUCCESS: {
      const allItemsPassed = action.payload.data.steps.every((item) => item.pass !== 0);

      return {
        ...state,
        fetchProcessing: false,
        fetchSuccess: true,
        fetchError: null,
        deviceHasCamera: action.payload.data.device_has_camera,
        allowAllOk: action.payload.data.allow_all_ok,
        scored: action.payload.data.scored,
        totalScore: action.payload.data.total_score,
        allowNA: action.payload.data.allow_na,
        createRepairByDefault: action.payload.data.create_repair_by_default,
        autoScrollOnPass: action.payload.data.auto_scroll_on_pass,
        stepsIncompleteCount: action.payload.data.steps_incomplete_count,
        inspectionCompleted: action.payload.data.inspection_completed,
        steps: action.payload.data.steps,
        visibleStepIds: action.payload.data.steps.map((step) => step.id),
        filterPassFail: 'none',
        filterStatus: {
          hasComments: false,
          hasValue: false,
          hasPicture: false,
        },
        allItemsPassed,
      };
    }

    case ACTIONS.FETCH_ERROR: {
      return {
        ...state,
        fetchProcessing: false,
        fetchSuccess: false,
        fetchError: action.payload.error.message,
        deviceHasCamera: false,
        allowAllOk: false,
        allowNA: false,
        createRepairByDefault: false,
        autoScrollOnPass: false,
        stepsIncompleteCount: 0,
        inspectionCompleted: false,
        steps: [],
        visibleStepIds: [],
        filterPassFail: 'none',
        filterStatus: {
          hasComments: false,
          hasValue: false,
          hasPicture: false,
        },
        allItemsPassed: false,
        scored: false,
        totalScore: 0,
      };
    }

    case ACTIONS.REQUEST_TOGGLE_UPDATE: {
      return {
        ...state,
        toggleUpdateRequested: true,
      };
    }

    case ACTIONS.TOGGLES_UPDATED: {
      return {
        ...state,
        toggleUpdateRequested: false,
      };
    }

    case ACTIONS.UPDATE_STEPS: {
      const { changes } = action.payload;
      const updatedSteps = [...state.steps];

      changes.forEach((change) => {
        // Make sure the id is an integer
        const changeId = parseInt(change.id, 10);
        const stepIndex = updatedSteps.findIndex((step) => step.id === changeId);

        if (stepIndex !== -1) {
          change.id = changeId;
          if (Object.hasOwn(change, 'pass')) {
            change.pass = parseInt(change.pass, 10);
          }
          if (Object.hasOwn(change, 'create_repair_request')) {
            change.create_repair_request = change.create_repair_request === 'true';
          }
          updatedSteps[stepIndex] = {
            ...updatedSteps[stepIndex],
            error_message: null,
            ...change,
          };
        }
      });

      const allItemsPassed = updatedSteps.every((item) => item.pass !== 0);

      return {
        ...state,
        stepsIncompleteCount: action.payload.stepsIncompleteCount,
        totalScore: action.payload.totalScore,
        allItemsPassed,
        steps: updatedSteps,
        filterRequested: true,
      };
    }

    case ACTIONS.UPDATE_STEPS_ERROR: {
      const updatedSteps = [...state.steps];

      const stepIndex = updatedSteps.findIndex((step) => step.id === action.payload.stepId);

      if (stepIndex !== -1) {
        updatedSteps[stepIndex] = {
          ...updatedSteps[stepIndex],
          error_message: `Failed to update: ${action.payload.error}`,
        };
      }

      return {
        ...state,
        steps: updatedSteps,
      };
    }

    case ACTIONS.UPLOAD_PICTURE: {
      const { picture, stepId } = action.payload;
      const updatedSteps = [...state.steps];

      const stepIndex = updatedSteps.findIndex((step) => step.id === stepId);

      if (stepIndex !== -1) {
        updatedSteps[stepIndex] = {
          ...updatedSteps[stepIndex],
          has_picture: picture.url !== null,
          error_message: null,
          picture,
        };
      }

      return {
        ...state,
        steps: updatedSteps,
      };
    }

    case ACTIONS.REMOVE_PICTURE_START: {
      const updatedSteps = [...state.steps];

      const stepIndex = updatedSteps.findIndex((step) => step.id === action.payload.stepId);

      if (stepIndex !== -1) {
        updatedSteps[stepIndex] = {
          ...updatedSteps[stepIndex],
          error_message: null,
          picture: {
            ...updatedSteps[stepIndex].picture,
            is_removing: true,
          },
        };
      }

      return {
        ...state,
        steps: updatedSteps,
      };
    }

    case ACTIONS.REMOVE_PICTURE_SUCCESS: {
      const updatedSteps = [...state.steps];

      const stepIndex = updatedSteps.findIndex((step) => step.id === action.payload.stepId);

      if (stepIndex !== -1) {
        updatedSteps[stepIndex] = {
          ...updatedSteps[stepIndex],
          has_picture: false,
        };
      }

      return {
        ...state,
        steps: updatedSteps,
      };
    }

    case ACTIONS.REMOVE_PICTURE_ERROR: {
      const updatedSteps = [...state.steps];

      const stepIndex = updatedSteps.findIndex((step) => step.id === action.payload.stepId);

      if (stepIndex !== -1) {
        updatedSteps[stepIndex] = {
          ...updatedSteps[stepIndex],
          picture: {
            ...updatedSteps[stepIndex].picture,
            is_removing: false,
          },
          error_message: `Failed to remove picture: ${action.payload.error}`,
        };
      }

      return {
        ...state,
        steps: updatedSteps,
      };
    }

    case ACTIONS.SET_FILTER_PASS_FAIL: {
      return {
        ...state,
        filterPassFail: action.payload.filterValue,
        filterRequested: true,
      };
    }

    case ACTIONS.SET_FILTER_STATUS: {
      return {
        ...state,
        filterStatus: action.payload.attributes,
        filterRequested: true,
      };
    }

    case ACTIONS.CLEAR_FILTERS: {
      return {
        ...state,
        filterPassFail: 'none',
        filterStatus: {
          hasComments: false,
          hasValue: false,
          hasPicture: false,
        },
        filterRequested: true,
      };
    }

    case ACTIONS.UPDATE_VISIBLE_STEPS: {
      const { visibleStepIds } = action.payload;
      const updatedSteps = [...state.steps];

      state.steps.forEach((currentStep) => {
        const stepIndex = updatedSteps.findIndex((step) => step.id === currentStep.id);

        if (stepIndex !== -1) {
          updatedSteps[stepIndex] = {
            ...updatedSteps[stepIndex],
            hidden: !visibleStepIds.includes(currentStep.id),
          };
        }
      });

      return {
        ...state,
        visibleStepIds,
        steps: updatedSteps,
        filterRequested: false,
      };
    }

    case ACTIONS.CLEAR_PENDING_ATTRIBUTES: {
      return {
        ...state,
        pendingAttributes: null,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export { ACTIONS, InspectionsReducer };
