import React from 'react';
import PropTypes from 'prop-types';

export default function CollapsableMenuItem({ className, children, onClick }) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <li>
      <button
        type="button"
        onClick={onClick}
        className={`im-clear im-cm-item im-cm-clickable ${className}`}
      >
        {children}
      </button>
    </li>
  );
}

CollapsableMenuItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
};

CollapsableMenuItem.defaultProps = {
  className: '',
  onClick: null,
};
