import { useCallback, useState } from 'react';
import axios from 'axios';

// Fetches all records for use with AG Grid's client-side data model
function useFetchAllData(
  ajax,
  columnDefs,
  setTotalsRowData,
  setHaveError,
  setErrorMessage,
  onRecordIdsChanged,
) {
  const [rowData, setRowData] = useState();

  const fetchData = useCallback(async (params) => {
    try {
      const response = await axios.get(ajax);
      const { data } = response.data;
      setRowData(data);

      const totals = {};

      // Add the totals data row
      if (data.length > 0) {
        // All rows have the total from server, so just pick the 1st one.
        const dataRow = data[0];
        columnDefs.forEach((colDef) => {
          if (colDef.field.endsWith('_total')) {
            const baseField = colDef.field.slice(0, -6);
            totals[baseField] = dataRow[colDef.field];
          }
        });

        if (Object.keys(totals).length > 0) {
          setTotalsRowData([totals]);
        } else {
          setTotalsRowData([]);
        }
      }

      // Notify parent component(s) of current record ids in proper order
      const orderedIds = [];
      params.api.forEachNodeAfterFilterAndSort((node) => {
        if (!node.group && node.data?.id) orderedIds.push(node.data.id);
      });

      onRecordIdsChanged(orderedIds);
    } catch (error) {
      setHaveError(true);
      setErrorMessage(`Encountered an error while retrieving records: ${error.message}`);
    }
  }, [ajax, columnDefs, onRecordIdsChanged, setErrorMessage, setHaveError, setTotalsRowData]);

  return { fetchData, rowData };
}

export default useFetchAllData;
