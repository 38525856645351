import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';

function PassIcon({ isActive }) {
  return (
    <BaseIcon
      isActive={isActive}
      activeColorClass="tw-stroke-good-dark"
      pathData="M14.0490798,27.6653226 L28.9263804,4.98790323 M5.61349693,19.6975806 L13.5889571,27.2056452"
      name="pass"
    />
  );
}

PassIcon.propTypes = {
  isActive: PropTypes.bool,
};

PassIcon.defaultProps = {
  isActive: false,
};

export default React.memo(PassIcon);
