import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function CustomIntervalModal({
  isOpen, toDateOnly, fromDate, toDate, onApply, onClose, dateFormat,
}) {
  // Check for invalid dates
  const isValidDate = (d) => d instanceof Date && !Number.isNaN(d.getTime());

  // Intercept invalid dates and replace with today's date
  const safeDate = (dateString) => {
    const dateObj = new Date(`${dateString}T00:00:00`);
    return isValidDate(dateObj) ? dateObj : new Date();
  };

  const focusRef = useRef(null);
  const defaultFrom = fromDate ? safeDate(fromDate) : new Date();
  const defaultTo = toDate ? safeDate(toDate) : new Date();
  const [startDate, setStartDate] = useState(defaultFrom);
  const [endDate, setEndDate] = useState(defaultTo);

  const handleApplyClick = () => {
    onClose();
    onApply({ fromDate: startDate, toDate: endDate });
  };

  const toJSDateFormat = (unixFormat) => (
    // Conversion between UNIX-style date format strings and those used in JS
    // (Incomplete implementation limited to the components used in this app)
    unixFormat.replace('%d', 'dd').replace('%m', 'MM').replace('%Y', 'yyyy')
  );

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="tw-relative tw-z-10"
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
        </Transition.Child>

        <div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
          <div className="tw-flex tw-min-h-min tw-relative tw-top-10 tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
              enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            >
              <Dialog.Panel className="tw-relative tw-transform tw-overflow-show tw-rounded-xl tw-bg-white tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 tw-w-fit sm:tw-max-w-lg">
                <div className="tw-flex tw-text-center tw-bg-white tw-rounded-t-xl tw-px-4 tw-pb-4 tw-pt-5 sm:tw-p-6 sm:tw-pb-4">
                  {/* Hack to prevent auto focus on and opening of date selector */}
                  <input style={{ opacity: 0, position: 'absolute', top: '-50px' }} />
                  {!toDateOnly && (
                    <div className="tw-mt-3 tw-text-center tw-mr-4 sm:tw-mt-0 sm:tw-text-left">
                      <Dialog.Title as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
                        From Date
                      </Dialog.Title>
                      <DatePicker
                        selected={startDate}
                        className="im-clear tw-border-solid tw-py-2 tw-rounded tw-border-gray-300"
                        onChange={(date) => setStartDate(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dateFormat={toJSDateFormat(dateFormat)}
                        dropdownMode="select"
                        name="custom_interval_from_date"
                      />
                    </div>
                  )}
                  <div className="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-text-left">
                    <Dialog.Title as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
                      To Date
                    </Dialog.Title>
                    <DatePicker
                      selected={endDate}
                      className="im-clear tw-border-solid tw-py-2 tw-rounded tw-border-gray-300"
                      onChange={(date) => setEndDate(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dateFormat={toJSDateFormat(dateFormat)}
                      dropdownMode="select"
                      name="custom_interval_to_date"
                    />
                  </div>
                </div>
                <div className="im-clear tw-bg-gray-100 tw-rounded-b-xl tw-px-4 tw-py-3 tw-mt-3 sm:tw-flex sm:tw-flex-row-reverse sm:tw-px-6">
                  <button
                    type="button"
                    className="im-clear active:tw-shadow-inner tw-inline-flex tw-cursor-pointer tw-w-full tw-justify-center tw-rounded-md tw-bg-primary tw-px-4 tw-py-3 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-primary-focus sm:tw-ml-3 sm:tw-w-auto"
                    onClick={handleApplyClick}
                  >
                    Apply
                  </button>
                  <button
                    type="button"
                    ref={focusRef}
                    className="im-clear tw-mt-3 active:tw-shadow-inner tw-inline-flex tw-cursor-pointer tw-w-full tw-justify-center tw-rounded-md tw-bg-white tw-px-4 tw-py-3 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 sm:tw-mt-0 sm:tw-w-auto"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

CustomIntervalModal.propTypes = {
  isOpen: PropTypes.bool,
  toDateOnly: PropTypes.bool,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  dateFormat: PropTypes.string.isRequired,
};

CustomIntervalModal.defaultProps = {
  isOpen: false,
  toDateOnly: false,
  fromDate: null,
  toDate: null,
  onApply: () => null,
  onClose: () => null,
};
