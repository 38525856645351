import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomizationModal from '../CustomizationModal';

export default function CustomizeButton({ reportId, config, railsFormToken, onCustomize, orgLogoPresent, report_title }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  if (!reportId) return null;

  const handleClick = (e) => {
    e.preventDefault();
    setModalIsOpen(true);
  };

  const handleClose = (e, performUpdate = false) => {
    setModalIsOpen(false);
    onCustomize(performUpdate);
  };

  return (
    <>
      <button
        className="im-clear tw-mr-4 tw-cursor-pointer tw-text-primary tw-font-bold tw-min-w-[110px] tw-max-w-[125px] tw-text-left tw-border-0 tw-bg-transparent tw-mb-2 lg:tw-mb-0"
        type="button"
        onClick={handleClick}
      >
        <span className="fas fa-sliders-v tw-mr-1 tw-text-primary" />
        Customize
      </button>
      <CustomizationModal
        isOpen={modalIsOpen}
        onClose={handleClose}
        title="Customize Report"
        config={config}
        customizationClass="report_customizations"
        customizationName={reportId}
        orgLogoPresent={orgLogoPresent}
        railsFormToken={railsFormToken}
        report_title={report_title}
      />
    </>
  );
}

CustomizeButton.propTypes = {
  reportId: PropTypes.string,
};

CustomizeButton.defaultProps = {
  reportId: undefined,
};
