import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../ConfirmationDialog';

export default function ResetToDefaultButton({
  onReset,
}) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleResetToDefaultClick = () => {
    setShowConfirmModal(true);
  };

  const handleResetToDefault = () => {
    onReset();
    setShowConfirmModal(false);
  };

  return (
    <>
      <button
        className="im-clear tw-mr-4 tw-cursor-pointer tw-text-primary"
        type="button"
        onClick={handleResetToDefaultClick}
      >
        <span className="fas fa-history tw-mr-1 tw-text-primary" />
        Reset to default settings
      </button>
      <ConfirmationDialog
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onCancelClick={() => setShowConfirmModal(false)}
        onConfirmClick={handleResetToDefault}
        confirmLabel="Reset & Refresh"
      >
        <h2 className="tw-text-lg tw-font-semibold tw-mb-4">Are you sure?</h2>
        <p className="tw-text-sm tw-mb-6">
          {/* eslint-disable-next-line max-len */}
          This will immediately clear all customizations, restore default settings, and refresh the screen.
        </p>
      </ConfirmationDialog>
    </>
  );
}

ResetToDefaultButton.propTypes = {
  onReset: PropTypes.func.isRequired,
};
