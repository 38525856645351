import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useReportCategories() {
  return useQuery(
    ['report_categories'],
    () => axios.get('/report_categories.json').then((response) => response.data),
    {
      retry: 2,
    },
  );
}
