/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

export default function DraggableFieldName({
  field,
  index,
  onClick,
  inListReorder,
}) {
  const handleDisplayClick = () => {
    onClick(field.name);
  };

  return (
    <Draggable
      draggableId={field.name}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={`tw-flex tw-justify-between tw-items-center tw-border tw-border-solid tw-rounded-md tw-py-1 tw-px-2 tw-mb-1 tw-bg-white ${snapshot.isDragging ? 'tw-border-primary tw-border-2' : 'tw-border-gray-300'} hover:tw-border-primary draggable-field`}
        >
          <span>
            <i className="fas fa-grip-vertical tw-mr-2 tw-font-bold tw-text-gray-400" />
            <span className={!inListReorder ? 'tw-text-gray-400' : ''}>{field.title}</span>
          </span>
          <button
            type="button"
            onClick={handleDisplayClick}
            className="im-clear field-show-hide"
          >
            <i className={`fas ${inListReorder ? 'fa-eye' : 'fa-eye-slash'} tw-cursor-pointer hover:tw-text-primary tw-delay-75 tw-transition-all tw-duration-200 tw-ease-in-out`} />
          </button>
        </div>
      )}
    </Draggable>
  );
}

DraggableFieldName.propTypes = {
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  inListReorder: PropTypes.bool,
};

DraggableFieldName.defaultProps = {
  onClick: () => null,
  inListReorder: false,
};
