import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useCustomization({
  customizationClass, customizationName, performQuery,
}) {
  const isQueryEnabled = Boolean(customizationClass && customizationName && performQuery);

  return useQuery(
    ['customization', customizationClass, customizationName],
    () => axios.get(`/${customizationClass}/${customizationName}.json`).then((response) => response.data),
    {
      enabled: isQueryEnabled, // Only run when we have class and name
      cacheTime: 0,
      retry: 2,
    },
  );
}
