import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { InspectionsContext } from './data/InspectionsContextManager';
import SpinnerMessage from '../SpinnerMessage';
import ConfirmationDialog from '../ConfirmationDialog';

export default function PictureDisplay({ step }) {
  const { removePicture, readOnly } = useContext(InspectionsContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleRemoveClick = () => {
    if (readOnly) return;

    setShowConfirmModal(true);
  };

  const handleConfirmClick = () => {
    if (readOnly) return;

    setShowConfirmModal(false);
    removePicture(step.id);
  };

  return (
    <div className="tw-flex tw-flex-col tw-mt-2">
      {!readOnly && (
        <div className="tw-flex tw-justify-end">
          <button
            className="im-clear tw-mb-1 tw-py-1 tw-text-red-500 tw-border-0 active:tw-shadow-inner tw-cursor-pointer tw-justify-center tw-rounded tw-bg-muted-light tw-text-lg tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-muted-dark hover:tw-bg-neutral-50 hover:tw-ring-bad-dark"
            type="button"
            onClick={handleRemoveClick}
            disabled={step.picture.is_removing}
          >
            <div className="tw-flex tw-items-center">
              <i className="fa fa-times" />
              <span className="tw-text-xs tw-ml-1">{step.picture.is_removing ? 'Removing...' : 'Remove Picture'}</span>
            </div>
          </button>
        </div>
      )}
      <div className="tw-relative tw-text-center tw-w-100 tw-pt-1.5 tw-rounded" style={{ backgroundColor: '#162B43' }}>
        {step.picture.is_removing && (
          <div className="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-flex tw-justify-center tw-items-center tw-bg-black tw-bg-opacity-50">
            <SpinnerMessage message="" strokeColor="#ffffff" fillColor="#ffffff" />
          </div>
        )}
        <a
          href={step.picture.url}
          data-featherlight="image"
        >
          <img
            src={step.picture.url}
            alt={step.name}
            className={`tw-transition-opacity ${step.picture.is_removing ? 'tw-opacity-30' : 'tw-opacity-100'}`}
          />
        </a>
      </div>
      {!readOnly && (
        <ConfirmationDialog
          isOpen={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onCancelClick={() => setShowConfirmModal(false)}
          onConfirmClick={handleConfirmClick}
          confirmLabel="Remove Picture"
        >
          <h2 className="tw-text-lg tw-font-semibold tw-mb-4">Are you sure?</h2>
          <p className="tw-text-sm tw-mb-6">
            This will permanently remove the picture from the inspection step.
          </p>
        </ConfirmationDialog>
      )}
    </div>
  );
}

PictureDisplay.propTypes = {
  step: PropTypes.object.isRequired,
};
