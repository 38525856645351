import React, { useContext } from 'react';
import { GridContext } from './data/GridContextManager';

function ScrollToTopButton() {
  const {
    handleScrollToTop,
    showScrollButton,
    setShowScrollButton,
  } = useContext(GridContext);

  return (
    <button
      type="button"
      onClick={handleScrollToTop}
      className={`tw-absolute tw-bottom-4 tw-right-5 ${showScrollButton ? 'tw-opacity-1' : 'tw-opacity-0'} tw-transition tw-duration-500 tw-ease-in-out tw-bg-blue-500 tw-text-white tw-p-2 tw-rounded-full tw-w-10 tw-h-10 tw-flex tw-justify-center tw-items-center tw-cursor-pointer hover:tw-bg-blue-600`}
      onMouseEnter={() => setShowScrollButton(true)}
      onMouseLeave={() => setShowScrollButton(false)}
      aria-label="Scroll to top"
    >
      <i className="fas fa-arrow-up tw-m-0 tw-p-0" />
    </button>
  );
}

export default React.memo(ScrollToTopButton);
