import React from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';

function HtmlRenderer({ value }) {
  const cleanHTML = DOMPurify.sanitize(value);

  return (
    <div className="tw-py-2" dangerouslySetInnerHTML={{ __html: cleanHTML }} />
  );
}

HtmlRenderer.propTypes = {
  value: PropTypes.string,
};

HtmlRenderer.defaultProps = {
  value: '',
};

export default React.memo(HtmlRenderer);
