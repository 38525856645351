import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function FavoriteButton({ onClick, isFavorite }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames({
        'im-clear tw-text-gray-400 im-cp-clickable hover:tw-text-primary tw-w-1/6 tw-text-right tw-cursor-pointer': true,
        'tw-font-bold tw-text-primary hover:tw-text-blue-400': isFavorite,
      })}
    >
      <i className="fas fa-star tw-mr-1" />
    </button>
  );
}

FavoriteButton.propTypes = {
  onClick: PropTypes.func,
  isFavorite: PropTypes.bool,
};

FavoriteButton.defaultProps = {
  onClick: null,
  isFavorite: false,
};
