import React from 'react';
import SpinnerMessage from '../SpinnerMessage';

export default function RunningIndicator() {
  return (
    <div className="tw-text-neutral-600 tw-text-lg tw-font-bold tw-h-screen tw-align-middle tw-flex tw-flex-col tw-justify-center tw-items-center">
      <SpinnerMessage message="Report running..." />
    </div>
  );
}
