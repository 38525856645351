import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import Tippy from '@tippyjs/react';

function TooltipCellRenderer({ data, colDef }) {
  const [tooltipContent, setTooltipContent] = useState(null);
  const [cellContent, setCellContent] = useState(null);

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data[colDef.field], 'text/html');
    const tooltipDiv = doc.querySelector('div.tooltip');

    if (tooltipDiv) {
      // If it exists, we will use it as the tooltip content
      // Need to remove the div from the document body
      tooltipDiv.remove();
      setTooltipContent(tooltipDiv.innerHTML);
    }

    // The remaining content will be rendered in the cell directly
    setCellContent(doc.body.innerHTML);
  }, [data, colDef.field]);

  return (
    <>
      {tooltipContent && (
        <Tippy
          content={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tooltipContent)}} />}
          allowHTML
          arrow
          placement="right"
          theme="light-border"
          maxWidth="24em"
          className="tw-p-2"
        >
          <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cellContent) }} />
        </Tippy>
      )}
      {!tooltipContent && (
        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cellContent) }} />
      )}
    </>
  );
}

TooltipCellRenderer.propTypes = {
  data: PropTypes.object.isRequired,
  colDef: PropTypes.shape({
    field: PropTypes.string.isRequired,
  }).isRequired,
};

export default React.memo(TooltipCellRenderer);
