import React, {
  Fragment, useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { DragDropContext } from 'react-beautiful-dnd';
import ReorderableFields from './ReorderableFields';
import SpinnerMessage from '../SpinnerMessage';
import useCustomization from './hooks/useCustomization';
import useCustomizationMutations from './hooks/useCustomizationMutations';
import LogoAndChartToggles from './LogoAndChartToggles';
import ResetToDefaultButton from './ResetToDefaultButton';
import RowsToDisplaySelect from './RowsToDisplaySelect';

export default function CustomizationWindow({
  isOpen,
  onClose,
  title,
  customizationClass,
  customizationName,
  orgLogoPresent,
  railsFormToken,
  report_title,
}) {
  const focusRef = useRef(null);
  const [displayedFields, setDisplayedFields] = useState([]);
  const [hiddenFields, setHiddenFields] = useState([]);
  const [displayLength, setDisplayLength] = useState(25);
  const [suppressCharts, setSuppressCharts] = useState(null);
  const [showOrgLogo, setShowOrgLogo] = useState(null);
  const [hideThumbnails, setHideThumbnails] = useState(null);
  const [showModal, setShowModal] = useState(isOpen);

  const handleClose = (e, performUpdate = false) => {
    setShowModal(false);

    // Dispatch a custom event to work with Rails utilization of this component
    const event = new CustomEvent('customizationModalClosed', {
      detail: {
        performUpdate,
      },
    });
    document.dispatchEvent(event);

    onClose(null, performUpdate);
  };

  const {
    status, data: customization, error, isFetching,
  } = useCustomization({ customizationClass, customizationName, performQuery: showModal });

  const { updateCustomizationMutation, deleteCustomizationMutation } = useCustomizationMutations({
    railsFormToken,
    onSuccess: () => handleClose(null, true),
  });

  useEffect(() => {
    if (!isFetching && status === 'success') {
      setDisplayedFields(customization.displayed_fields);
      setHiddenFields(customization.hidden_fields);
      setDisplayLength(customization.display_length || 25);
      setSuppressCharts(customization.suppress_charts);
      setHideThumbnails(customization.hide_thumbnails);

      if (customization.supports_org_logo && orgLogoPresent) {
        setShowOrgLogo(customization.show_org_logo);
      } else {
        setShowOrgLogo(false);
      }
    }
  }, [isFetching, status, customization, setDisplayedFields, setHiddenFields, setDisplayLength]);

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  if (status === 'error') {
    // Throw any error so the ErrorBoundary can pick it up
    throw error;
  }

  const handleToggleDisplay = (fieldId) => {
    const fieldInDisplayed = displayedFields.find((field) => field.name === fieldId);

    if (fieldInDisplayed) {
      setDisplayedFields((prevFields) => prevFields.filter((field) => field.name !== fieldId));
      setHiddenFields((prevFields) => [...prevFields, fieldInDisplayed]);
    } else {
      const fieldInHidden = hiddenFields.find((field) => field.name === fieldId);
      setHiddenFields((prevFields) => prevFields.filter((field) => field.name !== fieldId));
      setDisplayedFields((prevFields) => [...prevFields, fieldInHidden]);
    }
  };

  const handleDragStart = () => {
    document.body.style.cursor = 'grabbing';
  };

  const handleDragEnd = (dropResult) => {
    document.body.style.cursor = 'default';
    const { destination, source } = dropResult;

    if (!destination) {
      // dropped outside the list
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      // dropped on the same place
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'displayedFields') {
        // Dropped within the same list
        const items = Array.from(displayedFields);
        const [reorderedItem] = items.splice(source.index, 1);
        items.splice(destination.index, 0, reorderedItem);
        setDisplayedFields(items);
      } else {
        const items = Array.from(hiddenFields);
        const [reorderedItem] = items.splice(source.index, 1);
        items.splice(destination.index, 0, reorderedItem);
        setHiddenFields(items);
      }
    } else if (source.droppableId === 'displayedFields') {
      // Dropped in another list
      const sourceItems = Array.from(displayedFields);
      const destinationItems = Array.from(hiddenFields);
      const [movedItem] = sourceItems.splice(source.index, 1);
      destinationItems.splice(destination.index, 0, movedItem);
      setDisplayedFields(sourceItems);
      setHiddenFields(destinationItems);
    } else {
      const sourceItems = Array.from(hiddenFields);
      const destinationItems = Array.from(displayedFields);
      const [movedItem] = sourceItems.splice(source.index, 1);
      destinationItems.splice(destination.index, 0, movedItem);
      setHiddenFields(sourceItems);
      setDisplayedFields(destinationItems);
    }
  };

  const isArrayEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    // eslint-disable-next-line no-plusplus
    for (let i = arr1.length; i--;) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  };

  const handleSave = () => {
    const attributes = {
      customizationEndpoint: customizationClass,
      customizationId: customization.id,
      displayLength,
      suppressCharts,
      ...(customization.supports_org_logo ? { showOrgLogo } : {}),
      ...(customization.supports_thumbnails ? { hideThumbnails } : {}),
    };

    attributes.suppressedFields = hiddenFields
      .filter((field) => field.suppressable)
      .map((field) => field.name);

    attributes.customFields = displayedFields
      .filter((field) => !field.suppressable)
      .map((field) => field.name);

    const orderedFields = displayedFields.map((field) => field.name)
      .concat(hiddenFields.map((field) => field.name));

    if (!isArrayEqual(orderedFields, customization.current_order)) {
      attributes.orderedFields = orderedFields;
    }

    updateCustomizationMutation.mutate({ ...attributes });
  };

  const resetToDefault = () => {
    if (report_title) {
      localStorage.setItem(`ReportOrder_${report_title}`, null);
    }

    deleteCustomizationMutation.mutate({
      customizationEndpoint: customizationClass,
      customizationId: customization.id,
    });
  };

  return (
    <Transition.Root show={showModal && !isFetching} as={Fragment}>
      <Dialog
        as="div"
        className="im-clear tw-relative tw-z-10"
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
        </Transition.Child>

        <div className="tw-fixed tw-inset-0 tw-z-10">
          <div className="tw-flex tw-min-h-min tw-relative tw-top-0 tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0"
              enterTo="tw-opacity-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              <Dialog.Panel className="tw-w-4/5 sm:tw-w-3/6 tw-rounded-xl tw-bg-white tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 tw-max-h-modal tw-flex tw-flex-col tw-justify-between">
                <div className="tw-bg-white tw-rounded-t-xl tw-px-4 tw-pb-4 tw-pt-5 sm:tw-p-6 sm:tw-pb-4 tw-overflow-y-auto tw-overflow-x-hidden tw-flex-grow">
                  <div className="tw-flex tw-justify-between tw-items-center">
                    <h2 className="tw-font-semibold">
                      {title}
                    </h2>
                    {!isFetching && status !== 'loading' && (
                      <ResetToDefaultButton onReset={resetToDefault} />
                    )}
                  </div>

                  {!isFetching && status !== 'loading' && (
                    <div className="tw-mt-5">
                      <RowsToDisplaySelect
                        displayLength={displayLength}
                        onChange={(e) => setDisplayLength(e.target.value)}
                      />

                      <LogoAndChartToggles
                        supportsOrgLogo={customization.supports_org_logo}
                        showOrgLogo={showOrgLogo}
                        onShowOrgLogoChange={() => setShowOrgLogo(!showOrgLogo)}
                        orgLogoPresent={orgLogoPresent}
                        uploadLogoPath={customization.upload_logo_path}
                        suppressCharts={suppressCharts}
                        onSuppressChartsChange={() => setSuppressCharts(!suppressCharts)}
                        supportsThumbnails={customization.supports_thumbnails}
                        hideThumbnails={hideThumbnails}
                        onHideThumbnailsChange={() => setHideThumbnails(!hideThumbnails)}
                      />

                      <div className="tw-border-t tw-border-solid tw-border-gray-100 tw-my-4" />

                      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                        <div className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-space-x-6">
                          <div className="tw-flex-1">
                            <h3 className="tw-my-1 tw-font-bold">
                              Visible Columns:
                            </h3>
                            <ReorderableFields
                              fields={displayedFields}
                              onClick={handleToggleDisplay}
                              droppableId="displayedFields"
                              dragBackgroundClass="tw-bg-blue-50"
                              inListReorder
                            />
                          </div>
                          <div className="tw-flex-1">
                            <h3 className="tw-my-1 tw-font-bold">
                              Available columns:
                            </h3>
                            <ReorderableFields
                              fields={hiddenFields}
                              onClick={handleToggleDisplay}
                              droppableId="hiddenFields"
                              dragBackgroundClass="tw-bg-amber-50"
                              inListReorder={false}
                            />
                          </div>
                        </div>
                      </DragDropContext>
                    </div>
                  )}
                </div>
                <div className="tw-bg-gray-100 tw-rounded-b-xl tw-px-4 tw-py-3 sm:tw-px-6 sm:tw-flex sm:tw-justify-end">
                  <button
                    type="button"
                    ref={focusRef}
                    className="im-clear tw-mt-3 active:tw-shadow-inner tw-inline-flex tw-cursor-pointer tw-w-full tw-justify-center tw-rounded-md tw-bg-white tw-px-4 tw-py-3 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 sm:tw-mt-0 sm:tw-w-auto sm:tw-mr-3 sm:tw-mb-0"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="im-clear tw-mt-3 active:tw-shadow-inner tw-inline-flex tw-cursor-pointer tw-w-full tw-justify-center tw-rounded-md tw-bg-primary tw-px-4 tw-py-3 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-primary-focus sm:tw-w-auto sm:tw-mt-0"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

CustomizationWindow.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  customizationClass: PropTypes.string.isRequired,
  customizationName: PropTypes.string.isRequired,
  orgLogoPresent: PropTypes.bool,
  railsFormToken: PropTypes.string.isRequired,
};

CustomizationWindow.defaultProps = {
  isOpen: false,
  onClose: () => null,
  orgLogoPresent: false,
};
