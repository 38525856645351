window.Utility ||= {}

class Utility.HelpPanel
  @flyInActive = false

  @prepare = ->
    $('a[data-click="help-panel"]').on 'click', (event) ->
      event.preventDefault()
      url = $(this).data('url')
      Utility.HelpPanel.show(url)

  @show = (origin = null) ->
    return if @flyInActive

    # Initialize panel with iframe
    @flyInActive = true

    iframeHtml = """
    <div class="panel-container tw-h-full tw-min-h-full tw-overflow-y-hidden" id="help-contents">
      <div class="panel im-panel-container panel-medium tw-pb-0 tw-h-full tw-min-h-full tw-flex tw-flex-col">
        <div class="panel-header show-action-header  ui-helper-clearfix">
          <h2>
            <span class="title-text"><a href="#{origin}" target="_blank" class="hide-help">Open in new tab<i class="tw-pl-2 far fa-external-link"></i></a></span>
            <span class="back tw-absolute tw-right-0">
              <a class="tw-mr-3 hide-help" href="javascript:;"><i class="far fa-times"></i></a>
            </span>
          </h2>
        </div>
        <div class="panel-body im-fly-in-panel tw-px-0 tw-pb-0 tw-overflow-y-auto tw-h-full tw-opacity-100">
          <iframe src="#{origin}" class="tw-w-full tw-h-full tw-min-h-full tw-overflow-y-hidden tw-border-0">
          </iframe>
        </div>
      </div>
    </div>
    """

    $('#help-panel').html(iframeHtml)

    # Close the panel with escape
    $(document).on 'keydown', (event) =>
      if event.key == 'Escape' or event.keyCode == 27
        @hide()

    # Display the panel and prevent body scrolling
    $('#help-panel').addClass('active')
    $('body').addClass('prevent-scrolling')

    # Set a full-page overlay to prevent underlying element interactions
    unless $('.full-page-overlay').length
      $('<div class="full-page-overlay tw-fixed tw-bg-black tw-top-0 tw-left-0 tw-w-full tw-h-full tw-opacity-0 tw-transition-opacity tw-duration-500"></div>').appendTo('body')
    # Fade in the overlay after panel has enough time to start its animation
    setTimeout ->
      $('.full-page-overlay').removeClass('tw-opacity-0').addClass('tw-opacity-30')
    , 100

  @hide = ->
    @flyInActive = false

    # Hide the panel
    $('#help-panel').removeClass('active')
    #  Re-enable body scrolling if a fly-in is not present
    if $('#fly-in-panel.active').length == 0
      $('body').removeClass('prevent-scrolling')

    # Remove keydown event handler
    $(document).off 'keydown'

    # Once there's been time enough for the panel to start flying out,
    # empty its contents and remove the full-page overlay
    setTimeout ->
      @originUrl = null
      $('#help-panel').empty()
      if $('.full-page-overlay').length and $('#fly-in-panel.active').length == 0
        $('.full-page-overlay').remove()
    , 250
