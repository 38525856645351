import React from 'react';
import PropTypes from 'prop-types';
import SpinnerMessage from '../SpinnerMessage';

function LoadingOverlay({ loadingMessage }) {
  return (
    <div role="presentation">
      <SpinnerMessage message={loadingMessage} />
    </div>
  );
}

LoadingOverlay.propTypes = {
  loadingMessage: PropTypes.string,
};

LoadingOverlay.defaultProps = {
  loadingMessage: '',
};

export default React.memo(LoadingOverlay);
