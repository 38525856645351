import React from 'react';
import PropTypes from 'prop-types';

export default function SpinnerMessage({
  message,
  strokeColor,
  strokeWidth,
  fillColor,
  spinnerClass,
  messageClass,
}) {
  return (
    <>
      <svg
        className={`tw-animate-spin -tw-mb-1 tw-mr-2 tw-h-5 tw-w-5 tw-text-white ${spinnerClass}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="tw-opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        />
        <path
          className="tw-opacity-75"
          fill={fillColor}
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
      <div className={`tw-mt-2 ${messageClass}`}>
        {message}
      </div>
    </>
  );
}

SpinnerMessage.propTypes = {
  message: PropTypes.string,
  strokeColor: PropTypes.string,
  strokeWidth: PropTypes.string,
  fillColor: PropTypes.string,
  spinnerClass: PropTypes.string,
  messageClass: PropTypes.string,
};

SpinnerMessage.defaultProps = {
  message: 'Running',
  strokeColor: '#5046e5',
  strokeWidth: '4',
  fillColor: '#5046e5',
  spinnerClass: '',
  messageClass: '',
};
