import React, { useState, useContext } from 'react';
import { InspectionsContext } from './data/InspectionsContextManager';
import FilterDropdownButton from '../FilterDropdownButton';

export default function StepFilters() {
  const {
    setFilterPassFail,
    setFilterStatus,
    clearFilters,
    readOnly,
  } = useContext(InspectionsContext);

  const passFailFilterEmptyText = 'Any Status';
  const statusFilterEmptyText = 'All';
  const [passFailFilterText, setPassFailFilterText] = useState(readOnly ? 'Failed' : passFailFilterEmptyText);
  const [statusFilterText, setStatusFilterText] = useState(statusFilterEmptyText);

  const passFailFilterOptions = [
    {
      filter_id: 'passFailFilter',
      filter_value: 'none',
      name: passFailFilterEmptyText,
    },
    {
      filter_id: 'passFailFilter',
      filter_value: 1,
      name: 'Passed',
    },
    {
      filter_id: 'passFailFilter',
      filter_value: 0,
      name: 'Failed',
    },
    {
      filter_id: 'passFailFilter',
      filter_value: -1,
      name: 'N/A',
    },
    ...(readOnly ? [] : [{
      filter_id: 'passFailFilter',
      filter_value: null,
      name: 'Incomplete',
    }]),
  ];

  const statusFilterOptions = [
    {
      filter_id: 'statusFilter',
      filter_value: 'filterNone',
      name: statusFilterEmptyText,
    },
    {
      filter_id: 'statusFilter',
      filter_value: 'filterHasComments',
      name: 'With Remarks',
    },
    {
      filter_id: 'statusFilter',
      filter_value: 'filterHasPicture',
      name: 'With Picture',
    },
    {
      filter_id: 'statusFilter',
      filter_value: 'filterHasValue',
      name: 'With Value',
    },
  ];

  const handlePassFailFilterChange = ({ item }) => {
    setFilterPassFail(item.filter_value);
    setPassFailFilterText(item.name);
  };

  const handleStatusFilterChange = ({ item }) => {
    if (item.filter_value === 'filterNone') {
      setFilterStatus({
        hasComments: false,
        hasValue: false,
        hasPicture: false,
      });
    } else {
      setFilterStatus({
        hasComments: item.filter_value === 'filterHasComments',
        hasValue: item.filter_value === 'filterHasValue',
        hasPicture: item.filter_value === 'filterHasPicture',
      });
    }

    setStatusFilterText(item.name);
  };

  const handleClearFiltersClick = () => {
    clearFilters();
    setPassFailFilterText(passFailFilterEmptyText);
    setStatusFilterText(statusFilterEmptyText);
  };

  return (
    <div className="tw-mb-3 tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 lg:tw-gap-3">
      <div className="tw-flex tw-flex-wrap tw-mb-1 max-sm:tw-overflow-x-auto max-sm:tw-overflow-y-hidden max-sm:tw-no-scrollbar">
        <FilterDropdownButton
          options={passFailFilterOptions}
          onClick={handlePassFailFilterChange}
          currentText={passFailFilterText}
          id="pass-fail-filter-dropdown"
        />
        <FilterDropdownButton
          options={statusFilterOptions}
          onClick={handleStatusFilterChange}
          currentText={statusFilterText}
          id="status-filter-dropdown"
        />

        {(passFailFilterText !== passFailFilterEmptyText || statusFilterText !== statusFilterEmptyText) && (
          <button
            className="im-clear tw-mb-2 tw-cursor-pointer tw-text-primary tw-border-0 tw-bg-transparent"
            type="button"
            onClick={handleClearFiltersClick}
          >
            Clear filters
          </button>
        )}
      </div>
    </div>
  );
}
