import React, { useEffect, useState, useRef } from 'react';
import HideableHint from './HideableHint';
import { requestMessagingPermission} from '../utilities/messaging.js.coffee';

function NotificationPermissionHint(props) {
  const [isVisible, setIsVisible] = useState(true);
  const containerRef = useRef(null);
  
  useEffect(() => {
    // Don't show if the user has already granted permission
    if (localStorage.getItem('notificationPermission') === 'granted') {
      setIsVisible(false);
    }
  }, []);

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.classList.contains('notification-permission')) {
        requestMessagingPermission()
      }
    };

    // If the Hint has been hidden, containerElement will be null
    const containerElement = containerRef.current;
    if (containerElement) {
      containerElement.addEventListener('click', handleClick);

      // Clean up the event listener when the component unmounts
      return () => {
        containerElement.removeEventListener('click', handleClick);
      };  
    }
  }, []);  

  if (!isVisible) {
    return null;
  }
  return <HideableHint {...props} ref={containerRef} />;
}

export default NotificationPermissionHint;