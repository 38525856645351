/* eslint-disable no-undef */
import React, {
  createContext,
  useReducer,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import axios from 'axios';
import { ACTIONS, InspectionsReducer } from './InspectionsReducer';
import useRailsEvent from '../../hooks/useRailsEvent';

const InspectionsContext = createContext();

function InspectionsContextProvider({
  children,
  equipmentId,
  inspectionId,
  railsFormToken,
  readOnly,
  pendingAttributes,
}) {
  const initialState = {
    fetchRequested: true,
    fetchProcessing: true,
    fetchSuccess: false,
    fetchError: null,
    toggleUpdateRequested: false,
    filterRequested: false,
    deviceHasCamera: false,
    inspectionCompleted: false,
    allowAllOk: false,
    allowNA: false,
    createRepairByDefault: false,
    autoScrollOnPass: false,
    stepsIncompleteCount: 0,
    readOnly,
    pendingAttributes,
    steps: [],
    visibleStepIds: [],
    filterPassFail: 'none',
    filterStatus: {
      hasComments: false,
      hasValue: false,
      hasPicture: false,
    },
    allItemsPassed: false,
    scored: false,
    totalScore: 0,
  };

  const [state, dispatch] = useReducer(InspectionsReducer, initialState);
  const stepsRef = useRef(state.steps);

  // Event handler for when the Rails side performs a step update
  const handleUpdateSteps = (eventData) => {
    dispatch({
      type: ACTIONS.UPDATE_STEPS,
      payload: {
        stepsIncompleteCount: eventData.steps_incomplete_count,
        totalScore: eventData.total_score,
        changes: eventData.changes,
      },
    });
    dispatch({ type: ACTIONS.REQUEST_TOGGLE_UPDATE });
  };

  useRailsEvent(handleUpdateSteps, 'UpdateSteps');

  // Event handler for when the Rails side performs a picture upload
  const handlePictureUpload = (eventData) => {
    dispatch({ type: ACTIONS.UPLOAD_PICTURE, payload: eventData });
  };

  useRailsEvent(handlePictureUpload, 'PictureUpload');

  // Perform initial fetch whenever fetchRequested changes to true
  // (Always the case upon initial render because of initialState)
  // or if equipmentId or inspectionId change
  useEffect(() => {
    const fetchSteps = async () => {
      try {
        const url = `/equipment/${equipmentId}/inspections/${inspectionId}/steps`;
        const response = await axios.get(url);
        dispatch({ type: ACTIONS.FETCH_SUCCESS, payload: { data: response.data } });

        if (readOnly) {
          dispatch({ type: ACTIONS.SET_FILTER_PASS_FAIL, payload: { filterValue: 0 } });
        }

        // Merge in any pending, non-saved attributes passed in to the edit view
        // (Can happen if there's a Rails form validation error.)
        if (state.pendingAttributes !== null) {
          dispatch({
            type: ACTIONS.UPDATE_STEPS,
            payload: {
              stepsIncompleteCount: response.data.stepsIncompleteCount,
              totalScore: response.data.total_score,
              changes: state.pendingAttributes,
            },
          });
          dispatch({ type: ACTIONS.CLEAR_PENDING_ATTRIBUTES });
        }
        if (!readOnly) App.Inspections.updateToggles();
      } catch (error) {
        dispatch({ type: ACTIONS.FETCH_ERROR, payload: { error } });
      }
    };

    if (state.fetchRequested) {
      dispatch({ type: ACTIONS.FETCH_START });
      fetchSteps();
    }
    // Intentionally limited dependency array
  }, [equipmentId, inspectionId, state.fetchRequested]);

  // Call the legacy updateToggles when requested
  useEffect(() => {
    if (state.toggleUpdateRequested) {
      App.Inspections.updateToggles();
      dispatch({ type: ACTIONS.TOGGLES_UPDATED });
    }
  }, [state.toggleUpdateRequested]);

  // Update stepsRef whenever state.steps changes
  useEffect(() => {
    stepsRef.current = state.steps;
  }, [state.steps]);

  // When the steps array or either of the filter options changes, apply filters
  useEffect(() => {
    const applyFilters = () => {
      let visibleSteps = [...stepsRef.current];

      if (state.filterStatus.hasComments) {
        visibleSteps = visibleSteps.filter((step) => step.comments && step.comments.length > 0);
      }

      if (state.filterStatus.hasValue) {
        visibleSteps = visibleSteps.filter((step) => step.value && step.value.length > 0);
      }

      if (state.filterStatus.hasPicture) {
        visibleSteps = visibleSteps.filter((step) => step.has_picture);
      }

      if (state.filterPassFail === 'none') {
        // Include all steps if the pass/fail filter is cleared/set to 'none'
        visibleSteps = visibleSteps.filter((step) => step.pass || step.pass === null || step.pass === 0);
      } else {
        visibleSteps = visibleSteps.filter((step) => step.pass === state.filterPassFail);
      }

      // Never return section headers if filtering is active since they may not make sense
      if (
        state.filterStatus.hasComments
        || state.filterStatus.hasValue
        || state.filterStatus.hasPicture
        || state.filterPassFail !== 'none'
      ) {
        visibleSteps = visibleSteps.filter((step) => step.section_header === false);
      }

      return visibleSteps.map((step) => step.id);
    };

    if (state.filterRequested) {
      const visibleStepIds = applyFilters();
      dispatch({ type: ACTIONS.UPDATE_VISIBLE_STEPS, payload: { visibleStepIds } });
    }
  }, [state.filterRequested, state.filterStatus, state.filterPassFail]);

  const statusName = (value) => {
    switch (value) {
      case 1:
        return 'pass';
      case 0:
        return 'fail';
      case -1:
        return 'na';
      default:
        return 'incomplete';
    }
  };

  // Function to update attributes on an inspection step
  const updateStep = useCallback((stepId, attributes, payloadOverride = null) => {
    const payload = payloadOverride || { results: { [stepId]: {} } };

    if (payloadOverride === null) {
      Object.keys(attributes).forEach((key) => {
        if (attributes[key] !== undefined) {
          payload.results[stepId][key] = attributes[key];
        }
      });
    }

    $.ajax({
      url: `/inspections/${inspectionId}/update_steps`,
      type: 'PUT',
      data: payload,
      dataType: 'script',
      error: (_jqXHR, _textStatus, errorThrown) => {
        dispatch({ type: ACTIONS.UPDATE_STEPS_ERROR, payload: { stepId, error: errorThrown } });
      },
    });
  }, [inspectionId]);

  // Function to mark all visible inspection steps as OK
  const allOk = useCallback(() => {
    const stepsToUpdate = state.steps.filter((step) => !step.section_header && !step.hidden);
    const payload = { results: {} };

    stepsToUpdate.forEach((step) => {
      payload.results[step.id] = { pass: 1 };
    });

    updateStep(null, null, payload);
  }, [state.steps, updateStep]);

  // Function to remove the picture from an inspection step
  const removePicture = useCallback((stepId) => {
    const doPictureRemove = async () => {
      try {
        const url = `/inspection_steps/${stepId}/remove_picture`;
        const config = {
          headers: {
            'X-CSRF-Token': railsFormToken,
            'Content-Type': 'application/json',
          },
        };
        await axios.patch(url, {}, config);
        dispatch({ type: ACTIONS.REMOVE_PICTURE_SUCCESS, payload: { stepId } });
      } catch (error) {
        dispatch({ type: ACTIONS.REMOVE_PICTURE_ERROR, payload: { stepId, error: error.message } });
      }
    };

    dispatch({ type: ACTIONS.REMOVE_PICTURE_START, payload: { stepId } });
    doPictureRemove();
  }, [railsFormToken]);

  // Function to set the pass/fail filter
  const setFilterPassFail = useCallback((filterValue) => {
    dispatch({ type: ACTIONS.SET_FILTER_PASS_FAIL, payload: { filterValue } });
  }, [dispatch]);

  // Function to set the status filter
  const setFilterStatus = useCallback((attributes) => {
    dispatch({ type: ACTIONS.SET_FILTER_STATUS, payload: { attributes } });
  }, [dispatch]);

  // Function to clear both the pass/fail and the status filters
  const clearFilters = useCallback(() => {
    dispatch({ type: ACTIONS.CLEAR_FILTERS });
  }, [dispatch]);

  // All state values and functions made available to context consumers
  const providerValue = useMemo(
    () => ({
      fetchRequested: state.fetchRequested,
      fetchProcessing: state.fetchProcessing,
      fetchSuccess: state.fetchSuccess,
      fetchError: state.fetchError,
      deviceHasCamera: state.deviceHasCamera,
      inspectionCompleted: state.inspectionCompleted,
      allowAllOk: state.allowAllOk,
      scored: state.scored,
      totalScore: state.totalScore,
      allowNA: state.allowNA,
      createRepairByDefault: state.createRepairByDefault,
      steps: state.steps,
      visibleStepIds: state.visibleStepIds,
      filterPassFail: state.filterPassFail,
      allItemsPassed: state.allItemsPassed,
      autoScrollOnPass: state.autoScrollOnPass,
      stepsIncompleteCount: state.stepsIncompleteCount,
      readOnly: state.readOnly,
      statusName,
      updateStep,
      removePicture,
      setFilterPassFail,
      setFilterStatus,
      clearFilters,
      allOk,
    }),
    [
      state.fetchRequested,
      state.fetchProcessing,
      state.fetchSuccess,
      state.fetchError,
      state.deviceHasCamera,
      state.inspectionCompleted,
      state.allowAllOk,
      state.allowNA,
      state.createRepairByDefault,
      state.steps,
      state.visibleStepIds,
      state.filterPassFail,
      state.allItemsPassed,
      state.autoScrollOnPass,
      state.stepsIncompleteCount,
      state.readOnly,
      state.scored,
      state.totalScore,
      updateStep,
      removePicture,
      setFilterPassFail,
      setFilterStatus,
      clearFilters,
      allOk,
    ],
  );

  return (
    <InspectionsContext.Provider value={providerValue}>
      {children}
    </InspectionsContext.Provider>
  );
}

export { InspectionsContext, InspectionsContextProvider };

InspectionsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  equipmentId: PropTypes.number.isRequired,
  inspectionId: PropTypes.number.isRequired,
  railsFormToken: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  pendingAttributes: PropTypes.arrayOf(PropTypes.object),
};

InspectionsContextProvider.defaultProps = {
  readOnly: false,
  pendingAttributes: null,
};
