import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../ErrorBoundary';
import CustomizationModalDataWrapper from './CustomizationModalDataWrapper';

export default function CustomizationModal({
  railsFormToken,
  isOpen,
  onClose,
  title,
  customizationClass,
  customizationName,
  orgLogoPresent,
  config,
  report_title,
}) {
  return (
    <ErrorBoundary config={config} errorContext="Customizations">
      <CustomizationModalDataWrapper
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        customizationClass={customizationClass}
        customizationName={customizationName}
        orgLogoPresent={orgLogoPresent}
        railsFormToken={railsFormToken}
        report_title={report_title}
      />
    </ErrorBoundary>
  );
}

CustomizationModal.propTypes = {
  railsFormToken: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  customizationClass: PropTypes.string.isRequired,
  customizationName: PropTypes.string.isRequired,
  orgLogoPresent: PropTypes.bool,
  config: PropTypes.string.isRequired,
};

CustomizationModal.defaultProps = {
  isOpen: false,
  onClose: () => null,
  title: 'Customize',
  orgLogoPresent: false,
};
