import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-charts-enterprise';
import HtmlRenderer from './HtmlRenderer';
import LoadingOverlay from './LoadingOverlay';
import ScrollToTopButton from './ScrollToTopButton';
import ActionsMenuRenderer from './ActionsMenuRenderer';
import TooltipCellRenderer from './TooltipCellRenderer';
import ExpandDetailRenderer from './ExpandDetailRenderer';
import DetailCellRenderer from './DetailCellRenderer';
import htmlSum from './data/agAggregateFunctions';
import useFetchAllData from './data/useFetchAllData';
import Toggle from '../Toggle';
import CustomPagination from './CustomPagination';

function ClientSideGrid({
  ajax,
  currency,
  defaultColDef,
  columnDefs,
  gridStyle,
  displayLength,
  allowGrouping,
  quickFilterText,
  tallRows,
  rowBuffer,
  setHaveError,
  setErrorMessage,
  onBodyScroll,
  onColumnMoved,
  onFirstDataRendered,
  onGridReady,
  onRowClicked,
  onSortChanged,
  onRecordIdsChanged,
  onSelectionChanged,
  isRowSelectable,
  infiniteScroll,
  squareBorder,
  masterDetail,
}) {
  const [gridApi, setGridApi] = useState();
  const [groupByColumn, setGroupByCol] = useState();
  const [enableGroupBy, setEnableGroupBy] = useState(true);
  const [totalsRowData, setTotalsRowData] = useState([]);
  const detailCellRenderer = useCallback(DetailCellRenderer, []);

  // Effect to keep the groupByCol state value updated when column defs change
  useEffect(() => {
    setGroupByCol(columnDefs.find((e) => e.enableRowGroup));
  }, [columnDefs]);

  // Effect to apply the groupByColumn state based on enableGroupBy toggle value
  useEffect(() => {
    if (!gridApi || !groupByColumn) return;

    gridApi.applyColumnState({
      state: [{
        colId: groupByColumn.field,
        rowGroup: enableGroupBy,
      }],
      applyOrder: true,
    });
  }, [gridApi, enableGroupBy, groupByColumn]);

  // Use custom hook to fetch all data
  const { fetchData, rowData } = useFetchAllData(
    ajax,
    columnDefs,
    setTotalsRowData,
    setHaveError,
    setErrorMessage,
    onRecordIdsChanged,
  );

  // Define the custom aggregator that can subtotal on HTML currency strings
  const aggFuncs = useMemo(() => ({
    htmlSum: htmlSum({
      symbol: currency.symbol,
      thousandsSeparator: currency.thousands_separator,
      decimalMark: currency.decimal_mark,
      symbolFirst: currency.symbol_first,
    }),
  }), [currency]);

  // Function to determine row class
  const getRowClass = useCallback((params) => {
    let classes = 'tw-cursor-pointer';
    if (masterDetail) {
      classes += ' im-master-detail';
    }

    if (params.data?.DT_RowClass && params.data.DT_RowClass.includes('priority-high-or-overdue')) {
      classes += ' priority-high-or-overdue';
    }
    return classes;
  }, [masterDetail]);

  // Function to calculate the totals based on the current column filters
  const calculateTotals = useCallback(() => {
    const totals = {};

    // Iterate over all nodes that pass the current filter
    gridApi.forEachNodeAfterFilter((node) => {
      // For each node, add the values of the fields to the totals
      if (node?.data) {
        columnDefs.forEach((colDef) => {
          if (colDef.field.endsWith('_total')) {
            const baseField = colDef.field.slice(0, -6);
            const currencyParams = {
              symbol: currency.symbol,
              thousandsSeparator: currency.thousands_separator,
              decimalMark: currency.decimal_mark,
              symbolFirst: currency.symbol_first,
            };

            totals[baseField] = htmlSum(currencyParams)({
              values: [totals[baseField] || '0.00', node.data[baseField]],
            });
          }
        });
      }
    });

    if (Object.keys(totals).length === 0) return;

    setTotalsRowData([totals]);
  }, [gridApi, columnDefs, currency]);

  // Fetch data when grid is ready
  const handleGridReady = useCallback((params) => {
    setGridApi(params.api);
    onGridReady(params);
    fetchData(params);
  }, [fetchData, onGridReady]);

  // Track the the displayed record IDs in order when the filter changes
  const handleFilterChanged = useCallback((params) => {
    const orderedIds = [];
    params.api.forEachNodeAfterFilterAndSort((node) => {
      if (!node.group && node.data?.id) orderedIds.push(node.data.id);
    });
    onRecordIdsChanged(orderedIds);
    calculateTotals();
  }, [calculateTotals, onRecordIdsChanged]);

  // Turn on/off grouping
  const handleEnableGroupByChange = () => {
    setEnableGroupBy(!enableGroupBy);
  };

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomPagination,
          align: 'center',
        },
      ],
    };
  }, []);

  return (
    <>
      {groupByColumn && (
        <div className="im-clear tw-flex tw-justify-start tw-items-center tw-w-full tw-mb-2 tw-ml-2">
          <Toggle
            id="enable-grouping"
            value={enableGroupBy}
            onChange={handleEnableGroupByChange}
            labelText={`Group by ${groupByColumn.headerName}`}
            formControlClass="tw-w-40"
            labelClass="tw-du-label-text tw-mr-1 tw-w-full tw-text-left"
            inputClass="tw-du-toggle-sm tw-du-toggle-primary"
            toggleBeforeLabel
          />
        </div>
      )}
      <div
        style={gridStyle}
        className={`ag-theme-quartz tw-relative ${squareBorder ? 'ag-zero-border-radius' : ''}`}
      >
        <AgGridReact
          aggFuncs={aggFuncs}
          columnDefs={columnDefs}
          components={{
            htmlRenderer: HtmlRenderer,
            actionsMenuRenderer: ActionsMenuRenderer,
            tooltipCellRenderer: TooltipCellRenderer,
            expandDetailRenderer: ExpandDetailRenderer,
          }}
          defaultColDef={defaultColDef}
          getRowClass={getRowClass}
          groupDefaultExpanded={masterDetail ? false : -1}
          groupDisplayType="custom"
          groupRowRendererParams={{
            suppressCount: true,
            suppressDoubleClickExpand: true,
          }}
          isRowSelectable={isRowSelectable}
          loadingOverlayComponent={LoadingOverlay}
          onBodyScroll={onBodyScroll}
          onColumnMoved={onColumnMoved}
          onFilterChanged={handleFilterChanged}
          onFirstDataRendered={onFirstDataRendered}
          onGridReady={handleGridReady}
          onRowClicked={onRowClicked}
          onSelectionChanged={onSelectionChanged}
          onSortChanged={onSortChanged}
          pinnedBottomRowData={totalsRowData}
          quickFilterText={quickFilterText}
          reactiveCustomComponents
          rowBuffer={rowBuffer}
          rowData={rowData}
          rowHeight={tallRows ? 73 : 42}
          rowGroupPanelShow={allowGrouping ? 'always' : 'never'}
          rowSelection="multiple"
          suppressAggFuncInHeader
          suppressCellFocus
          suppressContextMenu
          suppressDragLeaveHidesColumns
          suppressGroupRowsSticky
          suppressRowClickSelection
          domLayout={infiniteScroll ? undefined : 'autoHeight'}
          pagination={!infiniteScroll}
          paginationPageSize={displayLength}
          paginateChildRows
          paginationPageSizeSelector={false}
          masterDetail={masterDetail}
          detailCellRenderer={masterDetail ? detailCellRenderer : undefined}
          detailRowAutoHeight
          statusBar={!infiniteScroll ? statusBar : undefined}
          suppressPaginationPanel
        />
        <ScrollToTopButton />
      </div>
    </>
  );
}

ClientSideGrid.propTypes = {
  ajax: PropTypes.string.isRequired,
  currency: PropTypes.object.isRequired,
  defaultColDef: PropTypes.object.isRequired,
  displayLength: PropTypes.number,
  columnDefs: PropTypes.arrayOf(PropTypes.object).isRequired,
  gridStyle: PropTypes.object.isRequired,
  allowGrouping: PropTypes.bool,
  quickFilterText: PropTypes.string,
  tallRows: PropTypes.bool,
  rowBuffer: PropTypes.number,
  setHaveError: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  onBodyScroll: PropTypes.func,
  onColumnMoved: PropTypes.func,
  onFirstDataRendered: PropTypes.func.isRequired,
  onGridReady: PropTypes.func,
  onRowClicked: PropTypes.func.isRequired,
  onSortChanged: PropTypes.func.isRequired,
  onRecordIdsChanged: PropTypes.func.isRequired,
  onSelectionChanged: PropTypes.func,
  isRowSelectable: PropTypes.func,
  infiniteScroll: PropTypes.bool,
  squareBorder: PropTypes.bool,
  masterDetail: PropTypes.bool,
};

ClientSideGrid.defaultProps = {
  allowGrouping: false,
  displayLength: 25,
  quickFilterText: '',
  tallRows: false,
  rowBuffer: 10,
  onBodyScroll: () => {},
  onColumnMoved: () => {},
  onGridReady: () => {},
  onSelectionChanged: () => {},
  isRowSelectable: () => true,
  infiniteScroll: true,
  squareBorder: false,
  masterDetail: false,
};

export default React.memo(ClientSideGrid);
