import React, { useState } from 'react';

function ExpandDetailRenderer({ node, value }) {
  const [expanded, setExpanded] = useState(node.expanded);
  if (value === null) return (<></>);

  const handleClick = () => {
    setExpanded(!expanded);
    node.setExpanded(!expanded);
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      aria-label="expand/collapse"
      style={{ backgroundColor: 'transparent', border: 'none' }}
      className="im-clear tw-text-left im-cp-clickable im-expand-master-detail tw-text-lg tw-text-primary"
    >
      <i
        className={`fas fa-chevron-right im-expand-master-detail im-cp-chevron tw-mx-0 ${expanded ? 'tw-rotate-90' : ''}`}
      />
    </button>
  );
}

export default React.memo(ExpandDetailRenderer);
