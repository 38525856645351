import React from 'react';
import PropTypes from 'prop-types';

function BaseIcon({
  isActive,
  widthPx,
  heightPx,
  activeColorClass,
  defaultColorClass,
  pathData,
  strokeWidthDefault,
  strokeWidthActive,
  name,
}) {
  return (
    <svg
      width={`${widthPx}px`}
      height={`${heightPx}px`}
      viewBox="0 0 35 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id={`${name}_icon_group`}
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <path
          className={isActive ? activeColorClass : defaultColorClass}
          d={pathData}
          id={`${name}_icon`}
          strokeWidth={isActive ? strokeWidthActive : strokeWidthDefault}
        />
      </g>
    </svg>
  );
}

BaseIcon.propTypes = {
  isActive: PropTypes.bool,
  widthPx: PropTypes.number,
  heightPx: PropTypes.number,
  activeColorClass: PropTypes.string.isRequired,
  defaultColorClass: PropTypes.string,
  pathData: PropTypes.string.isRequired,
  strokeWidthDefault: PropTypes.number,
  strokeWidthActive: PropTypes.number,
  name: PropTypes.string,
};

BaseIcon.defaultProps = {
  isActive: false,
  widthPx: 35,
  heightPx: 33,
  defaultColorClass: 'tw-stroke-muted-dark',
  strokeWidthDefault: 3,
  strokeWidthActive: 5.424,
  name: 'base',
};

export default React.memo(BaseIcon);
