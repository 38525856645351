import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CollapsableMenuItem from './CollapsableMenuItem';
import SlideDownTransition from './SlideDownTransition';

export default function CollapsableMenu({
  title, isOpen, className, children, onOpenChange, itemId,
}) {
  const [showContent, setShowContent] = useState(isOpen);

  const handleCheckedChange = () => {
    const newValue = !showContent;
    setShowContent(newValue);
    onOpenChange(itemId, newValue);
  };

  return (
    <div className={`im-cm-menu ${className}`}>
      <button
        className="im-clear im-cm-title im-cm-clickable"
        type="button"
        onClick={handleCheckedChange}
      >
        {title}
        <i
          className={classNames({
            'fas fa-chevron-down im-cm-chevron': true,
            'tw-rotate-180': showContent,
          })}
        />
      </button>
      <SlideDownTransition
        className="im-cm-section"
        show={showContent}
      >
        <ul className="tw-pb-2">
          {children}
        </ul>
      </SlideDownTransition>
    </div>
  );
}

CollapsableMenu.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf([CollapsableMenuItem]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf([CollapsableMenuItem]),
      }),
    ),
  ]),
  className: PropTypes.string,
  onOpenChange: PropTypes.func,
  itemId: PropTypes.number,
};

CollapsableMenu.defaultProps = {
  isOpen: false,
  children: [],
  className: '',
  onOpenChange: null,
  itemId: null,
};
