import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { InspectionsContext } from './data/InspectionsContextManager';
import ActionButtonGroup from './ActionButtonGroup';
import AdditionalInfo from './AdditionalInfo';
import ValueField from './ValueField';
import AdditionalInfoButton from './AdditionalInfoButton';
import RepairRequest from './RepairRequest';
import TakePictureModal from './TakePictureModal';
import HiddenStep from './HiddenStep';

function Step({
  step,
  index,
  stepRef,
  onScrollToNext,
}) {
  let classes;

  const [createRepairRequest, setCreateRepairRequest] = useState(step.create_repair_request);
  const [repairPriority, setRepairPriority] = useState(step.repair_priority || 'NORMAL');
  const [showAdditional, setShowAdditional] = useState(false);
  const [showTakePictureModal, setShowTakePictureModal] = useState(false);
  const valueInputRef = useRef(null);

  const {
    statusName,
    deviceHasCamera,
    createRepairByDefault,
    readOnly,
  } = useContext(InspectionsContext);

  const status = statusName(step.pass);
  const scored_step = step.scored_step

  switch (status) {
    case 'pass':
      classes = 'tw-border-good-dark tw-bg-good-light';
      break;
    case 'fail':
      classes = 'tw-border-bad-dark tw-bg-bad-light';
      break;
    case 'na':
      classes = 'tw-border-neutral-dark tw-bg-neutral-light';
      break;
    default:
      classes = 'tw-border-muted-medium tw-bg-muted-light';
  }

  const handleRepairRequestChange = () => {
    setCreateRepairRequest(!createRepairRequest);
  };

  const handleRepairPriorityChange = (event) => {
    setRepairPriority(event.target.value);
  };

  const handleShowAdditionalToggle = () => {
    setShowAdditional(!showAdditional);
  };

  const handleCloseTakePictureModal = () => {
    setShowTakePictureModal(false);
  };

  // Actions to take upon action button click
  const handleActionButtonClick = (newValue) => {
    // Default repair request to on if organization setting is enabled
    if (statusName(newValue) === 'fail' && createRepairByDefault) {
      setCreateRepairRequest(true);
    }

    if (statusName(newValue) === 'fail' && step.has_value && step.value_required) {
      valueInputRef.current.focus();
    }

    if (
      statusName(newValue) !== status
      && statusName(newValue) !== 'na'
      && step.require_photo
      && !step.has_picture
      && deviceHasCamera
    ) {
      setShowTakePictureModal(true);
    }
  };

  // Effects to run whenever the status changes (both on action button click and initial load)
  useEffect(() => {
    // Automatically open additional info when step fails
    if (status === 'fail' || step.require_photo || (step.comments !== null && step.comments !== '') || (step.notes !== null && step.notes !== '') || step.has_picture || scored_step ) {
      setShowAdditional(true);
    } else {
      if (status === 'incomplete') return;

      setShowAdditional(false);
      // Automatically scroll to the next item if not failed
      if (!readOnly) onScrollToNext();
    }
    // Intentionally leaving onScrollToNext out of dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    setCreateRepairRequest(step.create_repair_request);
  }, [step.create_repair_request]);

  useEffect(() => {
    setRepairPriority(step.repair_priority);
  }, [step.repair_priority]);

  const showAdditionalInfoButton = (!readOnly && (status === 'pass' || status === 'fail') || scored_step);

  const rowClass = () => {
    if (step.pass === 0) return 'step-failed';
    if (step.pass === 1) return 'step-passed';
    if (step.pass === -1) return 'step-not-available';
    return 'step-incomplete';
  };

  if (step.hidden) {
    // Step can be hidden if filters are applied. In that case, we still want the hidden
    // fields so that Rails form submission includes all entered data.
    return (
      <HiddenStep
        step={step}
        index={index}
        createRepairRequest={createRepairRequest}
        repairPriority={repairPriority}
      />
    );
  }

  return (
    <>
      {step.section_header && (
        <div className="section-header">
          {step.name}
        </div>
      )}
      {!step.section_header && (
        <div ref={stepRef} className={`tw-rounded-lg ${rowClass()} tw-border-solid tw-border-[1px] tw-relative tw-font-medium tw-text-base tw-px-2 sm:tw-px-6 tw-pt-2 tw-pb-3 tw-my-4 inspection-step-container-${index} ${classes} ${showAdditionalInfoButton ? 'tw-pb-[40px]' : ''}`}>
          <div className="tw-mb-1 step-name">
            {step.name}
          </div>
          <input type="hidden" value={step.name} name={`inspection[inspection_steps_attributes][${index}][name]`} />
          <input type="hidden" value={step.id} name={`inspection[inspection_steps_attributes][${index}][id]`} />
          <div className="im-clear tw-flex tw-flex-wrap tw-content-between">
            {step.error_message && (
              <div className="tw-w-full tw-font-extrabold">
                <i className="fas fa-exclamation-circle tw-m-0 tw-mr-1 tw-text-bad-dark" />
                {step.error_message}
              </div>
            )}
            <div className="tw-flex tw-flex-wrap tw-grow tw-content-around tw-mr-1">
              <ActionButtonGroup
                stepId={step.id}
                stepStatus={status}
                stepValue={step.pass}
                index={index}
                onClick={handleActionButtonClick}
                showAdditional={showAdditional}
                onShowAdditionalClick={handleShowAdditionalToggle}
              />
            </div>
            {status === 'fail' && (
              <RepairRequest
                value={createRepairRequest}
                priority={repairPriority}
                onValueChange={handleRepairRequestChange}
                onPriorityChange={handleRepairPriorityChange}
                step={step}
                index={index}
                disabled={readOnly}
              />
            )}
          </div>
          {step.has_value && (
            <ValueField step={step} index={index} valueInputRef={valueInputRef} />
          )}
          <AdditionalInfo
            show={showAdditional}
            deviceHasCamera={deviceHasCamera}
            index={index}
            step={step}
          />
          {showAdditionalInfoButton && (
            <AdditionalInfoButton
              onClick={handleShowAdditionalToggle}
              value={showAdditional}
              show
            />
          )}
        </div>
      )}
      {!readOnly && deviceHasCamera && (
        <TakePictureModal
          isOpen={showTakePictureModal}
          onClose={handleCloseTakePictureModal}
          step={step}
        />
      )}
    </>
  );
}

Step.propTypes = {
  step: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  stepRef: PropTypes.func.isRequired,
  onScrollToNext: PropTypes.func.isRequired,
};

export default React.memo(Step);
