import React from 'react';
import PropTypes from 'prop-types';
import Toggle from '../Toggle';

export default function LogoAndChartToggles({
  supportsOrgLogo,
  showOrgLogo,
  onShowOrgLogoChange,
  orgLogoPresent,
  uploadLogoPath,
  suppressCharts,
  onSuppressChartsChange,
  supportsThumbnails,
  hideThumbnails,
  onHideThumbnailsChange,
}) {
  return (
    <div className="tw-flex tw-flex-wrap tw-justify-between tw-items-center">
      <div className="tw-flex tw-items-center tw-space-x-4 tw-min-w-full sm:tw-min-w-0 tw-mb-2 sm:tw-mb-0">
        {supportsOrgLogo && showOrgLogo !== null && (
          <>
            <Toggle
              id="logo-toggle"
              value={showOrgLogo}
              onChange={onShowOrgLogoChange}
              disabled={!orgLogoPresent}
              labelText="Print logo on report PDF"
              formControlClass="tw-w-40"
              labelClass="tw-du-label-text tw-mr-2 tw-w-full"
              inputClass="tw-du-toggle-sm tw-du-toggle-success"
            />
            {uploadLogoPath && (
              <a
                className="tw-text-primary tw-text-sm"
                href={uploadLogoPath}
              >
                {orgLogoPresent ? 'Update logo here' : 'You must upload a logo first' }
                <span className="tw-ml-2 fas fa-external-link-alt" />
              </a>
            )}
          </>
        )}
        {supportsThumbnails && hideThumbnails !== null && (
          <Toggle
            id="thumbnails-toggle"
            value={!hideThumbnails}
            onChange={onHideThumbnailsChange}
            labelText="Thumbnail image"
            labelClass="tw-du-label-text tw-mr-2 tw-w-full"
            inputClass="tw-du-toggle-sm tw-du-toggle-success"
          />
        )}
      </div>

      <div className="tw-flex tw-items-center tw-space-x-4 tw-min-w-full sm:tw-min-w-0 tw-mb-2 sm:tw-mb-0">
        {suppressCharts !== null && (
          <Toggle
            id="charts-toggle"
            value={!suppressCharts}
            onChange={onSuppressChartsChange}
            labelText="Display charts"
            formControlClass="tw-w-40"
            labelClass="tw-du-label-text tw-mr-2 tw-w-full tw-text-right"
            inputClass="tw-du-toggle-sm tw-du-toggle-success"
          />
        )}
      </div>
    </div>
  );
}

LogoAndChartToggles.propTypes = {
  supportsOrgLogo: PropTypes.bool,
  showOrgLogo: PropTypes.bool,
  onShowOrgLogoChange: PropTypes.func.isRequired,
  orgLogoPresent: PropTypes.bool.isRequired,
  uploadLogoPath: PropTypes.string,
  suppressCharts: PropTypes.bool,
  onSuppressChartsChange: PropTypes.func.isRequired,
  supportsThumbnails: PropTypes.bool,
  hideThumbnails: PropTypes.bool,
  onHideThumbnailsChange: PropTypes.func.isRequired,
};

LogoAndChartToggles.defaultProps = {
  supportsOrgLogo: false,
  showOrgLogo: null,
  uploadLogoPath: null,
  suppressCharts: null,
  supportsThumbnails: false,
  hideThumbnails: false,
};
