import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function AdditionalInfoButton({ onClick, value }) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className="im-clear tw-absolute tw-group tw-left-0 tw-right-0 tw-bottom-0 tw-cursor-pointer tw-transition-all tw-ease-in-out tw-text-center tw-rounded-b-lg tw-bg-gray-300/20 hover:tw-bg-gray-50"
      onClick={onClick}
    >
      <button
        className="im-clear im-cp-title im-cp-clickable tw-shrink group-hover:tw-text-primary"
        type="button"
        onClick={onClick}
      >
        <i
          className={classNames({
            'fas fa-chevron-down im-cp-chevron tw-ml-0 tw-text-sm': true,
            'tw-rotate-180': value,
          })}
        />
      </button>
    </div>
  );
}

AdditionalInfoButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

export default React.memo(AdditionalInfoButton);
