import { useState, useEffect } from 'react';

// Hook to manage state in local storage
export default function useLocalStorageState(defaultValue, key) {
  const [value, setValue] = useState(() => {
    const storedValue = window.localStorage.getItem(key);

    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
