import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Menu, Transition } from '@headlessui/react';

export default function ActionsDropdown({ report, onDownloadRequest }) {
  const classesForOption = (active) => classNames({
    'im-clear tw-w-full tw-cursor-pointer tw-text-left tw-font-regular tw-block tw-px-4 tw-py-2 tw-text-sm': true,
    'tw-bg-primary-content tw-text-gray-900': active,
    'tw-text-gray-700': !active,
  });

  const scheduleReportParams = () => {
    let queryString = `?name=${report.id}`;
    if (report.custom_filter_id !== null) queryString += `&custom_filter_id=${report.custom_filter_id}`;
    if (report.interval !== null) queryString += `&report_interval=${report.interval}`;

    if (report.warehouse_filter) {
      if (report.warehouse_id !== null) queryString += `&warehouse_id=${report.warehouse_id}`;
      if (report.category_id !== null) queryString += `&warehouse_category=${encodeURIComponent(report.category_id)}`;
    } else {
      if (report.location_id !== null) queryString += `&location_id=${report.location_id}`;
      if (report.category_id !== null) queryString += `&category_id=${encodeURIComponent(report.category_id)}`;
      if (report.status !== null) queryString += `&status=${report.status}`;
    }
    return queryString;
  };

  return (
    <div>
      <Menu as="div" className="tw-visible md:tw-invisible im-clear tw-absolute tw-right-3 tw-top-3 tw-flex-none tw-inline-block tw-text-center">
        <div>
          <Menu.Button className="im-clear test-report-dropdown tw-inline-flex tw-w-min tw-justify-center tw-gap-x-1.5 tw-rounded-md tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-600 tw-cursor-pointer hover:tw-text-primary">
            <i className="far fa-ellipsis-v" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="tw-transition tw-ease-out tw-duration-100"
          enterFrom="tw-transform tw-opacity-0 tw-scale-95"
          enterTo="tw-transform tw-opacity-100 tw-scale-100"
          leave="tw-transition tw-ease-in tw-duration-75"
          leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
          leaveTo="tw-transform tw-opacity-0 tw-scale-95"
        >
          <Menu.Items className="tw-absolute tw-right-0 tw-z-40 tw-mt-2 tw-w-56 tw-shadow-md tw-shadow-gray-500 tw-border-solid tw-border-1 tw-border-gray-200 tw-origin-top-right tw-rounded-md tw-bg-neutral-100 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
            <div className="tw-py-1">
              <div>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={() => onDownloadRequest('pdf')}
                      className={classesForOption(active)}
                    >
                      <i className="far fa-print tw-mr-2" />
                      Print
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={() => onDownloadRequest('csv')}
                      className={classesForOption(active)}
                    >
                      <i className="far fa-file-export tw-mr-2" />
                      Export to CSV
                    </button>
                  )}
                </Menu.Item>
                {report.allow_scheduling && (
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        type="button"
                        href={`/scheduled_reports/new${scheduleReportParams()}`}
                        className={classesForOption(active)}
                      >
                        <i className="far fa-calendar-check tw-mr-2" />
                        Schedule Report
                      </a>
                    )}
                  </Menu.Item>
                )}
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <div className="tw-invisible md:tw-visible im-clear tw-flex tw-absolute tw-right-3 tw-top-3 tw-flex-none tw-inline-block tw-text-center">
        <button
          type="button"
          id="print-report"
          onClick={() => onDownloadRequest('pdf')}
          className="im-clear tw-w-full tw-cursor-pointer tw-text-left tw-font-regular tw-block tw-px-4 tw-py-2 tw-text-lg tw-text-gray-700 hover:tw-text-primary"
        >
          <i title="Print" className="far fa-print tw-mr-2" />
        </button>
        {!report.device_has_camera && (
          <button
            type="button"
            onClick={() => onDownloadRequest('csv')}
            className="im-clear tw-w-full tw-cursor-pointer tw-text-left tw-font-regular tw-block tw-px-4 tw-py-2 tw-text-lg tw-text-gray-700 hover:tw-text-primary"
          >
            <i title="Export to CSV" className="far fa-file-export tw-mr-2" />
          </button>
        )}
        {report.allow_scheduling && (
          <a
            type="button"
            href={`/scheduled_reports/new${scheduleReportParams()}`}
            data-remote="true"
            data-no-react-unmount="true"
            className='im-clear show-fly-in tw-w-full tw-cursor-pointer tw-text-left tw-font-regular tw-block tw-px-4 tw-py-2 tw-text-lg tw-text-gray-700 hover:tw-text-primary'
          >
            <i title="Create Scheduled Report" className="far fa-calendar-check tw-mr-2" />
          </a>
        )}
      </div>
    </div>
  );
}

ActionsDropdown.propTypes = {
  report: PropTypes.object,
  onDownloadRequest: PropTypes.func.isRequired,
};

ActionsDropdown.defaultProps = {
  report: {},
};
