import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';

export default function NotesDialog({
  notes,
  isOpen,
  onClose
}) {

  const handleClick = (event) => {
    event.stopPropagation()
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter="tw-ease-out tw-duration-300"
        enterFrom="tw-opacity-0"
        enterTo="tw-opacity-100"
        leave="tw-ease-in tw-duration-200"
        leaveFrom="tw-opacity-100"
        leaveTo="tw-opacity-0"
      >
        <Dialog
          as="div"
          className={`im-clear im-trx-lists tw-fixed tw-z-20 tw-inset-0 tw-flex confirmation-dialog tw-items-center tw-justify-center`}
          onClose={onClose}
          onClick={onClose}
        >
          <div
            onClick={handleClick}
            className={`tw-p-5 tw-flex tw-flex-col tw-rounded-md tw-w-1/2 tw-max-w-m tw-bg-blue-50 tw-border-solid tw-border-2 tw-border-blue-400 tw-max-h-[75%]`}>
            <div
              dangerouslySetInnerHTML={{__html: notes}}
              className={'tw-overflow-y-auto'}>
            </div>
            <div className="tw-flex tw-justify-end tw-gap-2">
              <button
                type="button"
                className={`im-clear tw-mt-3 active:tw-shadow-inner tw-inline-flex tw-cursor-pointer tw-w-full tw-justify-center tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset sm:tw-mt-0 sm:tw-w-auto sm:tw-mr-3 sm:tw-mb-0 tw-bg-white tw-px-4 tw-py-3 tw-text-sm tw-font-semibold tw-text-gray-900 tw-ring-gray-300 hover:tw-bg-gray-50`}
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </Dialog>
      </Transition.Child>
    </Transition.Root>
  );
}

NotesDialog.propTypes = {
  notes: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

NotesDialog.defaultProps = {
  onClose: () => null,
};
