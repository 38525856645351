import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import merge from 'lodash.merge';

export default function Chart({ chartOptions, show }) {
  const defaultOptions = {
    credits: false,
    plotOptions: {
      series: {
        point: {
          events: {
            click(e) {
              // eslint-disable-next-line no-restricted-globals
              if (e.point.url) location.href = e.point.url;
            },
          },
        },
      },
    },
    title: {
      style: {
        fontSize: '16px',
      },
    },
  };

  const options = merge({}, defaultOptions, chartOptions);

  if (!show) return null;

  return (
    <div className="chart-react">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

Chart.propTypes = {
  chartOptions: PropTypes.object.isRequired,
  show: PropTypes.bool,
};

Chart.defaultProps = {
  show: true,
};
